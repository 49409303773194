import React, { useEffect, useState } from "react";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { ToggleButton } from "../../commanComponents/buttons/ToggleButtons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import { useNavigate } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import useDebounce from "../../constants/useDebounce";
import Loader from "../../commanComponents/Loader";
import { isEven } from "../../constants/Helper/NumbersFunction";
import { PaginationSrNo } from "../../constants/Helper/Helpers";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { userRole } from "../../constants/Helper/getUserRole";

function DumpYardList() {
   const navigate = useNavigate();
   const [page, setPage] = useState(1);
   const [searchString, setSearchString] = useState("");
   const [pageUpdated, setPageUpdated] = useState("");
   const [dumpYardData, setDumpYardData] = useState([]);
   const [loading, setLoading] = useState(false);
   const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);

   const { data, isFetching, error } = useQuery(
      ["dump_yard_manage", page, debouncedSearchTerm, pageUpdated],
      () => commonApi?.getDumpYardList(page, searchString),
      {
         refetchOnWindowFocus: false, 
         onSuccess: (data) => {
            if (data?.status == "SUCCESS") {
               setDumpYardData(data?.result);
            } else if (data?.status == "JWT_INVALID") {
               toast.error(`Failed : ${data?.message}`);
            }
            if (data?.status == "FAILED") {
               setDumpYardData([]);
            }
         },
         onError: (error) => {
            toast.error(`Failed : ${error?.message}`);
         },
      }
   );

   const updateActiveStatus = useMutation(
      (data) => {
         setLoading(true);
         return commonApi.updateDumpActiveStatus(data);
      },
      {
         onSuccess: (data) => {
            if (data?.status === "SUCCESS") {
               setLoading(false);
               toast.success(`Success : ${data?.message}`);
               setPageUpdated((prev) => !prev);
            } else if (data?.status == "JWT_INVALID") {
               setLoading(false);
               toast.error(`Failed: ${data?.message}`);
            } else {
               setLoading(false);
               toast.error(`Failed : ${data?.message}`);
            }
         },
         onError: (error) => {
            setLoading(false);
            toast.error(`Failed : ${error?.message}`);
         },
      }
   );

   useEffect(()=>{
      if ( !checkUserTabAccess("Dump_Yard_Manage") || userRole() == 'zonal' ) {
         toast.info('You do not have permission to access Dump Yard Manage')
        navigate(-1); // redirect to previous page if user dont not have Tab Access
        return;
     }
     },[])

   return (
      <div>
         {(isFetching || loading) && <Loader />}
         <div className="search_btn_wrapper">
            <div className="w-200">
               <Searchbox
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search Dump Yard..."
               />
            </div>
            {checkUserTabAccess("Dump_Yard_Manage")?.access === 'write' && <div className="buttons_wrapper">
               <RoundedbuttonWithIcon
                  text={"Add Dump Yard"}
                  textClasses="text-uppercase"
                  icon={svgIcons.PlusIcon}
                  onClick={() => {
                     navigate("/dump-yard/dump-yard-management?type=add");
                  }}
               />
            </div>}
         </div>
         <div className="tableWrapper common_shadow">
            <div
               className="table-responsive position-relative tablemain"
               style={{ overflowY: "auto", height: "58vh" }}
            >
               <table className="table position-relative w-100">
                  <thead
                     className="rounded-pill w-100 bg_Table_Header border-0"
                     style={{ position: "sticky", top: "-0.04px", zIndex: "1" }}
                  >
                     <tr>
                        <th scope="col" className="col_100px">
                           Sr. No
                        </th>
                        <th scope="col" className="col_200px">
                           Dump Yard Name
                        </th>
                        <th scope="col" className="col_200px">
                           Zone
                        </th>
                        <th scope="col" className="col_200px">
                           Address
                        </th>
                        <th scope="col" className="col_200px">
                           Dry Capacity
                        </th>
                        <th scope="col" className="col_200px">
                           Wet Capacity
                        </th>
                        <th scope="col" className="col_150px ">
                           Collection
                        </th>
                        <th scope="col" className="col_150px text-center row-fix-to-last table-th-bg-color">
                           Action
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {dumpYardData?.length > 0 &&
                        dumpYardData?.map((data, index) => {
                           return (
                              <tr
                                 className={`${
                                    isEven(index + 1) && "Table_Data"
                                 }`}
                              >
                                 <th scope="row">
                                    {PaginationSrNo(page, index)}
                                 </th>
                                 <td  className={`text-capitalize `}>{data?.name ? data?.name : "--"}</td>
                                 <td  className={`text-capitalize `}>
                                    {data?.zoneId?.name
                                       ? data?.zoneId?.name
                                       : "--"}
                                 </td>
                                 <td  className={`text-capitalize `}>{data?.address ? data?.address : "--"}</td>
                                 <td >
                                    {data?.dryCapacity
                                       ? data?.dryCapacity
                                       : "--"}
                                 </td>
                                 <td>
                                    {data?.wetCapacity
                                       ? data?.wetCapacity
                                       : "--"}
                                 </td>
                                 <td >
                                    {data?.collection ? data?.collection : "--"}
                                 </td>
                                 <td className="row-fix-to-last text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                       {data?.isActive && (
                                          <div
                                             className="mr-2 cursor_pointer"
                                             title="Edit"
                                             onClick={() => {
                                                navigate(
                                                   `/dump-yard/dump-yard-management?type=update&dumpId=${data?._id}`
                                                );
                                             }}
                                          >
                                             {svgIcons.editIcon}
                                          </div>
                                       )}
                                       <ToggleButton
                                          onChange={() => {
                                             updateActiveStatus.mutate(
                                                data?._id
                                             );
                                          }}
                                          isActive={data?.isActive}
                                          disabled={checkUserTabAccess("Dump_Yard_Manage")?.access !== 'write'}
                                       />
                                    </div>
                                 </td>
                              </tr>
                           );
                        })}
                  </tbody>
               </table>
               {dumpYardData?.length == 0 && (
                  <h6 className="DataNotFoundText">No Dump Yard Found...</h6>
               )}
            </div>
         </div>

         <div className="mt-2">
            <PaginationButton
               page={page}
               setPage={setPage}
               totalPages={data?.totalPages || 1}
            />
         </div>
      </div>
   );
}

export default DumpYardList;
