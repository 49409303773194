import React from 'react'
import DumpYardList from '../components/dumpYardManage/DumpYardList'

export default function DumpYard() {
  return (
    <div>
      <DumpYardList/>
    </div>
  )
}
