import React, { useEffect, useState } from "react";
import "./RoleAccessMain.css";
import DepartmentsListWithUsers from "./DepartmentsListWithUsers";
import RoleAccessForm from "./RoleAccessForm";
import { userRole } from "../../constants/Helper/getUserRole";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const RoleAccessMain = () => {
  const [userId, setuserId] = useState("")
  const navigate = useNavigate()

  const handleUserIdChange = (id) => {
    setuserId(id)
  };

  useEffect(()=>{
    if (!checkUserTabAccess("Role_Access") || userRole() == 'zonal') {
      toast.info('You do not have permission to access Role Access')
       navigate(-1); // redirect to previous page if user dont not have Tab Access
      return;
   }
   },[])

  return (
    <>
      <div className="w-100 RoleAccessMain">
        <div className="row w-100 gap-4 h-100 mx-auto">
          <div className="col-4 pr-2 p-0 position-relative h-100">
            <div className="common_shadow rounded p-3 h-100 bg-white">
              <DepartmentsListWithUsers handleUserIdChange={handleUserIdChange} userId={userId}/>
            </div>
          </div>
          <div className="col-8 p-0 h-auto">
            {" "}
            <RoleAccessForm userId={userId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleAccessMain;
