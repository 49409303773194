import UserManagementMain from "../components/UserManagement/UserManagementMain"

const UserManagement = () => {
  return (
    <>
    <UserManagementMain />
    </>
  )
}

export default UserManagement