
import "./Typography.css"

export const Heading_3 = ({ text, className }) => {
  return <h3 className={className}>{text}</h3>;
};
export const Heading_4 = ({ text, className }) => {
  return <h4 className={className}>{text}</h4>;
};
export const Heading_5 = ({ text, className }) => {
  return <h5 className={className}>{text}</h5>;
};
export const Heading_6 = ({ text, className }) => {
  return <h6 className={className}>{text}</h6>;
};
