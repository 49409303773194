import { TransparentBtnWithBorder } from "../../commanComponents/buttons/Button";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import BigTableLayout from "../../commanComponents/CommonLayout/BigTableLayout";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { Heading_6 } from "../../commanComponents/NavigationBar/Typography";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import "./GarbageCollectionLayout.css";
const GarbageCollectionLayout = ({
  children,
  handleSearch,
  handleOpenPopup,
  isHeader = true,
  page=1,
  setPage,
  totalPages,
  selectedZoneList,
  selectedWardList
}) => {
  return (
    <>
    <div className="w-100 mx-auto GarbageCollectionLayout">
      {isHeader && (
        <div className="d-flex justify-content-between align-items-center w-100 mx-auto">
          <div className="d-flex align-items-center w-100">
            <div
              className="px-4 py-2 d-flex align-items-center cursor_pointer bg_support_orange rounded mr-2"
              onClick={handleOpenPopup}
            >
              {svgIcons.filtersIcon}
              <Heading_6 text="Filter" className="ml-3 mb-0 text-white" />
            </div>
            <div>
              <Searchbox
                onChange={handleSearch}
                placeholder="Search..."
              />
            </div>
          </div>
          <div className="d-flex" style={{ gap: "5px" }}>
          {(selectedZoneList && selectedZoneList?.value ) && <TransparentBtnWithBorder text={selectedZoneList?.label} />}  
          {(selectedZoneList && selectedZoneList?.value ) && <TransparentBtnWithBorder text={selectedWardList?.label} />} 
          </div>
        </div>
      )}
      <div className="mw-100 py-0 mt-2 UserManagementMain_TableWrapper">
        <BigTableLayout>{children}</BigTableLayout>
        {/* <UserManagementList /> */}
      </div>
      <div className="py-2">
        <PaginationButton
          page={page}
          setPage={setPage}
          totalPages={totalPages || 1}
        />
      </div>
    </div>
    </>
  );
};

export default GarbageCollectionLayout;
