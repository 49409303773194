import React, { useEffect, useState } from "react";
import "./CenteredPopup.css"; // Import the CSS file for styling
import { Heading_4 } from "../NavigationBar/Typography";
import { IoCloseCircle } from "react-icons/io5";

const CenteredPopup = ({ isOpen, onClose, title, children, style }) => {
   const [showPopup, setShowPopup] = useState(isOpen);
   const [animationState, setAnimationState] = useState(
      isOpen ? "open" : "close"
   );

   useEffect(() => {
      if (isOpen) {
         setAnimationState("open");
         setShowPopup(true);
         setTimeout(() => setShowPopup(true), 300); // Match this duration with animation time
      } else {
         setAnimationState("close");
         // Delay hiding the popup until the animation ends
         setTimeout(() => setShowPopup(false), 300); // Match this duration with animation time
      }
   }, [isOpen]);

   if (!showPopup) return null;

   return (
      <div className="centered-popup-overlay" onClick={onClose}>
         <div
            className={`centered-popup-content text-center d-flex flex-column ${animationState}`}
            onClick={(e) => e.stopPropagation()}
            style={style}
         >
            <div className="popupCloaseBtn" onClick={onClose}>
               <div>{<IoCloseCircle />}</div>
            </div>
            {title && (
               <div className="centered-popup-header">
                  <Heading_4
                     text={title}
                     className="fw-bold text-capitalize text-center mb-3 mx-auto"
                  />

                  {/* <button className="centered-popup-close" onClick={onClose}>
              ✕
            </button> */}
               </div>
            )}
            <div className="centered-popup-body my-auto">{children}</div>
         </div>
      </div>
   );
};

export default CenteredPopup;
