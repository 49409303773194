import React from 'react'
import './dashboard.css'
import Spinner from '../../commanComponents/Spinner'

export default function Cards({img, cardText, value, graphImg, loading}) {
  return (
    <div className='card'>
      <div className='d-flex align-items-center'>
        <div>{img}</div>
        <h6 className='m-0 ml-2'>{cardText}</h6>
      </div>

      <div className='d-flex align-items-center mt-3'>
        <h6 style={{fontSize:'26px', fontWeight:'500', margin:'0px'}}>{loading? <Spinner/> : value}</h6>
        <p className='m-0 ml-2'>{cardText}</p>
      </div>

      <div className='mt-5'>
        {graphImg}
      </div>
    </div>
  )
}
