import React from 'react'
import WastInfoList from '../components/wasteInfo/WastInfoList'

export default function WastInfo() {
  return (
    <div>
      <WastInfoList />
    </div>
  )
}
