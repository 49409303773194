import React, { useContext } from "react";
import Sidebar from "../commanComponents/NavigationBar/Sidebar";
import Topbar from "../commanComponents/NavigationBar/Topbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SidebarContext } from "../constants/stateManagement/SidebarProvider";
import { svgIcons } from "../constants/utilities/ImagesExport/Icons";
import Cookies from "js-cookie";

export default function Layout() {
   const cookieValue =
      Cookies.get("loginToken") && JSON.parse(Cookies.get("loginToken"));
   const name = cookieValue?.userDetails?.name;
   const { closeSideNav, setCloseNavBar } = useContext(SidebarContext);
   const currentUrl = useLocation().pathname?.split("/");
   const navigate = useNavigate();
   const currentPageName = currentUrl[currentUrl.length - 1]
      .split("-")
      .join(" ");

   return (
      <div className="layoutMainWrapper">
         <Sidebar />
         <div
            className={
               closeSideNav ? "layoutOutletWrapperClose" : "layoutOutletWrapper"
            }
         >
            <Topbar />
            <div className="heroBannerWrapper">
               <h3 className="text-capitalize">
                  {currentUrl[currentUrl.length - 1] === "dashboard"
                     ? `Hello ${name} !`
                     : currentPageName}
               </h3>
               {currentUrl.length > 2 && (
                  <div
                     onClick={() => {
                        navigate(-1);
                     }}
                     title="Click to go back"
                  >
                     {svgIcons?.backIcon}
                  </div>
               )}
            </div>
            <div className="outlateWrapper p-3">
               <Outlet />
            </div>
         </div>
      </div>
   );
}
