import { useNavigate, useSearchParams } from "react-router-dom";
import {
   commonApi,
   getServerUrl,
} from "../../constants/apis/adminCommunication";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../commanComponents/Loader";
import "./transferReq.css";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import mapImg from "../../assets/img/mapImg.svg";
import { convertToDateInputFormat, formatDate } from "../../constants/Helper/Helpers";

const ViewTrasferRequest = () => {
   const [searchParams] = useSearchParams();
   const route = useNavigate();
   const transferReqId = searchParams.get("transferReqId");
   const [transferData, setTransferData] = useState({});
   const [assignTo, setAssignTo] = useState({});
   const [loading, setLoading] = useState(false);

   const getTransferDetails = async () => {
      try {
         setLoading(true);
         const response = await commonApi.getransferReqDetails(transferReqId);
         if (response.status === "SUCCESS") {
            setAssignTo(response?.assignedTo[0]);
            setTransferData(response?.transfer);
         } else if (response.status === "JWT_INVALID") {
            route("/login");
            setTransferData({});
            setAssignTo({});
         } else {
            toast.error(`Failed: ${response.message}`);
            setTransferData({});
            setAssignTo({});
         }
      } catch (error) {
         toast.error("An error occurred while fetching grievance details.");
         console.error("Error fetching grievance:", error);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (transferReqId) {
         getTransferDetails();
      }
   }, [transferReqId]);

   return (
      <>
         {" "}
         {loading && <Loader />}
         <div className="tableWrapper common_shadow p-4">
            <div className="trasferTwoCardWrapper">
               <div>
                  <div>
                     <button>
                        From <FaArrowRight />
                     </button>
                     <p>
                        {transferData?.createdAt
                           ? convertToDateInputFormat(transferData?.createdAt)
                           : "--"}
                     </p>
                  </div>
                  <div>
                     <div className="trasferDriverProfileWrapper">
                        <div>
                           <img
                              src={`${getServerUrl()}/getFiles/${transferData?.driverId?.profile}`}
                              alt="profile"
                           />
                           <div>
                              <h6 className="text-capitalize">
                                 {transferData?.driverId?.name || "--"}
                              </h6>
                              <p className="text-capitalize">
                                 {transferData?.driverId?.wardId?.length > 0
                                    ? transferData?.driverId?.wardId
                                         .map((e) => e.name)
                                         .join(", ")
                                    : "--"}
                              </p>
                           </div>
                        </div>

                        <div>
                           <img src={mapImg} alt="map" />
                           <div>
                              <h6 className="text-capitalize">
                                 {transferData?.trackId?.name || "--"}
                              </h6>
                           </div>
                        </div>
                     </div>

                     <div className="transferInputWrapper">
                        <div className="text-capitalize">
                           {transferData?.reason || "--"}
                        </div>

                        <div>
                           <div>
                              <span style={{ color: "rgba(40, 143, 67, 1)" }}>
                                 Dry
                              </span>
                              <span className="text-capitalize">
                                 {transferData?.dryCollection} Kg
                              </span>
                           </div>
                           <div>
                              {" "}
                              <span style={{ color: "rgba(0, 122, 255, 1)" }}>
                                 Wet
                              </span>
                              <span className="text-capitalize">
                                 {transferData?.wetCollection} Ltr
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <span>
                  <svg
                     width="45"
                     height="45"
                     viewBox="0 0 64 64"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M53.3337 26.666H10.667L25.3337 10.666"
                        stroke="#FFA844"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                     <path
                        d="M10.667 37.334H53.3337L38.667 53.334"
                        stroke="#FFA844"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                  </svg>
               </span>
               <div>
                  <div>
                     <button>
                        <FaArrowLeft /> Assign To
                     </button>
                  </div>
                  <div>
                     <div className="trasferDriverProfileWrapper">
                        <div>
                        <img
                              src={`${getServerUrl()}/getFiles/${assignTo?.profile}`}
                              alt="profile"
                           />
                           <div>
                              <h6 className="text-capitalize">
                                 {assignTo?.name || "--"}
                              </h6>
                              <p className="text-capitalize">
                                 {assignTo?.wardId?.length > 0
                                    ? assignTo?.wardId
                                         .map((e) => e.name)
                                         .join(", ")
                                    : "--"}
                              </p>
                           </div>
                        </div>

                        <div>
                           <img src={mapImg} alt="map" />
                           <div>
                              <h6 className="text-capitalize">
                                 {transferData?.trackId?.name || "--"}
                              </h6>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default ViewTrasferRequest;
