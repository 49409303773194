// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationCard .row {
    flex-wrap: nowrap !important;
}

.NotificationCard .row div:nth-child(2) {
    flex-grow: 2;
}

.NotificationCard .row div:first-child,
.NotificationCard .row div:last-child {
    min-width: 100px;
}

.NotificationCard .row div img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.NotificationCard .smallTick {
    display: block;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Notification/NotificationCard.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,YAAY;AAChB;;AAEA;;IAEI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".NotificationCard .row {\n    flex-wrap: nowrap !important;\n}\n\n.NotificationCard .row div:nth-child(2) {\n    flex-grow: 2;\n}\n\n.NotificationCard .row div:first-child,\n.NotificationCard .row div:last-child {\n    min-width: 100px;\n}\n\n.NotificationCard .row div img {\n    width: 80px;\n    height: 80px;\n    object-fit: contain;\n}\n\n.NotificationCard .smallTick {\n    display: block;\n    border-radius: 50%;\n    width: 12px;\n    height: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
