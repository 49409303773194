import React, { useState, useContext, useEffect } from "react";
import "./navbar.css";
import mainLogo from "../../assets/img/mainLogo.svg";
import {
  AdminNavLinks,
  ZonalNavLinks,
} from "../../constants/utilities/Navigation";
import NavButton from "./NavButton";
import { SidebarContext } from "../../constants/stateManagement/SidebarProvider";
import Cookies from "js-cookie";

export default function Sidebar() {
  const cookieValue =
    Cookies.get("loginToken") && JSON.parse(Cookies.get("loginToken"));
  const role = cookieValue?.userDetails?.userType;
  const tabAccess = cookieValue?.userDetails?.tabAccess;
  const { closeSideNav, setCloseNavBar } = useContext(SidebarContext);

  const showTab = (accessKey) => {
    return tabAccess?.some(
      (tab) => tab.tabName.toLowerCase() === accessKey.toLowerCase()
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setCloseNavBar(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setCloseNavBar]);

  const sidebarTabList = role === "zonal" ? ZonalNavLinks : AdminNavLinks;
  return (
    <div
      className={closeSideNav ? "sidebarMinWrapperClose" : "sidebarMinWrapper"}
    >
      <div>
        <img src={mainLogo} alt="logo" />
        <div
          className="hamburger"
          onClick={() => {
            setCloseNavBar((prev) => !prev);
          }}
          title={closeSideNav ? "Open Sidebar" : "Close Sidebar"}
        >
          <svg
            width="19"
            height="10"
            viewBox="0 0 19 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.9437 0.0688477H1.1086C1.1086 0.0688477 0.300049 0.241707 0.300049 0.840091C0.300049 1.43847 1.1086 1.58609 1.1086 1.58609H17.9437C17.9437 1.58609 18.7 1.38028 18.7 0.840091C18.7 0.299837 17.9437 0.0688477 17.9437 0.0688477Z"
              fill="#6A6969"
            />
            <path
              d="M10.3459 4.62061H1.01645C1.01645 4.62061 0.300049 4.79347 0.300049 5.39185C0.300049 5.99023 1.01645 6.13785 1.01645 6.13785H10.3459C10.3459 6.13785 11.0334 5.91941 11.0334 5.37923C11.0334 4.83897 10.3459 4.62061 10.3459 4.62061Z"
              fill="#6A6969"
            />
            <path
              d="M6.00535 8.41357H0.744387C0.744387 8.41357 0.300049 8.57381 0.300049 9.17219C0.300049 9.77058 0.744387 9.93082 0.744387 9.93082H6.00535C6.00535 9.93082 6.43338 9.71238 6.43338 9.17219C6.43338 8.63194 6.00535 8.41357 6.00535 8.41357Z"
              fill="#6A6969"
            />
          </svg>
        </div>
        <div
          className={
            closeSideNav ? "NavButtonsWrapperClose" : "NavButtonsWrapper"
          }
        >
          {sidebarTabList.length > 0 &&
            sidebarTabList?.map((data, index) => {
              return role === "admin" || role === "zonal" ? (
                <NavButton tabInfo={data} key={index} />
              ) : (
                showTab(data?.tabAccessKey) && (
                  <NavButton tabInfo={data} key={index} />
                )
              );
            })}
        </div>

        <div
          className={closeSideNav ? "sidebar-footerClose" : "sidebar-footer"}
        >
          <h6 className="text_primary" style={{ marginBottom: "10px" }}>
            Powered By AIITS
          </h6>
        </div>
      </div>
    </div>
  );
}
