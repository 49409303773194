import React, { useEffect, useState } from "react";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { ToggleButton } from "../../commanComponents/buttons/ToggleButtons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import { useNavigate } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import useDebounce from "../../constants/useDebounce";
import { isEven } from "../../constants/Helper/NumbersFunction";
import "./surveyManagement.css";
import Loader from "../../commanComponents/Loader";
import { PaginationSrNo } from "../../constants/Helper/Helpers";
import { Heading_6 } from "../../commanComponents/NavigationBar/Typography";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import Filter from "./Filter";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { userRole } from "../../constants/Helper/getUserRole";

function SurveyList() {
   const navigate = useNavigate();
   const [page, setPage] = useState(1);
   const [searchString, setSearchString] = useState("");
   const [pageUpdated, setPageUpdated] = useState("");
   const [selectedHouseType, setSelectedHouseType] = useState("");
   const [surveyList, setSurveyList] = useState([]);
   const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const tabAccess = checkUserTabAccess("Survey_Detail");

   const { data, isFetching, error } = useQuery(
      ["SurveyManagement", page, debouncedSearchTerm, pageUpdated],
      () =>
         commonApi?.getSurveyDetailsList(
            page,
            searchString,
            selectedHouseType?.value
         ),
      {
         staleTime: 0,
         refetchOnWindowFocus: false, 
         onSuccess: (data) => {
            if (data?.status == "SUCCESS") {
               setSurveyList(data);
            } else if (data?.status == "JWT_INVALID") {
               toast.info(`Failed : ${data?.message}`);
            } else {
               setSurveyList([]);
            }
         },
         onError: (error) => {
            toast.error(`Failed : ${error?.message}`);
         },
      }
   );

   const handleOpenFilterPopup = () => setIsPopupOpen(true);
   const handleCloseFilterPopup = () => setIsPopupOpen(false);

   useEffect(()=>{
      if (userRole() !=='zonal' && !checkUserTabAccess("Survey_Detail")) {
         toast.info('You do not have permission to access Survey Detail')
        navigate(-1); // redirect to previous page if user dont not have Tab Access
        return;
     }
     },[])

   return (
      <div>
         {isFetching && <Loader />}
         <div className="search_btn_wrapper">
            <div className="w-200">
               <Searchbox
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search Survey..."
               />
            </div>
            <div
               className="px-4 py-2 d-flex align-items-center cursor_pointer bg_support_orange rounded mr-2"
               onClick={handleOpenFilterPopup}
            >
               {svgIcons.filtersIcon}
               <Heading_6 text="Filter" className="ml-3 mb-0 text-white" />
            </div>
         </div>
         <div className="tableWrapper big_Shadow">
            <div
               className="table-responsive position-relative tablemain"
               style={{ overflowY: "auto", height: "58vh" }}
            >
               <table className="table position-relative w-100">
                  <thead
                     className="rounded-pill w-100 bg_Table_Header border-0"
                     style={{ position: "sticky", top: "-0.04px", zIndex: "1" }}
                  >
                     <tr>
                        <th scope="col" className="col_100px">
                           Sr. No
                        </th>
                        <th scope="col" className="col_200px">
                           House Type
                        </th>
                        <th scope="col" className="col_150px">
                           House/Plot No
                        </th>
                        <th scope="col" className="col_200px">
                           Mobile
                        </th>
                        <th scope="col" className="col_200px">
                           Zone
                        </th>
                        <th scope="col" className="col_200px">
                           Ward
                        </th>
                        <th scope="col" className="col_200px">
                           Expected Dry Weight
                        </th>
                        <th scope="col" className="col_200px">
                           Expected Wet Weight
                        </th>
                        <th scope="col" className="col_100px text-center row-fix-to-last table-th-bg-color">
                           Action
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {surveyList?.survey?.length > 0 &&
                        surveyList?.survey?.map((data, index) => {
                           return (
                              <tr
                                 className={`${
                                    isEven(index + 1) && "Table_Data"
                                 }`}
                              >
                                 <th scope="row">
                                    {PaginationSrNo(page, index)}
                                 </th>
                                 <td className={`text-capitalize `}>
                                    {data?.houseType || "--"}
                                 </td>
                                 <td>{data?.plotNo || "--"}</td>
                                 <td >{data?.familyInfo?.length ? data?.familyInfo[0]?.mobile : "--"}</td>
                                 <td className="text-capitalize">{data?.zoneId?.name || "--"}</td>
                                 <td className="text-capitalize">{data?.wardId?.name || "--"}</td>
                                 <td>
                                    {data?.expectedDryWeight || "--"}
                                 </td>
                                 <td>
                                    {data?.expectedWetWeight || "--"}
                                 </td>

                                 <td className="row-fix-to-last text-center">
                                       <div
                                          className=" cursor_pointer"
                                          onClick={() => {
                                             navigate(
                                                `${
                                                   data?.houseType ==
                                                   "flatscheme"
                                                      ? "/survey-detail/flat-details"
                                                      : "/survey-detail/view-survey"
                                                }?surveyDetailId=${data?._id}`
                                             );
                                          }}
                                          title="View"
                                       >
                                          {svgIcons.eyeIcon}
                                    </div>
                                 </td>
                              </tr>
                           );
                        })}
                  </tbody>
               </table>
               {surveyList?.length == 0 && (
                  <h6 className="DataNotFoundText">No Survey Found...</h6>
               )}
            </div>
         </div>

         <div className="mt-2">
            <PaginationButton
               page={page}
               setPage={setPage}
               totalPages={surveyList?.totalPages || 1}
            />
         </div>

         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleCloseFilterPopup}
            title="Filter"
         >
            <Filter
               onClose={handleCloseFilterPopup}
               setSelectedHouseType={setSelectedHouseType}
               selectedHouseType={selectedHouseType}
               setPageUpdated={setPageUpdated}
            />
         </CenteredPopup>
      </div>
   );
}

export default SurveyList;
