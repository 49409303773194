import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { toast } from "react-toastify";
import { commonApi } from "../../constants/apis/adminCommunication"; // Assuming this is where changeUserPassword is imported
import { Heading_3 } from "../../commanComponents/NavigationBar/Typography";
import InputWithIcon from "../../commanComponents/inputs/InputWithIcon";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";

const ChangePassword = ({ userId }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      userId, // Pre-fill userId
    },
  });

  const [visibility, setVisibility] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const navigate = useNavigate();

  // Mutation to call the changeUserPassword API
  const mutation = useMutation(({ userId, newPassword, confirmPassword }) =>
    commonApi.changeUserPassword(userId, newPassword, confirmPassword), {
    onSuccess: async (data) => {
      if (data?.status === "SUCCESS") {
        toast.success("Password reset successfully!");
        navigate("/login");
      } else {
        toast.error(`Failed to reset password: ${data?.message}`);
      }
    },
    onError: (error) => {
      toast.error("An error occurred while resetting the password.");
      console.error("Error:", error);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate({
      userId: data.userId,
      newPassword: data.newpassword,
      confirmPassword: data.confirmpassword,
    });
  };

  const toggleVisibility = (field) => {
    setVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="py-2">
        <div className="d-flex row w-100 mx-auto">
          <div className="col-12 p-0">
            <InputWithIcon
              label="User Id"
              control={control}
              name="userId"
              className="my-2"
              isDisabled={true} // Disable the input
              icon={svgIcons.userIcon}
              placeholder="User Id"
              value={userId} // Pre-fill the userId
            />
          </div>
          <div className="col-12 p-0">
            <InputWithIcon
              control={control}
              label="New Password"
              name="newpassword"
              className="my-2"
              type={visibility.newPassword ? "text" : "password"}
              icon={
                visibility.newPassword ? svgIcons.closeEye : svgIcons.openEye
              }
              placeholder="New Password..."
              handleClick={() => toggleVisibility("newPassword")}
            />
          </div>
          <div className="col-12 p-0">
            <InputWithIcon
              control={control}
              label="Confirm Password"
              name="confirmpassword"
              className="my-2"
              type={visibility.confirmPassword ? "text" : "password"}
              icon={
                visibility.confirmPassword
                  ? svgIcons.closeEye
                  : svgIcons.openEye
              }
              placeholder="Confirm Password..."
              handleClick={() => toggleVisibility("confirmPassword")}
            />
          </div>
        </div>

        <div className="my-3 text-center">
          <button
            type="submit"
            className="btn bg_primary text-white mx-auto px-5"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
