// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DepartmentsListWithUsersbuttons {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: .5em;
    height: 58vh;
}

.departmentUserListBtn:hover{
    background-color: #f7ffe1 !important;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.departmentUserListBtn{
    transition: all 0.2s ease-in-out;
}

.departmentUserListBtnSelected{
    background-color: #f2fbd7 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/roleaccess/DepartmentsListWithUsers.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,gCAAgC;AACpC;AACA;IACI,gCAAgC;AACpC;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".DepartmentsListWithUsersbuttons {\n    position: relative;\n    overflow-y: auto;\n    overflow-x: hidden;\n    display: flex;\n    flex-direction: column;\n    gap: .5em;\n    height: 58vh;\n}\n\n.departmentUserListBtn:hover{\n    background-color: #f7ffe1 !important;\n    cursor: pointer;\n    transition: all 0.2s ease-in-out;\n}\n.departmentUserListBtn{\n    transition: all 0.2s ease-in-out;\n}\n\n.departmentUserListBtnSelected{\n    background-color: #f2fbd7 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
