import React, { useContext, useEffect, useRef, useState } from "react";
import "./navbar.css";
import defaultProfile from "../../assets/img/defaultProfile.svg";
import { FaAngleDown } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { getServerUrl } from "../../constants/apis/adminCommunication";
import { UserDataContext } from "../../constants/stateManagement/GetUserDataProvider";

export default function Topbar() {
   const { userData } = useContext(UserDataContext);
   const navigation = useNavigate();
   const [showDropdown, setShowDropdown] = useState(false);
   const dropdownRef = useRef(null);

   const toggleDropdown = () => {
      setShowDropdown((prev) => !prev);
   };

   const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
         setShowDropdown(false);
      }
   };
   useEffect(() => {
      if (showDropdown) {
         document.addEventListener("click", handleClickOutside);
      } else {
         document.removeEventListener("click", handleClickOutside);
      }
      return () => {
         document.removeEventListener("click", handleClickOutside);
      };
   }, [showDropdown]);

   const handleLogout = () => {
      Cookies.remove("loginToken");
      toast.success("Logout Successfull");
      navigation("/login");
   };

   return (
      <div className="topbarMainWrapper">
         <div>
            <div>
               <Link
                  to="/notification"
                  className="notificationIconWrapper"
                  title="Notification"
               >
                  <svg
                     width="22"
                     height="26"
                     viewBox="0 0 26 30"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M24.0065 14.4972C22.9716 13.2888 22.5014 12.2415 22.5014 10.4623V9.85736C22.5014 7.53885 21.9677 6.04502 20.8076 4.55118C19.0195 2.23125 16.0092 0.833008 13.0623 0.833008H12.937C10.0521 0.833008 7.13618 2.16704 5.31708 4.39281C4.09356 5.91661 3.49799 7.47465 3.49799 9.85736V10.4623C3.49799 12.2415 3.0587 13.2888 1.99287 14.4972C1.20863 15.3875 0.958008 16.5318 0.958008 17.7703C0.958008 19.0101 1.36491 20.1844 2.18153 21.1389C3.24737 22.2831 4.75249 23.0137 6.28999 23.1406C8.51599 23.3946 10.742 23.4902 13.0004 23.4902C15.2574 23.4902 17.4834 23.3304 19.7108 23.1406C21.2469 23.0137 22.752 22.2831 23.8178 21.1389C24.633 20.1844 25.0413 19.0101 25.0413 17.7703C25.0413 16.5318 24.7907 15.3875 24.0065 14.4972Z"
                        fill="#8A92A6"
                     />
                     <path
                        opacity="0.4"
                        d="M15.8455 25.2394C15.1373 25.0882 10.8219 25.0882 10.1136 25.2394C9.50822 25.3792 8.85352 25.7045 8.85352 26.4179C8.88871 27.0985 9.28717 27.6992 9.83909 28.0801L9.83769 28.0815C10.5515 28.638 11.3893 28.9918 12.2664 29.1188C12.7339 29.183 13.2098 29.1802 13.6941 29.1188C14.5699 28.9918 15.4076 28.638 16.1215 28.0815L16.1201 28.0801C16.672 27.6992 17.0704 27.0985 17.1056 26.4179C17.1056 25.7045 16.4509 25.3792 15.8455 25.2394Z"
                        fill="#8A92A6"
                     />
                  </svg>
               </Link>

               <div ref={dropdownRef} title="Profile">
                  <div className="profileBtnWrapper" onClick={toggleDropdown}>
                     <img
                        alt={"profile"}
                        src={
                           userData?.profile
                              ? `${getServerUrl()}/getFiles/${
                                   userData?.profile
                                }`
                              : defaultProfile
                        }
                     />
                     <div>
                        <h6 className="text-capitalize">{userData?.name}</h6>
                        <div>
                           <div></div>{" "}
                           <h6 className="text-capitalize">
                              {userData?.userType}
                           </h6>
                        </div>
                     </div>
                     <FaAngleDown />
                  </div>

                  <div
                     className={
                        showDropdown
                           ? "profile-dropdown-menu"
                           : "profile-dropdown-menu-close"
                     }
                  >
                     <div>
                        <div
                           onClick={() => {
                              navigation("/profile");
                              toggleDropdown()
                           }}
                        >
                           Profile
                        </div>
                        <div onClick={handleLogout} title="Logout">
                           Logout
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
