
import './buttons.css'

export default function Button({ text, onClick, disabled }) {
   return (
         <button onClick={onClick} disabled={disabled} className="button" title={text}>
            {text}
         </button>
   );
}

export const TransparentBtnWithBorder = ({text="Zone 1", bg="bg-transparent", color="text_lime_green", isActive=false, className, onClick }) => {
   return <button className={`rounded px-3 btn TransparentBtnWithBorder text-capitalize text_lime_green ${color} ${bg} ${className}`} onClick={onClick} style={{whiteSpace: "nowrap"}} title={text}>{text}</button>
 }