import ManageDepartmentsMain from "../components/manage departments/ManageDepartmentsMain"

const ManageDepartments = () => {
  return (
   <>
   <ManageDepartmentsMain />
   </>
  )
}

export default ManageDepartments