import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import Layout from "./pages/Layout";
import Router from "./routes/route";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { store } from "./store/reducer";
import { Provider } from "react-redux";
const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <div className="App transparant_Scrollbar">
            {/* Main router page */}
            <Router />
            <ToastContainer autoClose={3000} />
          </div>
        </Provider>
      </QueryClientProvider>
    </>
  );
}

export default App;
