// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoleAccessMain_TableWrapper {
    border-radius: 10px !important;
    background: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/roleaccess/RoleAccessMain.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,iBAAiB;AACrB","sourcesContent":[".RoleAccessMain_TableWrapper {\n    border-radius: 10px !important;\n    background: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
