import React, { useEffect, useState } from "react";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { ToggleButton } from "../../commanComponents/buttons/ToggleButtons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import { useNavigate } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import useDebounce from "../../constants/useDebounce";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import Loader from "../../commanComponents/Loader";
import Attachment from "../../commanComponents/Attachment";
import { isEven } from "../../constants/Helper/NumbersFunction";
import { PaginationSrNo } from "../../constants/Helper/Helpers";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { userRole } from "../../constants/Helper/getUserRole";
import { DocumentsShowCard } from "../../commanComponents/Images/ImagesShowCard";

function AnnouncementsList() {
   const navigate = useNavigate();
   const [page, setPage] = useState(1);
   const [searchString, setSearchString] = useState("");
   const [pageUpdated, setPageUpdated] = useState("");
   const [announcementData, setAnnouncementData] = useState([]);
   const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [attachmentFile, setAttachmentFile] = useState("");

   const { data, isFetching, error } = useQuery(
      ["announcements", page, debouncedSearchTerm, pageUpdated],
      () => commonApi?.getAnnouncementList(page, searchString),
      {
         refetchOnWindowFocus: false, 
         onSuccess: (data) => {
            if (data?.status == "SUCCESS") {
               setAnnouncementData(data?.result);
            } else if (data?.status == "JWT_INVALID") {
               toast.error(`Failed : ${data?.message}`);
            }
            if (data?.status == "FAILED") {
               setAnnouncementData([]);
            }
         },
         onError: (error) => {
            toast.error(`Failed : ${error?.message}`);
         },
      }
   );

   const updateActiveStatus = useMutation(
      (data) => {
         setLoading(true);
         return commonApi.updateAnnouncementStatus(data);
      },
      {
         onSuccess: (data) => {
            if (data?.status === "SUCCESS") {
               setLoading(false);
               toast.success(`Success : ${data?.message}`);
               setPageUpdated((prev) => !prev);
            } else if (data?.status == "JWT_INVALID") {
               setLoading(false);
               toast.error(`Failed : ${data?.message}`);
            } else {
               setLoading(false);
               toast.error(`Failed : ${data?.message}`);
            }
         },
         onError: (error) => {
            setLoading(false);
            toast.error(`Failed : ${error?.message}`);
         },
      }
   );

   useEffect(()=>{
      if (!checkUserTabAccess("Announcements") || userRole() == 'zonal') {
         toast.info('You do not have permission to access Announcements')
         navigate(-1); // redirect to previous page if user dont not have Tab Access
        return;
     }
     },[])

   const handleClosePopup = () => setIsPopupOpen(false);

   return (
      <div>
         {(isFetching || loading) && <Loader />}
         <div className="search_btn_wrapper">
            <div className="w-200">
               <Searchbox
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search Announcement..."
               />
            </div>
            {checkUserTabAccess("Announcements")?.access === 'write' && <div className="buttons_wrapper">
               <RoundedbuttonWithIcon
                  text={"Add Announcement"}
                  textClasses="text-uppercase"
                  icon={svgIcons.PlusIcon}
                  onClick={() => {
                     navigate(
                        "/announcements/announcement-management?type=add"
                     );
                  }}
               />
            </div>}
         </div>
         <div className="tableWrapper common_shadow">
            <div
               className="table-responsive position-relative tablemain"
               style={{ overflowY: "auto", height: "58vh" }}
            >
               <table className="table position-relative w-100">
                  <thead
                     className="rounded-pill w-100 bg_Table_Header border-0"
                     style={{ position: "sticky", top: "-0.04px", zIndex: "1" }}
                  >
                     <tr>
                        <th scope="col" className="col_100px">
                           Sr. No
                        </th>
                        <th scope="col" className="col_250px">
                           Subject
                        </th>
                        <th scope="col" className="col_200px">
                           Publish Date
                        </th>
                        <th scope="col" className="col_250px">
                           Description
                        </th>
                        <th scope="col" className="col_150px text-center">
                           Attachment
                        </th>
                        <th scope="col"  className="col_150px text-center row-fix-to-last table-th-bg-color">
                           Action
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {announcementData?.length > 0 &&
                        announcementData?.map((data, index) => {
                           return (
                              <tr
                                 className={`${
                                    isEven(index + 1) && "Table_Data"
                                 }`}
                              >
                                 <th>{PaginationSrNo(page, index)}</th>
                                 <td  className={`text-capitalize `}>{data?.title ? data?.title : "--"}</td>
                                 <td>
                                    {data?.date
                                       ? new Date(
                                            data?.date
                                         )?.toLocaleDateString("hi")
                                       : "--"}
                                 </td>
                                 <td  className={`text-capitalize `}>
                                    {data?.description
                                       ? data?.description
                                       : "--"}
                                 </td>
                                 <td
                                    className="text-center cursor_pointer"
                                    onClick={() => {
                                       setAttachmentFile(data?.attachment);
                                       setIsPopupOpen(true);
                                    }}
                                    title="Attachment"
                                 >
                                    {svgIcons?.attachmentIcon}
                                 </td>
                                 <td className="row-fix-to-last text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                       {(data?.isActive && !data?.isEdited) && (
                                          <div
                                             className="mr-2 cursor_pointer"
                                             title="Edit"
                                             onClick={() => {
                                                navigate(
                                                   `/announcements/announcement-management?type=update&announcementId=${data?._id}`
                                                );
                                             }}
                                          >
                                             {svgIcons.editIcon}
                                          </div>
                                       )}
                                       <ToggleButton
                                          fill="sas"
                                          onChange={() => {
                                             updateActiveStatus.mutate(
                                                data?._id
                                             );
                                          }}
                                          isActive={data?.isActive}
                                          disabled={checkUserTabAccess("Announcements")?.access !== 'write'}
                                       />
                                    </div>
                                 </td>
                              </tr>
                           );
                        })}
                  </tbody>
               </table>
               {announcementData?.length == 0 && (
                  <h6 className="DataNotFoundText">
                     No Announcements Found...
                  </h6>
               )}
            </div>
         </div>

         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            title="Attachment"
            style={{maxWidth:'40%', minWidth:'500px'}}
         >
            <DocumentsShowCard  documents={attachmentFile} />
         </CenteredPopup>

         <div className="mt-2">
            <PaginationButton
               page={page}
               setPage={setPage}
               totalPages={data?.totalPages || 1}
            />
         </div>
      </div>
   );
}

export default AnnouncementsList;
