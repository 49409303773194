import React, { useCallback, useEffect, useMemo, useState } from "react";
import { commonApi } from "../../constants/apis/adminCommunication";
import markerIcon from "../../assets/icons/marker_map.png";
import { IoCloseCircleSharp } from "react-icons/io5";
import { GoogleMap, Marker } from "@react-google-maps/api";
import UserMarker from "./UserMarker";
const MapWithDirections = ({ location, zoneId, trackId }) => {
  const [map, setMap] = useState(null);
  const containerStyle = {
    width: "100%",
    height: "555px",
  };
  const center = {
    lat: 21.149539260461108,
    lng: 79.08211870155534,
  };
  const [houseData, setHouseData] = useState([]);
  const [houseClick, setHouseClick] = useState({
    isClick: false,
    houseId: "",
  });
  console.log("location in dummy components", location);
  console.log("zoneId in dummy components", zoneId);
  console.log("wardId in dummy components", trackId);
  const [aHouseData, setAHouseData] = useState();

  const getAllDynamoDBHouses = async () => {
    const formData = {
      trackId,
      zoneId,
    };
    const responseData = await commonApi.liveTrackingGetAllDynamoHouses(
      formData
    );
    console.log("responseData", responseData);
    setHouseData(responseData?.house);
    console.log(responseData?.house);
    // dispatch(addHouses(responseData?.house));
  };
  useEffect(() => {
    getAllDynamoDBHouses();
  }, []);
  // let locationVehicle;
  // useEffect(() => {
  //   locationVehicle = location.filter(
  //     (loc) => loc.zoneId === zoneId && loc.trackId === trackId
  //   );
  // }, [location, trackId, zoneId]);

  console.log("i am rerender");

  const fetchHouseData = async (id) => {
    console.log(id);
    let formData = {
      houseId: id,
    };
    const houseData = await commonApi.getAHouseGarbageData(formData);
    setAHouseData(houseData);
    console.log(houseData.garbage);
  };

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const blackMarkerIcon = {
    url: require("./../../assets/icons/truck.png"), // Replace with your icon URL
    fillColor: "#EB00FF",
    scale: 0.5,
  };
  console.log(location, "locationVehicle");
  const customMarkerIcon = useMemo(
    () => ({
      url: require("./../../assets/icons/marker_map.png"),
      fillColor: "#EB00FF",
      scale: 0.5,
    }),
    []
  );
  const customOpenPlotIcon = useMemo(
    () => ({
      url: require("./../../assets/img/openPlot.png"),
      fillColor: "#EB00FF",
      scale: 0.5,
    }),
    []
  );
  return (
    <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
      <div
        style={{
          height: "65vh",
          width: houseClick.isClick ? "60%" : "100%",
          boxShadow: "1px solid",
          marginTop: 20,
        }}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: location[0]?.latitude,
            lng: location[0]?.longitude,
          }}
          zoom={15}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {location?.map((loc) => (
            <UserMarker location={loc} />
          ))}
          {/* <Marker
            position={{
              lat: location[0]?.latitude,
              lng: location[0]?.longitude,
            }}
            icon={blackMarkerIcon}
          /> */}
          {/* Render markers */}
          {houseData.map((loc, index) => {
            console.log("marker", loc);
            return (
              <Marker
                key={index}
                title={loc.ownerName}
                position={{
                  lat: Number(loc.latitude),
                  lng: Number(loc.longitude),
                }}
                icon={
                  loc.houseType === "open plot"
                    ? customOpenPlotIcon
                    : customMarkerIcon
                }
              />
            );
          })}
        </GoogleMap>
      </div>

      {houseClick?.isClick && aHouseData && (
        <div
          style={{
            marginTop: 20,
            width: "40%",
            height: "65vh",
          }}
        >
          <div
            style={{
              padding: "20px",
              marginLeft: "10px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              overflowY: "scroll",
              wordWrap: "break-word",
              backgroundColor: "#fff",
              backgroundClip: "borderbox",
              border: "1px solid rgba(0, 0, 0, .125)",
              borderRadius: ".25rem",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h5>Name: {aHouseData?.ownerName}</h5>
                <p>House Type: {aHouseData?.houseType}</p>
              </div>
              <div>
                <IoCloseCircleSharp
                  size={30}
                  color="red"
                  onClick={() => setHouseClick({})}
                />
              </div>
            </div>
            {console.log(aHouseData)}
            <div className="accordion-item">
              <div>
                {/* <h3>Day: {item.day}</h3> */}
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th style={{ padding: "10px 0", textAlign: "center" }}>
                        Dry
                      </th>
                      <th style={{ padding: "10px 0", textAlign: "center" }}>
                        Wet
                      </th>
                      <th style={{ padding: "10px 0", textAlign: "center" }}>
                        Hazardous
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {aHouseData?.status === "SUCCESS" &&
                      aHouseData?.garbage?.map((item, index) => (
                        <tr key={index}>
                          <td style={{ padding: "10px 0" }}>{item.day}</td>
                          <td
                            style={{ padding: "10px 0", textAlign: "center" }}
                          >
                            {item.totalDryIncludingExceed}
                          </td>
                          <td
                            style={{ padding: "10px 0", textAlign: "center" }}
                          >
                            {item.totalWetIncludingExceed}
                          </td>
                          <td
                            style={{ padding: "10px 0", textAlign: "center" }}
                          >
                            {item.hazardousWasteCollected}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MapWithDirections;
