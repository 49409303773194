import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { Heading_6 } from "../../commanComponents/NavigationBar/Typography";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import useDebounce from "../../constants/useDebounce";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import EditAndToggleBtn from "../../commanComponents/buttons/EditAndToggleBtn";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import AddWardForm from "../../commanComponents/forms/AddWardForm";
import { isEven } from "../../constants/Helper/NumbersFunction";
import Loader from "../../commanComponents/Loader";
import Spinner from "../../commanComponents/Spinner";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { useNavigate } from "react-router-dom";
import BulkUploadExSheetBtn from "../../commanComponents/buttons/BulkUploadExSheetBtn";
import FileSaver from "file-saver";

const WardList = () => {
   const [searchTerm, setSearchTerm] = useState("");
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [isListUpdated, setIsListUpdated] = useState(false);
   const [ward, setWard] = useState(null); // Use null to indicate no ward is selected
   const debouncedSearchTerm = useDebounce(searchTerm, 1000);
   const queryClient = useQueryClient();
   const route = useNavigate();
   const [selectedBulkUploadFile, setSelectedBulkUploadFile] = useState({});
   const [bulkUploadLoading, setBulkUploadLoading] = useState(false);

   const fetchWardList = useCallback(
      async ({ pageParam = 1 }) => {
         try {
            const response = await commonApi.getWardList(
               pageParam,
               debouncedSearchTerm
            );
            if (response?.status === "SUCCESS") {
               return response;
            } else if (response?.status === "JWT_INVALID") {
               toast.error(`Failed: ${response?.message}`);
            }
            return { ward: [] };
         } catch (error) {
            toast.error("An error occurred while fetching the ward list.");
            return { ward: [] };
         }
      },
      [debouncedSearchTerm]
   );

   const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
      useInfiniteQuery(
         ["wards", { searchString: debouncedSearchTerm }, isListUpdated],
         fetchWardList,
         {
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage, pages) => {
               if (lastPage.ward.length < 10) return undefined;
               return pages.length + 1;
            },
         }
      );

   const mutation = useMutation((id) => commonApi.changeWardStatus(id), {
      onSuccess: (data) => {
         if (data?.status === "SUCCESS") {
            toast.success("Ward status updated successfully!");
            queryClient.invalidateQueries("wards");
            setIsListUpdated((prev) => !prev);
         } else if (data?.status === "JWT_INVALID") {
            toast.error(`Failed: ${data?.message}`);
         } else {
            toast.error(data.message || "Error updating status!");
         }
      },
      onError: (error) => {
         toast.error(`Error updating status: ${error.message}`);
      },
   });

   const handleWardEdit = (id) => {
      const wardToEdit = data?.pages
         .flatMap((page) => page.ward)
         .find((ward) => ward._id === id);
      if (wardToEdit) {
         setWard(wardToEdit);
         setIsPopupOpen(true);
      } else {
         toast.error("Ward not found in the current data!");
      }
   };

   const HandlebulkUploadWards = async () => {
      const formData = new FormData();

      if (!selectedBulkUploadFile?.type) {
         return;
      }

      formData.append("file", selectedBulkUploadFile);
      try {
         setBulkUploadLoading(true);
         const serverResponse = await commonApi.bulkUploadWards(formData);

         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
            setIsListUpdated((prev) => !prev);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.error(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setBulkUploadLoading(false);
         setSelectedBulkUploadFile({});
      }
   };

   const downloadTemplate = async () => {
      try {
         const serverResponse = await commonApi.downloadWardTamplate();

         if (serverResponse) {
            // Create a Blob from the response
            const file = new Blob([serverResponse], {
               type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(file, `ward-template.xlsx`);
            toast.success("Ward tamplate downloaded successfully!");
         } else {
            toast.info("Failed to download ward tamplate. Please try again.");
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      }
   };

   const handleOpenPopup = () => {
      setWard(null);
      setIsPopupOpen(true);
   };

   const handleClosePopup = () => {
      setIsPopupOpen(false);
      setWard(null);
   };

   useEffect(() => {
      HandlebulkUploadWards();
   }, [selectedBulkUploadFile]);

   return (
      <div className="big_Shadow rounded p-3 h-100 bg-white">
         <div className="d-flex align-items-center mb-3 justify-content-between">
            <Heading_6
               text={`Ward List (${data?.pages[0].totalRecords || 0})`}
               className="fw-bold text-capitalize"
            />
            {checkUserTabAccess("Zone_Ward")?.access === "write" && (
               <BulkUploadExSheetBtn
                  btnText={"Bulk Upload"}
                  setSelectedBulkUploadFile={setSelectedBulkUploadFile}
                  btnLoading={bulkUploadLoading}
                  handleDownloadTemplate={downloadTemplate}
               />
            )}
         </div>
         <div className="d-flex align-items-center justify-content-between">
            <div className="w-50 pr-2">
               <Searchbox
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
               />
            </div>
            {checkUserTabAccess("Zone_Ward")?.access === "write" && (
               <div className="w-50 d-flex justify-content-end">
                  <RoundedbuttonWithIcon
                     text="Add Ward"
                     className="text-uppercase w-75"
                     icon={svgIcons.PlusIcon}
                     onClick={handleOpenPopup}
                  />
               </div>
            )}
         </div>
         <div className="container p-0 w-100 mt-3 position-relative">
            <div
               className="table-responsive position-relative tablemain"
               style={{ maxHeight: "55vh", overflowY: "auto" }}
               id="scrollableDesignationDiv"
            >
               {data?.pages[0]?.ward?.length > 0 ? (
                  <InfiniteScroll
                     dataLength={
                        data?.pages.flatMap((page) => page.ward).length || 0
                     }
                     next={fetchNextPage}
                     hasMore={hasNextPage}
                     scrollableTarget="scrollableDesignationDiv"
                  >
                     <table className="table w-100 overflow-auto">
                        <thead
                           className="rounded-pill w-100 bg_Table_Header border-0 z_index_1"
                           style={{
                              position: "sticky",
                              top: "0px",
                              right: "0px",
                              zIndex: "1",
                           }}
                        >
                           <tr>
                              <th scope="col" className="col_100px">
                                 Sr. No
                              </th>
                              <th scope="col" className="col_200px">
                                 Zone Name
                              </th>
                              <th scope="col" className="col_200px">
                                 Ward Name
                              </th>
                              <th
                                 scope="col"
                                 className="col_100px text-center row-fix-to-last table-th-bg-color"
                              >
                                 Action
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           {data?.pages
                              .flatMap((page) => page.ward)
                              .map((ward, index) => (
                                 <tr key={ward._id}>
                                    <th
                                       scope="row"
                                       className={`${
                                          isEven(index + 1) && "Table_Data"
                                       }`}
                                    >
                                       {index + 1}
                                    </th>
                                    <td
                                       className={`text-capitalize ${
                                          isEven(index + 1) && "Table_Data"
                                       }`}
                                    >
                                       {ward?.zoneId?.name || "N/A"}
                                    </td>
                                    <td
                                       className={`text-capitalize ${
                                          isEven(index + 1) && "Table_Data"
                                       }`}
                                    >
                                       {ward.name}
                                    </td>
                                    <td
                                       className={`text-capitalize row-fix-to-last text-center`}
                                    >
                                       <EditAndToggleBtn
                                          isActive={ward.isActive}
                                          handleClick2={() =>
                                             mutation.mutate(ward?._id)
                                          }
                                          handleClick1={() =>
                                             handleWardEdit(ward?._id)
                                          }
                                          disabled={
                                             checkUserTabAccess("Zone_Ward")
                                                ?.access !== "write"
                                          }
                                       />
                                    </td>
                                 </tr>
                              ))}
                        </tbody>
                     </table>
                  </InfiniteScroll>
               ) : (
                  !isFetching && (
                     <h6 className="DataNotFoundText">No Wards Found...</h6>
                  )
               )}
            </div>

            {isFetching && (
               <div className="m-3 text-center">
                  <Spinner />
               </div>
            )}
         </div>
         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            title={ward ? "Edit Ward" : "Create Ward"}
         >
            <AddWardForm ward={ward} onClose={handleClosePopup} />
         </CenteredPopup>
      </div>
   );
};

export default WardList;
