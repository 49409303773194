import React from 'react'
import SurveyList from '../components/surveyManagement/SurveyList'

export default function SurveyManagement() {
  return (
    <div>
      <SurveyList />
    </div>
  )
}
