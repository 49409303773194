export const svgIcons = {
   PlusIcon: (
      <svg
         width="28"
         height="28"
         viewBox="0 0 28 28"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            opacity="0.5"
            d="M14 26.686C20.9036 26.686 26.5 21.0896 26.5 14.186C26.5 7.28248 20.9036 1.68604 14 1.68604C7.09644 1.68604 1.5 7.28248 1.5 14.186C1.5 21.0896 7.09644 26.686 14 26.686Z"
            stroke="#1C274C"
            strokeWidth="2" // Changed from strokeWidth to strokeWidth
         />
         <path
            d="M17.75 14.186H14M14 14.186H10.25M14 14.186V10.436M14 14.186V17.936"
            stroke="#2E3A3A"
            strokeWidth="2" // Changed from strokeWidth to strokeWidth
            strokeLinecap="round" // Changed from strokeLinecap to strokeLinecap
         />
      </svg>
   ),
   editIcon: (
      <svg
         width="20"
         height="20"
         viewBox="0 0 25 25"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.0015 2.82008C20.7811 1.59969 18.8026 1.59968 17.5821 2.82008L15.8323 4.56987L7.59692 12.8052C7.46342 12.9388 7.36873 13.1061 7.32293 13.2892L6.28127 17.4559C6.19252 17.8109 6.29653 18.1864 6.55526 18.445C6.81399 18.7038 7.1895 18.8078 7.54448 18.7191L11.7111 17.6774C11.8943 17.6316 12.0615 17.5369 12.1951 17.4034L20.3706 9.22791L22.1803 7.41821C23.4007 6.19783 23.4007 4.21918 22.1803 2.9988L22.0015 2.82008ZM19.0553 4.29321C19.462 3.88642 20.1216 3.88642 20.5284 4.29321L20.7071 4.47193C21.1139 4.87873 21.1139 5.53829 20.7071 5.94508L19.6483 7.00395L18.0281 5.32042L19.0553 4.29321ZM16.5546 6.79383L18.1749 8.47736L10.926 15.7263L8.72346 16.2769L9.27408 14.0744L16.5546 6.79383ZM4.16683 8.33351C4.16683 7.75821 4.6332 7.29184 5.2085 7.29184H10.4168C10.9921 7.29184 11.4585 6.82547 11.4585 6.25017C11.4585 5.67488 10.9921 5.2085 10.4168 5.2085H5.2085C3.48261 5.2085 2.0835 6.60762 2.0835 8.33351V19.7918C2.0835 21.5177 3.48261 22.9168 5.2085 22.9168H16.6668C18.3928 22.9168 19.7918 21.5177 19.7918 19.7918V14.5835C19.7918 14.0083 19.3255 13.5418 18.7502 13.5418C18.1749 13.5418 17.7085 14.0083 17.7085 14.5835V19.7918C17.7085 20.3671 17.2421 20.8335 16.6668 20.8335H5.2085C4.6332 20.8335 4.16683 20.3671 4.16683 19.7918V8.33351Z"
            fill="#007AFF"
         />
      </svg>
   ),
   userIcon: (
      <svg
         width="32"
         height="27"
         viewBox="0 0 32 27"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M20 9.5C20 11.9853 17.7614 14 15 14C12.2386 14 10 11.9853 10 9.5C10 7.01472 12.2386 5 15 5C17.7614 5 20 7.01472 20 9.5Z"
            stroke="#76B95F"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M15 15C10.1675 15 6.25 18.5258 6.25 22.875H23.75C23.75 18.5258 19.8325 15 15 15Z"
            stroke="#76B95F"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   ),
   openEye: (
      <svg
         width="32"
         height="20"
         viewBox="0 0 32 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M13.1256 9C13.1256 10.2427 11.9504 11.25 10.5006 11.25C9.05088 11.25 7.87561 10.2427 7.87561 9C7.87561 7.75732 9.05088 6.75 10.5006 6.75C11.9504 6.75 13.1256 7.75732 13.1256 9Z"
            fill="#76B95F"
            stroke="#76B95F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M10.5011 3.75C6.5831 3.75 3.26661 5.95716 2.15161 9C3.26659 12.0428 6.5831 14.25 10.5011 14.25C14.419 14.25 17.7355 12.0428 18.8505 9C17.7355 5.95718 14.419 3.75 10.5011 3.75Z"
            stroke="#76B95F"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   ),

   closeEye: (
      <svg
         width="32"
         height="20"
         viewBox="0 0 32 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <g clipPath="url(#clip0_348_5127)">
            <path
               d="M19.8188 9.66879C18.9 8.53129 15.4875 4.81254 10.5 4.81254C9.94321 4.811 9.3872 4.8549 8.83752 4.94379L17.4563 13.6063C18.3355 12.9494 19.1292 12.1851 19.8188 11.3313C20.0107 11.0968 20.1156 10.8031 20.1156 10.5C20.1156 10.197 20.0107 9.9033 19.8188 9.66879Z"
               fill="#76B95F"
            />
            <path
               d="M17.9813 16.7563L12.8626 11.6375C12.6528 12.0859 12.3186 12.4645 11.8998 12.7282C11.4809 12.9919 10.995 13.1297 10.5001 13.125C9.80386 13.125 9.13618 12.8484 8.6439 12.3562C8.15162 11.8639 7.87506 11.1962 7.87506 10.5C7.87038 10.005 8.00812 9.51917 8.27185 9.1003C8.53559 8.68143 8.91419 8.34722 9.36256 8.1375L4.24381 3.01875C4.0753 2.89238 3.86687 2.83102 3.65678 2.84595C3.44668 2.86088 3.24902 2.9511 3.10009 3.10003C2.95115 3.24897 2.86094 3.44663 2.84601 3.65672C2.83108 3.86682 2.89243 4.07525 3.01881 4.24375L5.1188 6.34375C3.6083 7.19377 2.2723 8.32195 1.18131 9.66875C0.989382 9.90327 0.884521 10.197 0.884521 10.5C0.884521 10.803 0.989382 11.0967 1.18131 11.3313C2.10006 12.4688 5.51256 16.1875 10.5001 16.1875C11.7707 16.1702 13.0288 15.9334 14.2188 15.4875L16.7563 17.9813C16.8315 18.0815 16.9274 18.1645 17.0375 18.2245C17.1476 18.2845 17.2692 18.3202 17.3943 18.3291C17.5193 18.3379 17.6448 18.3199 17.7623 18.276C17.8797 18.2322 17.9864 18.1636 18.075 18.075C18.1637 17.9863 18.2322 17.8797 18.2761 17.7622C18.3199 17.6448 18.338 17.5193 18.3291 17.3942C18.3202 17.2692 18.2846 17.1475 18.2246 17.0374C18.1646 16.9274 18.0816 16.8315 17.9813 16.7563Z"
               fill="#76B95F"
            />
         </g>
         <defs>
            <clipPath id="clip0_348_5127">
               <rect width="21" height="21" fill="white" />
            </clipPath>
         </defs>
      </svg>
   ),
   filtersIcon: (
      <svg
         width="29"
         height="25"
         viewBox="0 0 29 25"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M28 3.875H25M28 12.5H20.5M28 21.125H20.5M7 24V14.7442C7 14.4452 7 14.2957 6.96937 14.1527C6.9422 14.0259 6.89727 13.9031 6.83578 13.7877C6.76647 13.6578 6.66901 13.541 6.47409 13.3074L1.52592 7.38C1.33099 7.14651 1.23353 7.02975 1.16422 6.89974C1.10273 6.7844 1.0578 6.66162 1.03063 6.53475C1 6.39175 1 6.24223 1 5.9432V3.3C1 2.49493 1 2.09239 1.16348 1.78489C1.3073 1.51441 1.53678 1.2945 1.81902 1.15667C2.13988 1 2.55992 1 3.4 1H16.6C17.4401 1 17.8602 1 18.181 1.15667C18.4633 1.2945 18.6926 1.51441 18.8365 1.78489C19 2.09239 19 2.49493 19 3.3V5.9432C19 6.24223 19 6.39175 18.9694 6.53475C18.9422 6.66162 18.8973 6.7844 18.8358 6.89974C18.7665 7.02975 18.6689 7.14651 18.4741 7.38L13.5259 13.3074C13.3311 13.541 13.2335 13.6578 13.1642 13.7877C13.1027 13.9031 13.0578 14.0259 13.0306 14.1527C13 14.2957 13 14.4452 13 14.7442V19.6875L7 24Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   ),
   backIcon: (
      <svg
         width="45"
         height="45"
         viewBox="0 0 45 45"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M16.875 41.25H28.125C37.5 41.25 41.25 37.5 41.25 28.125V16.875C41.25 7.5 37.5 3.75 28.125 3.75H16.875C7.5 3.75 3.75 7.5 3.75 16.875V28.125C3.75 37.5 7.5 41.25 16.875 41.25Z"
            stroke="#288F43"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M16.875 28.8381H26.1C29.2875 28.8381 31.875 26.2506 31.875 23.0631C31.875 19.8756 29.2875 17.2881 26.1 17.2881H13.4062"
            stroke="#288F43"
            stroke-width="2.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M16.0687 20.1943L13.125 17.2318L16.0687 14.2881"
            stroke="#288F43"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
      </svg>
   ),
   uploadIcon: (
      <svg
         width="35"
         height="35"
         viewBox="0 0 60 60"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M42.5 22.5049C47.9375 22.5352 50.8822 22.7763 52.8032 24.6973C55 26.894 55 30.4295 55 37.5005V40.0005C55 47.0715 55 50.6073 52.8032 52.8038C50.6065 55.0005 47.071 55.0005 40 55.0005H20C12.9289 55.0005 9.3934 55.0005 7.1967 52.8038C5 50.6073 5 47.0715 5 40.0005V37.5005C5 30.4295 5 26.894 7.1967 24.6972C9.11765 22.7763 12.0624 22.5352 17.5 22.5049"
            stroke="#288F43"
            stroke-width="3.75"
            stroke-linecap="round"
         />
         <path
            d="M30 37.5V5M30 5L37.5 13.75M30 5L22.5 13.75"
            stroke="#288F43"
            stroke-width="3.75"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
      </svg>
   ),
   blueopenEye: (
      <svg
         width="32"
         height="20"
         viewBox="0 0 32 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M13.1256 9C13.1256 10.2427 11.9504 11.25 10.5006 11.25C9.05088 11.25 7.87561 10.2427 7.87561 9C7.87561 7.75732 9.05088 6.75 10.5006 6.75C11.9504 6.75 13.1256 7.75732 13.1256 9Z"
            fill="#007AFF"
            stroke="#007AFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M10.5011 3.75C6.5831 3.75 3.26661 5.95716 2.15161 9C3.26659 12.0428 6.5831 14.25 10.5011 14.25C14.419 14.25 17.7355 12.0428 18.8505 9C17.7355 5.95718 14.419 3.75 10.5011 3.75Z"
            stroke="#007AFF"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   ),

   closeEye: (
      <svg
         width="32"
         height="20"
         viewBox="0 0 32 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M1.54664 1.6377L19.7012 17.3877M8.4497 7.68713C7.92257 8.1596 7.59817 8.80325 7.59817 9.5127C7.59817 10.9625 8.95289 12.1377 10.6239 12.1377C11.4535 12.1377 12.2051 11.8481 12.7516 11.3791M5.0767 4.82895C3.16109 5.92549 1.702 7.57364 1 9.5127C2.28519 13.0627 6.10805 15.6377 10.6241 15.6377C12.6301 15.6377 14.4993 15.1297 16.0701 14.2538M9.61533 3.43091C9.94715 3.40233 10.2837 3.3877 10.6241 3.3877C15.1403 3.3877 18.9631 5.96274 20.2483 9.5127C19.9652 10.2949 19.5587 11.0298 19.0489 11.7002"
            stroke="#76B95F"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   ),
   bluecloseEye: (
      <svg
         width="32"
         height="20"
         viewBox="0 0 32 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M1.54664 1.6377L19.7012 17.3877M8.4497 7.68713C7.92257 8.1596 7.59817 8.80325 7.59817 9.5127C7.59817 10.9625 8.95289 12.1377 10.6239 12.1377C11.4535 12.1377 12.2051 11.8481 12.7516 11.3791M5.0767 4.82895C3.16109 5.92549 1.702 7.57364 1 9.5127C2.28519 13.0627 6.10805 15.6377 10.6241 15.6377C12.6301 15.6377 14.4993 15.1297 16.0701 14.2538M9.61533 3.43091C9.94715 3.40233 10.2837 3.3877 10.6241 3.3877C15.1403 3.3877 18.9631 5.96274 20.2483 9.5127C19.9652 10.2949 19.5587 11.0298 19.0489 11.7002"
            stroke="#007AFF"
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   ),
   filtersIcon: (
      <svg
         width="29"
         height="25"
         viewBox="0 0 29 25"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M28 3.875H25M28 12.5H20.5M28 21.125H20.5M7 24V14.7442C7 14.4452 7 14.2957 6.96937 14.1527C6.9422 14.0259 6.89727 13.9031 6.83578 13.7877C6.76647 13.6578 6.66901 13.541 6.47409 13.3074L1.52592 7.38C1.33099 7.14651 1.23353 7.02975 1.16422 6.89974C1.10273 6.7844 1.0578 6.66162 1.03063 6.53475C1 6.39175 1 6.24223 1 5.9432V3.3C1 2.49493 1 2.09239 1.16348 1.78489C1.3073 1.51441 1.53678 1.2945 1.81902 1.15667C2.13988 1 2.55992 1 3.4 1H16.6C17.4401 1 17.8602 1 18.181 1.15667C18.4633 1.2945 18.6926 1.51441 18.8365 1.78489C19 2.09239 19 2.49493 19 3.3V5.9432C19 6.24223 19 6.39175 18.9694 6.53475C18.9422 6.66162 18.8973 6.7844 18.8358 6.89974C18.7665 7.02975 18.6689 7.14651 18.4741 7.38L13.5259 13.3074C13.3311 13.541 13.2335 13.6578 13.1642 13.7877C13.1027 13.9031 13.0578 14.0259 13.0306 14.1527C13 14.2957 13 14.4452 13 14.7442V19.6875L7 24Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   ),
   backIcon: (
      <svg
         width="45"
         height="45"
         viewBox="0 0 45 45"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M16.875 41.25H28.125C37.5 41.25 41.25 37.5 41.25 28.125V16.875C41.25 7.5 37.5 3.75 28.125 3.75H16.875C7.5 3.75 3.75 7.5 3.75 16.875V28.125C3.75 37.5 7.5 41.25 16.875 41.25Z"
            stroke="#288F43"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M16.875 28.8381H26.1C29.2875 28.8381 31.875 26.2506 31.875 23.0631C31.875 19.8756 29.2875 17.2881 26.1 17.2881H13.4062"
            stroke="#288F43"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M16.0687 20.1943L13.125 17.2318L16.0687 14.2881"
            stroke="#288F43"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   ),
   attachmentIcon: (
      <svg
         width="18"
         height="18"
         viewBox="0 0 22 22"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.7185 7.64831L10.0407 18.2076C8.27148 19.9573 5.40273 19.9573 3.63348 18.2076C1.86423 16.4586 1.86423 13.6221 3.63348 11.8723L13.244 2.36832C14.423 1.20207 16.3355 1.20207 17.5152 2.36832C18.6942 3.53457 18.6942 5.42606 17.5152 6.59156L7.90472 16.0956C7.31522 16.6791 6.35897 16.6791 5.76872 16.0956C5.17922 15.5128 5.17922 14.5671 5.76872 13.9836L14.312 5.53632L13.244 4.48031L4.70147 12.9283C3.52172 14.0938 3.52172 15.9853 4.70147 17.1523C5.88047 18.3178 7.79298 18.3178 8.97273 17.1516L18.5825 7.64831C20.3517 5.89931 20.3525 3.06131 18.5832 1.31231C16.8132 -0.437437 13.9452 -0.437437 12.176 1.31231L2.03147 11.3436L2.06899 11.3803C0.225486 13.7233 0.388226 17.1103 2.56548 19.2636C4.74348 21.4168 8.16797 21.5781 10.5372 19.7548L10.574 19.7916L21.7865 8.70432L20.7185 7.64831Z"
            fill="#007AFF"
         />
      </svg>
   ),
   upArrowIcon: (
      <svg
         width="10"
         height="7"
         viewBox="0 0 10 7"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M1.28228 6.59912L8.71748 6.59912C9.61868 6.59912 10.1239 5.69432 9.56708 5.07752L5.84948 0.959122C5.41748 0.480322 4.58348 0.480322 4.15028 0.959122L0.432679 5.07872C-0.124121 5.69432 0.38108 6.59912 1.28228 6.59912Z"
            fill="#76B95F"
         />
      </svg>
   ),
   downArrowIcon: (
      <svg
         width="10"
         height="7"
         viewBox="0 0 10 6"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M8.71772 -0.000488281H1.28252C0.381321 -0.000488281 -0.12388 0.904311 0.43292 1.52111L4.15052 5.63951C4.58252 6.11831 5.41652 6.11831 5.84972 5.63951L9.56732 1.51991C10.1241 0.904312 9.61892 -0.000488281 8.71772 -0.000488281Z"
            fill="#76B95F"
         />
      </svg>
   ),
   clockIcon: (
      <svg
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M12 8V12L14.5 14.5"
            stroke="#007AFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M4.33785 6.87061L3.58786 6.87438C3.58992 7.28564 3.92281 7.61853 4.33408 7.6206L4.33785 6.87061ZM6.87963 7.63339C7.29384 7.63547 7.63131 7.30138 7.63339 6.88717C7.63547 6.47296 7.30138 6.13549 6.88717 6.13341L6.87963 7.63339ZM5.07505 4.32129C5.07296 3.90708 4.7355 3.57298 4.32129 3.57506C3.90708 3.57715 3.57298 3.91462 3.57507 4.32882L5.07505 4.32129ZM3.75 12C3.75 11.5858 3.41421 11.25 3 11.25C2.58579 11.25 2.25 11.5858 2.25 12H3.75ZM16.8755 20.4452C17.2341 20.2378 17.3566 19.779 17.1492 19.4204C16.9418 19.0619 16.483 18.9393 16.1245 19.1468L16.8755 20.4452ZM19.1468 16.1245C18.9393 16.483 19.0619 16.9418 19.4204 17.1492C19.779 17.3566 20.2378 17.2341 20.4452 16.8755L19.1468 16.1245ZM5.14033 5.07126C4.84598 5.36269 4.84361 5.83756 5.13505 6.13191C5.42648 6.42626 5.90134 6.42862 6.19569 6.13719L5.14033 5.07126ZM18.8623 5.13786C15.0421 1.31766 8.86882 1.27898 5.0739 5.0739L6.13456 6.13456C9.33366 2.93545 14.5572 2.95404 17.8017 6.19852L18.8623 5.13786ZM5.0739 5.0739L3.80752 6.34028L4.86818 7.40094L6.13456 6.13456L5.0739 5.0739ZM4.33408 7.6206L6.87963 7.63339L6.88717 6.13341L4.34162 6.12062L4.33408 7.6206ZM5.08784 6.86684L5.07505 4.32129L3.57507 4.32882L3.58786 6.87438L5.08784 6.86684ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM16.1245 19.1468C14.9118 19.8483 13.5039 20.25 12 20.25V21.75C13.7747 21.75 15.4407 21.2752 16.8755 20.4452L16.1245 19.1468ZM20.25 12C20.25 13.5039 19.8483 14.9118 19.1468 16.1245L20.4452 16.8755C21.2752 15.4407 21.75 13.7747 21.75 12H20.25ZM6.19569 6.13719C7.68707 4.66059 9.73646 3.75 12 3.75V2.25C9.32542 2.25 6.90113 3.32791 5.14033 5.07126L6.19569 6.13719Z"
            fill="#007AFF"
         />
      </svg>
   ),
   eyeIcon: (
      <svg
         width="20"
         height="16"
         viewBox="0 0 22 17"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M14.0644 8.1875C14.0644 9.91344 12.6654 11.3125 10.9394 11.3125C9.21359 11.3125 7.81445 9.91344 7.81445 8.1875C7.81445 6.46156 9.21359 5.0625 10.9394 5.0625C12.6654 5.0625 14.0644 6.46156 14.0644 8.1875Z"
            stroke="#288F43"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
         <path
            d="M10.9398 1C6.27558 1 2.32738 4.0655 1 8.29167C2.32735 12.5178 6.27558 15.5833 10.9398 15.5833C15.604 15.5833 19.5522 12.5178 20.8796 8.29167C19.5522 4.06553 15.604 1 10.9398 1Z"
            stroke="#288F43"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
      </svg>
   ),
   doubleRight: (
      <svg
         width="40"
         height="40"
         viewBox="0 0 40 40"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M8.04852 19.1519C7.5799 18.6833 6.82009 18.6833 6.35147 19.1519C5.88284 19.6205 5.88284 20.3802 6.35147 20.8489L8.04852 19.1519ZM13.6 26.4004L12.7515 27.2489C13.2201 27.7175 13.9799 27.7175 14.4485 27.2489L13.6 26.4004ZM27.2485 14.4489C27.7171 13.9803 27.7171 13.2205 27.2485 12.7519C26.7798 12.2832 26.0202 12.2832 25.5515 12.7519L27.2485 14.4489ZM14.4485 19.1519C13.9799 18.6833 13.2201 18.6833 12.7515 19.1519C12.2828 19.6205 12.2828 20.3802 12.7515 20.8489L14.4485 19.1519ZM20 26.4004L19.1515 27.2489C19.6202 27.7175 20.3798 27.7175 20.8485 27.2489L20 26.4004ZM33.6485 14.4489C34.1171 13.9803 34.1171 13.2205 33.6485 12.7519C33.1798 12.2832 32.4202 12.2832 31.9515 12.7519L33.6485 14.4489ZM6.35147 20.8489L12.7515 27.2489L14.4485 25.5519L8.04852 19.1519L6.35147 20.8489ZM14.4485 27.2489L27.2485 14.4489L25.5515 12.7519L12.7515 25.5519L14.4485 27.2489ZM12.7515 20.8489L19.1515 27.2489L20.8485 25.5519L14.4485 19.1519L12.7515 20.8489ZM20.8485 27.2489L33.6485 14.4489L31.9515 12.7519L19.1515 25.5519L20.8485 27.2489Z"
            fill="black"
         />
      </svg>
   ),
   redDeleteIcon: (
      <svg
         width="50"
         height="50"
         viewBox="0 0 50 50"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M20.8335 22.917V35.417"
            stroke="#EC0F0F"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M29.1665 22.917V35.417"
            stroke="#EC0F0F"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M8.3335 14.583H41.6668"
            stroke="#EC0F0F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M12.5 14.583H25H37.5V37.4997C37.5 40.9515 34.7019 43.7497 31.25 43.7497H18.75C15.2982 43.7497 12.5 40.9515 12.5 37.4997V14.583Z"
            stroke="#EC0F0F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M18.75 10.4167C18.75 8.11548 20.6155 6.25 22.9167 6.25H27.0833C29.3846 6.25 31.25 8.11548 31.25 10.4167V14.5833H18.75V10.4167Z"
            stroke="#EC0F0F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   ),
   mobileIcon: (
      <svg
         width="32"
         height="24"
         viewBox="0 0 32 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M11 18H13M9.2 21H14.8C15.9201 21 16.4802 21 16.908 20.782C17.2843 20.5903 17.5903 20.2843 17.782 19.908C18 19.4802 18 18.9201 18 17.8V6.2C18 5.0799 18 4.51984 17.782 4.09202C17.5903 3.71569 17.2843 3.40973 16.908 3.21799C16.4802 3 15.9201 3 14.8 3H9.2C8.0799 3 7.51984 3 7.09202 3.21799C6.71569 3.40973 6.40973 3.71569 6.21799 4.09202C6 4.51984 6 5.07989 6 6.2V17.8C6 18.9201 6 19.4802 6.21799 19.908C6.40973 20.2843 6.71569 20.5903 7.09202 20.782C7.51984 21 8.07989 21 9.2 21Z"
            stroke="#76B95F"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
      </svg>
   ),
   truck: (
      <svg
         width="19"
         height="19"
         viewBox="0 0 19 19"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M13.9794 11.2614V8.15491C13.9794 7.72621 14.3266 7.37829 14.7561 7.37829L17.9542 7.37829L17.0044 5.47788C16.8723 5.2146 16.6037 5.04842 16.3093 5.04842L11.6496 5.04842C11.2201 5.04842 10.873 5.39634 10.873 5.82503V10.4848H9.63656L10.7463 4.27177H10.8729H11.6495C12.079 4.27177 12.4261 3.92385 12.4261 3.49516C12.4261 3.06646 12.079 2.71855 11.6495 2.71855H11.2884L9.9658 0.734258C9.82135 0.518344 9.57904 0.388672 9.31966 0.388672L6.98979 0.388672C6.7832 0.388672 6.58596 0.470217 6.4407 0.616235L5.75266 1.30351L4.65995 1.85027L3.45384 1.24683C3.24339 1.14199 2.99562 1.13809 2.78207 1.23594C2.56849 1.33456 2.41086 1.52485 2.35338 1.75318L1.72198 4.27952C1.43308 4.29973 1.17679 4.47367 1.06186 4.74241L0.0631115 7.07228C0.00253475 7.21364 -0.0153397 7.36894 0.0134196 7.52038L0.571825 10.5259C0.244878 10.6175 0.000241286 10.9057 0.000241286 11.2614L0.000241286 15.1445C0.000241286 15.5732 0.347392 15.9211 0.776854 15.9211H1.55347C1.55347 17.2057 2.59881 18.251 3.88334 18.251C5.16786 18.251 6.21321 17.2057 6.21321 15.9211H12.4262C12.4262 17.2057 13.4716 18.251 14.7561 18.251C16.0406 18.251 17.086 17.2057 17.086 15.9211H17.8626C18.292 15.9211 18.6392 15.5732 18.6392 15.1445V12.038H14.7561C14.3266 12.038 13.9794 11.6901 13.9794 11.2614ZM3.8833 16.6978C3.45461 16.6978 3.10669 16.349 3.10669 15.9211C3.10669 15.7503 3.17349 15.5996 3.26745 15.4715C3.56491 15.0746 4.20173 15.0746 4.49919 15.4715C4.59315 15.5996 4.65995 15.7503 4.65995 15.9211C4.65995 16.3491 4.312 16.6978 3.8833 16.6978ZM8.05924 10.4848H2.14369L1.58295 7.46759L2.28734 5.82503H2.33004H6.98979C7.19638 5.82503 7.39362 5.74349 7.53888 5.59747L8.86457 4.27177H9.16902L8.05924 10.4848ZM15.3719 15.4715C15.4659 15.5996 15.5327 15.7503 15.5327 15.9211C15.5327 16.3491 15.1848 16.6978 14.7561 16.6978C14.3274 16.6978 13.9794 16.349 13.9794 15.9211C13.9794 15.7503 14.0462 15.5996 14.1402 15.4715C14.4376 15.0746 15.0745 15.0746 15.3719 15.4715Z"
            fill="#76B95F"
         />
         <path
            d="M18.6397 8.93164H15.5332V10.4849H18.6397V8.93164Z"
            fill="#76B95F"
         />
      </svg>
   ),
};
