// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputWithIcon .Icon {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translate(0, -50%);
}
.InputWithIcon input{
    padding-right: 40px;
    border: 2px solid var(--Lime_Green) !important;
    border-radius: 6px !important;
}`, "",{"version":3,"sources":["webpack://./src/commanComponents/inputs/InputWithIcon.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,6BAA6B;AACjC;AACA;IACI,mBAAmB;IACnB,8CAA8C;IAC9C,6BAA6B;AACjC","sourcesContent":[".InputWithIcon .Icon {\n    position: absolute;\n    right: 4px;\n    top: 50%;\n    transform: translate(0, -50%);\n}\n.InputWithIcon input{\n    padding-right: 40px;\n    border: 2px solid var(--Lime_Green) !important;\n    border-radius: 6px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
