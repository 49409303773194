// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload-container {
   border: 1px solid #8a92a6;
   padding: 10px;
   text-align: center;
   cursor: pointer;
   border-radius: 8px;
   background-color: #eefff0;
   height: 100%;
}

.file-input {
   display: none;
}

.file-input-label {
   display: inline-block;
   width: 100%;
   height: 100%;
   cursor: pointer;
}

.file-input-label > p {
   font-weight: 700;
   color: var(--Text_Color);
   margin: 0px;
   overflow-x: hidden;
}

.disabled-file-upload-container {
   opacity: 0.5;
   pointer-events: none;
}

.file-upload-container:hover {
   background-color: #e3ffe6;
}

.fileUploadLable {
   color: #8a92a6;
   margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/commanComponents/fileUpload/FileUpload.css"],"names":[],"mappings":"AAAA;GACG,yBAAyB;GACzB,aAAa;GACb,kBAAkB;GAClB,eAAe;GACf,kBAAkB;GAClB,yBAAyB;GACzB,YAAY;AACf;;AAEA;GACG,aAAa;AAChB;;AAEA;GACG,qBAAqB;GACrB,WAAW;GACX,YAAY;GACZ,eAAe;AAClB;;AAEA;GACG,gBAAgB;GAChB,wBAAwB;GACxB,WAAW;GACX,kBAAkB;AACrB;;AAEA;GACG,YAAY;GACZ,oBAAoB;AACvB;;AAEA;GACG,yBAAyB;AAC5B;;AAEA;GACG,cAAc;GACd,kBAAkB;AACrB","sourcesContent":[".file-upload-container {\n   border: 1px solid #8a92a6;\n   padding: 10px;\n   text-align: center;\n   cursor: pointer;\n   border-radius: 8px;\n   background-color: #eefff0;\n   height: 100%;\n}\n\n.file-input {\n   display: none;\n}\n\n.file-input-label {\n   display: inline-block;\n   width: 100%;\n   height: 100%;\n   cursor: pointer;\n}\n\n.file-input-label > p {\n   font-weight: 700;\n   color: var(--Text_Color);\n   margin: 0px;\n   overflow-x: hidden;\n}\n\n.disabled-file-upload-container {\n   opacity: 0.5;\n   pointer-events: none;\n}\n\n.file-upload-container:hover {\n   background-color: #e3ffe6;\n}\n\n.fileUploadLable {\n   color: #8a92a6;\n   margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
