import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import GrievanceList from "./GrievanceList";
import { Heading_6 } from "../../commanComponents/NavigationBar/Typography";
import { commonApi } from "../../constants/apis/adminCommunication";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import useDebounce from "../../constants/useDebounce";
import Loader from "../../commanComponents/Loader";
import { userRole } from "../../constants/Helper/getUserRole";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { toast } from "react-toastify";

const GrievanceMain = () => {
   const router = useNavigate();
   const [status, setStatus] = useState("new");
   const [page, setPage] = useState(1);
   const [complaints, setComplaints] = useState([]);
   const [totalPages, setTotalPages] = useState(1);
   const [searchString, setSearchString] = useState("");
   const [filterUserType, setFilterUserType] = useState(
      userRole() === "zonal" ? "contractor" : ""
   );
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);

   const fetchComplaints = async () => {
      setLoading(true);
      try {
         const response = await commonApi.getComplaintList(
            page,
            debouncedSearchTerm,
            status,
            filterUserType
         );
         if (response?.status === "SUCCESS") {
            setComplaints(response.complaint);
            setTotalPages(response.totalPages);
            setError(null); // Reset error if data fetch is successful
         } else {
            setComplaints([]); // Clear complaints on failure
            setTotalPages(1); // Reset totalPages on failure
            setError("Failed to fetch complaints");
         }
      } catch (error) {
         console.error("Error fetching complaints:", error);
         setComplaints([]); // Clear complaints on error
         setTotalPages(1); // Reset totalPages on error
         setError("Error fetching complaints");
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchComplaints();
   }, [page, debouncedSearchTerm, status, filterUserType]);

   useEffect(()=>{
      if (userRole() !=='zonal' && !checkUserTabAccess("Grievance")) {
         toast.info('You do not have permission to access Grievance')
         router(-1); // redirect to previous page if user dont not have Tab Access
        return;
     }
     },[])

   return (
      <div className="w-100 mx-auto GarbageInfoMain">
         {loading && <Loader />}

         <div className="search_btn_wrapper">
            <div className="w-200">
               <Searchbox
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search Grievance..."
               />
            </div>
            {userRole() === "zonal" && (
               <div className="grievanceUserTypeFilters">
                  <button
                     className={
                        filterUserType == "contractor" &&
                        "SelectedUserTypeFilterBtn"
                     }
                     onClick={() => {
                        setFilterUserType("contractor");
                     }}
                  >
                     Contractor
                  </button>
                  <button
                     className={
                        filterUserType == "citizen" &&
                        "SelectedUserTypeFilterBtn"
                     }
                     onClick={() => {
                        setFilterUserType("citizen");
                     }}
                  >
                     Citizen
                  </button>
                  <button
                     className={
                        filterUserType == "specificpickup" &&
                        "SelectedUserTypeFilterBtn"
                     }
                     onClick={() => {
                        setFilterUserType("specificpickup");
                     }}
                  >
                     Specific Pick Up
                  </button>
               </div>
            )}
         </div>
         <GrievanceList data={complaints} />
         <div className="py-2">
            <PaginationButton
               page={page}
               setPage={setPage}
               totalPages={totalPages || 1}
            />
         </div>
      </div>
   );
};

export default GrievanceMain;
