// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BigTableLayout .tablemain{
    border-radius: 10px !important;
    height: 60vh !important;
}`, "",{"version":3,"sources":["webpack://./src/commanComponents/CommonLayout/CommonLayout.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,uBAAuB;AAC3B","sourcesContent":[".BigTableLayout .tablemain{\n    border-radius: 10px !important;\n    height: 60vh !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
