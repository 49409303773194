import { getServerUrl } from "../apis/adminCommunication";

export const ImageBaseUrl = `${getServerUrl()}/getFiles/`


export const getSubString = (text, subStringEnd) => {
  return text.length > subStringEnd
    ? `${text.substring(0, subStringEnd)}...`
    : text;
};

export const formatDate = (dateString) => {
  const date = dateString && new Date(dateString);
  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return formatter.format(date);
};


// -------------- Validations ----------------//
export const validateAadhaar = (value) => {
  // Check if value is a 12-digit number
  const aadhaarRegex = /^\d{12}$/;
  return aadhaarRegex.test(value) || "Aadhaar number must be a 12-digit number.";
};

export function convertToDateInputFormat(dateString) {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function formatToAmPm(dateTimeString) {
  const date = new Date(dateTimeString);

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  
  return formattedTime;
}

export const getPageLimit = process.env.REACT_APP_DATA_LIMIT || 20

export const PaginationSrNo = (page, index) => {  
  return (page - 1) * getPageLimit + (index + 1);
};
