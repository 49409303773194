import React, { memo, useState, useCallback, useEffect } from "react";
import {
   GoogleMap,
   useJsApiLoader,
   Marker,
   DirectionsService,
   DirectionsRenderer,
} from "@react-google-maps/api";
import Spinner from "../../commanComponents/Spinner";

const containerStyle = {
   width: "100%",
   height: "85vh", // Ensure height is defined
};

const center = {
   lat: 21.1310033,
   lng: 79.0532054,
};

const Track = memo(({ locations }) => {
   const [map, setMap] = useState(null);
   const [isWayLoaded, setisWayLoaded] = useState(false);
   const [directionsResponses, setDirectionsResponses] = useState([]);

   // Load the Google Maps API
   const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
   });

   const onLoad = useCallback((map) => {
      setMap(map);
   }, []);

   const onUnmount = useCallback(() => {
      setMap(null);
   }, []);

   const handleDirectionsServiceCallback = (response, index) => {
      if (!isWayLoaded) {
        if (response !== null && response.status === "OK") {
          setisWayLoaded(true);
          setDirectionsResponses((prevResponses) => {
            const newResponses = [...prevResponses];
            newResponses[index] = response;
            return newResponses;
          });
        }
      }
    };

    useEffect(() => {
      setisWayLoaded(false);
      setDirectionsResponses([]); // Reset state to allow re-render
    }, [locations]);

   if (!isLoaded) return <div> <Spinner /></div>; // Provide a loading indicator

   const customMarkerIcon = {
    url: require("./../../assets/icons/marker_map.png"), // Replace with your icon URL
    fillColor: "#EB00FF",
    scale: 0.5,
  };

   return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={100}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* User Marker */}
        {/* <UserMarker latitude={userLatitude} longitude={userLongitude} /> */}

        {/* Render the routes from origin to destination */}
        {locations?.length > 0 &&
          locations?.map((location, index) => {
            const waypoints = location?.slice(1, -1).map((loc) => ({
              location: loc,
              stopover: true,
            }));
            return (
              <React.Fragment key={index}>
                <DirectionsService
                  options={{
                    origin: location[0],
                    destination: location[location.length - 1],
                    waypoints: waypoints,
                    travelMode: "WALKING",
                  }}
                  callback={(response) =>
                    handleDirectionsServiceCallback(response, index)
                  }
                />
                {directionsResponses[index] && (
                  <DirectionsRenderer
                    options={{
                      directions: directionsResponses[index],
                      suppressMarkers: true, // Suppress default A, B, C markers
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
      </GoogleMap>
    ) : (
      <></>
    );
});

export default Track;
