import { useForm } from "react-hook-form";
import { RoundedBtn } from "../buttons/SubmitBtns";
import { InputWithLabel } from "../inputs/InputWithLabel";
import TextArea from "../inputs/TextArea";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import "./Forms.css";
import { commonApi } from "../../constants/apis/adminCommunication";
import { useEffect } from "react";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

const AddZoneForm = ({ zone, onClose }) => {
   const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      reset,
   } = useForm();

   useEffect(() => {
      setValue("name", zone?.name || "");
      setValue("zoneNo", zone?.zoneNo || "");
      setValue("address", zone?.address || "");
   }, [zone]);

   const queryClient = useQueryClient();

   // Mutation for adding or updating a zone
   const mutation = useMutation(
      async (data) => {
         if (zone) {
            // Update existing zone
            return commonApi.updateZone(
               zone._id,
               data.name,
               data.zoneNo,
               data.address
            );
         } else {
            // Add new zone
            return commonApi.addZone(data.name, data.zoneNo, data.address);
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === "SUCCESS") {
               toast.success(
                  `Zone ${zone ? "updated" : "created"} successfully!`
               );
               queryClient.invalidateQueries("zones"); // Invalidate the zones query to refresh data
               onClose(); // Close the popup
               reset(); // Reset the form fields
            } else {
               toast.error(
                  data.message ||
                     `Error ${zone ? "updating" : "creating"} zone!`
               );
            }
         },
         onError: (error) => {
            toast.error(
               `Error ${zone ? "updating" : "creating"} zone: ${error.message}`
            );
         },
      }
   );

   const onSubmit = (data) => {
      mutation.mutate(data);
   };

   return (
      <form>
         <div className="form-row" style={{textAlign:'start'}}>
            <div className="col-md-6" >
               <InputWithLabel
                  labelText={"Zone Name*"}
                  label={true}
                  placeholder="Enter Zone Name*"
                  register={{
                     ...register("name", { required: "Zone Name is required" }),
                  }}
                  errorMessage={errors.name}
               />
            </div>
            <div className="col-md-6">
               <InputWithLabel
                  placeholder="Enter Zone Number"
                  labelText={"Zone Number*"}
                  label={true}
                  register={{
                     ...register("zoneNo", {
                        required: "Zone Number is required",
                     }),
                  }}
                  errorMessage={errors.zoneNo}
               />
            </div>
            <div className="col-md-12 my-2">
               <TextArea
                  placeholder="Enter Address*"
                  isLabel={true}
                  label={'Address*'}
                  rows={3}
                  register={{
                     ...register("address", {
                        required: "Address is required",
                     }),
                  }}
                  errorMessage={errors.address}
               />
            </div>
         </div>
         <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper">
            {checkUserTabAccess("Zone_Ward")?.access === "write" && (
               <RoundedBtn
                  text={zone ? "Update" : "Create"}
                  className="bg_primary text-white px-4"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={mutation.isLoading}
               />
            )}
            <RoundedBtn
               text="Close"
               className="bg-secondary text-white px-4 ml-2"
               onClick={(e) => {
                  e.preventDefault();
                  onClose();
               }}
               disabled={mutation.isLoading}
            />
         </div>
      </form>
   );
};

export default AddZoneForm;
