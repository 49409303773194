// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GarbageCollectionList .garbage_type{
position: absolute;
top: -10px;
}
.GarbageCollectionList .garbage_type span{
    border: 2px solid #fff;
}

.GarbageCollectionList table thead tr:nth-child(2) th{
    border: none !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/GarbageCollection/GarbageCollectionList.css"],"names":[],"mappings":"AAAA;AACA,kBAAkB;AAClB,UAAU;AACV;AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".GarbageCollectionList .garbage_type{\nposition: absolute;\ntop: -10px;\n}\n.GarbageCollectionList .garbage_type span{\n    border: 2px solid #fff;\n}\n\n.GarbageCollectionList table thead tr:nth-child(2) th{\n    border: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
