import GrievanceMain from "../components/Grievance/GrievanceMain"


const Grievance = () => {
  return (
    <>
    <GrievanceMain />
    </>
  )
}

export default Grievance