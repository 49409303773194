// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
    position: relative;
    width: 100%; 
    height: 100%;
  }
  
  .customLoader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 99;
  }
    
  .customLoader img {
    width: 150px; 
    height: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/commanComponents/Loader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,MAAM;IACN,OAAO;IACP,0CAA0C;IAC1C,WAAW;EACb;;EAEA;IACE,YAAY;IACZ,YAAY;EACd","sourcesContent":[".loader-container {\n    position: relative;\n    width: 100%; \n    height: 100%;\n  }\n  \n  .customLoader {\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    background-color: rgba(255, 255, 255, 0.5);\n    z-index: 99;\n  }\n    \n  .customLoader img {\n    width: 150px; \n    height: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
