// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* From Uiverse.io by Shaidend */
.InputContainer {
  height: 40px;
  display: flex;
  width: 100%;
  border: 1px solid rgb(158, 158, 158);
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  padding-left: 15px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
}

.searchbox .input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.9em;
  caret-color: rgb(255, 81, 0);

}

.searchbox .labelforsearch {
  cursor: text;
  padding: 0px 12px;
}

.searchbox .searchIcon {
  width: 13px;
}

.searchbox .border {
  width: 1.3px;
  background-color: rgb(223, 223, 223);
}

.searchbox .micIcon {
  width: 12px;
}

.searchbox .micButton {
  padding: 0px 15px 0px 12px;
  border: none;
  background-color: transparent;
  height: 40px;
  cursor: pointer;
  transition-duration: 0.3s;
}

.searchbox .searchIcon path {
  fill: rgb(114, 114, 114);
}

.searchbox .micIcon path {
  fill: rgb(255, 81, 0);
}

.searchbox .micButton:hover {
  background-color: rgb(255, 230, 230);
  transition-duration: 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/commanComponents/inputs/Searchbox.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;EACE,YAAY;EACZ,aAAa;EACb,WAAW;EACX,oCAAoC;EACpC,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;EACpC,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,6CAA6C;AAC/C;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,4BAA4B;;AAE9B;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,YAAY;EACZ,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B","sourcesContent":["/* From Uiverse.io by Shaidend */\n.InputContainer {\n  height: 40px;\n  display: flex;\n  width: 100%;\n  border: 1px solid rgb(158, 158, 158);\n  align-items: center;\n  justify-content: center;\n  background-color: rgb(255, 255, 255);\n  border-radius: 10px;\n  overflow: hidden;\n  cursor: pointer;\n  padding-left: 15px;\n  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);\n}\n\n.searchbox .input {\n  width: 100%;\n  height: 100%;\n  border: none;\n  outline: none;\n  font-size: 0.9em;\n  caret-color: rgb(255, 81, 0);\n\n}\n\n.searchbox .labelforsearch {\n  cursor: text;\n  padding: 0px 12px;\n}\n\n.searchbox .searchIcon {\n  width: 13px;\n}\n\n.searchbox .border {\n  width: 1.3px;\n  background-color: rgb(223, 223, 223);\n}\n\n.searchbox .micIcon {\n  width: 12px;\n}\n\n.searchbox .micButton {\n  padding: 0px 15px 0px 12px;\n  border: none;\n  background-color: transparent;\n  height: 40px;\n  cursor: pointer;\n  transition-duration: 0.3s;\n}\n\n.searchbox .searchIcon path {\n  fill: rgb(114, 114, 114);\n}\n\n.searchbox .micIcon path {\n  fill: rgb(255, 81, 0);\n}\n\n.searchbox .micButton:hover {\n  background-color: rgb(255, 230, 230);\n  transition-duration: 0.3s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
