// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainWrapper{
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
}

.ProfileImgWrapper{
    position: relative;
    width: 82px;
    height: 82px;
    border: 2px solid #c6e4cc;
    border-radius: 80px;
}

.ProfileImgWrapper > div{
    position: absolute;
    bottom: -12px;
    right: -4px;
    cursor: pointer;
    font-size: 28px;
    color: #288F43;
}

.ProfileImgWrapper img{
    width: 80px;
    height: 80px;
    border-radius: 80px;
    object-fit: contain;
}

.mainWrapper >:nth-child(1){
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mainWrapper >:nth-child(2){
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 30px;
}

.mainWrapper >:nth-child(3){
    margin: 20px 0px;
    max-width: 400px;
}


/* ---------------------- medea query ------------------------- */

@media (max-width: 1000px){
    .mainWrapper >:nth-child(2){
        grid-template-columns: repeat(2, 1fr)
    }
}`, "",{"version":3,"sources":["webpack://./src/components/profile/profile.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,eAAe;IACf,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;;AAGA,iEAAiE;;AAEjE;IACI;QACI;IACJ;AACJ","sourcesContent":[".mainWrapper{\n    background-color: #fff;\n    border-radius: 8px;\n    padding: 20px;\n}\n\n.ProfileImgWrapper{\n    position: relative;\n    width: 82px;\n    height: 82px;\n    border: 2px solid #c6e4cc;\n    border-radius: 80px;\n}\n\n.ProfileImgWrapper > div{\n    position: absolute;\n    bottom: -12px;\n    right: -4px;\n    cursor: pointer;\n    font-size: 28px;\n    color: #288F43;\n}\n\n.ProfileImgWrapper img{\n    width: 80px;\n    height: 80px;\n    border-radius: 80px;\n    object-fit: contain;\n}\n\n.mainWrapper >:nth-child(1){\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.mainWrapper >:nth-child(2){\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 20px;\n    margin-top: 30px;\n}\n\n.mainWrapper >:nth-child(3){\n    margin: 20px 0px;\n    max-width: 400px;\n}\n\n\n/* ---------------------- medea query ------------------------- */\n\n@media (max-width: 1000px){\n    .mainWrapper >:nth-child(2){\n        grid-template-columns: repeat(2, 1fr)\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
