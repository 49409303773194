import ForgotPasswordMain from "../components/Forgot Password/ForgotPasswordMain"

const ForgotPassword = () => {
  return (
    <>
     <ForgotPasswordMain /> 
    </>
  )
}

export default ForgotPassword