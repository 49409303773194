// utils/apiHelpers.js
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useTryCatch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const getActiveList = async (apiCall, successMessage = "", failureMessage = "", redirectOnError = false) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiCall();
      if (response?.status === "SUCCESS") {
        // if (successMessage) toast.success(successMessage);
        return response;
      } else if (response?.status === "JWT_INVALID") {
        toast.error(response?.message || "Invalid Jwt please login Again.");
        if (redirectOnError) navigate("/login");
      } else {
        toast.error(response?.message || failureMessage || "Something went wrong!");
      }
    } catch (error) {
      toast.error(error?.message || failureMessage || "Something went wrong!");
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { getActiveList, loading, error };
};
