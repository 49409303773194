import "./ComponentLayouts.css";
const TwoSectionLayout = ({ leftChildren, rightChildren }) => {
  return (
    <div className="w-100 TwoSectionLayout">
      <div className="row w-100 gap-4 mx-auto contentWrapper">
        <div className="col-4 pr-2 p-0 position-relative h-auto">
          <div className="common_shadow rounded p-3 h-100 bg-white">
            {leftChildren}
          </div>
        </div>
        <div className="col-8 p-0 h-auto">
            {rightChildren}
        </div>
      </div>
    </div>
  );
};

export default TwoSectionLayout;
