import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, {
  memo,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { commonApi } from "../../constants/apis/adminCommunication";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { addHouses } from "../../store/actions/liveTracking";
import GoogleMapReact from "google-map-react";

const SingleMapComponents = ({ zoneId, trackId }) => {
  const { house, location } = useSelector(
    (state) => ({
      house: state.liveTracking.house,
      location: state.liveTracking.location,
    }),
    shallowEqual // Use shallowEqual to avoid unnecessary re-renders if the selected state hasn't changed
  );
  const mapRef = useRef(null); // Reference to the Google Map instance
  const directionsRendererRef = useRef(null); // Reference to DirectionsRenderer instance
  const [mapLoaded, setMapLoaded] = useState(false); // Track when the map is loaded

  console.log("i am ren");
  const [trackData, setTracksData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllDynamoDBHouses();
    getATrack();
  }, [zoneId, trackId]);

  const getAllDynamoDBHouses = useCallback(async () => {
    const formData = {
      trackId,
      zoneId,
    };
    const responseData = await commonApi.liveTrackingGetAllDynamoHouses(
      formData
    );
    dispatch(addHouses(responseData?.house));
  }, [trackId, zoneId, dispatch]);

  const getATrack = useCallback(async () => {
    const formData = { trackId };
    const responseData = await commonApi.getATrack(formData);
    setTracksData(responseData?.trackCords || []);
  }, [trackId]);

  const center = useMemo(
    () => ({
      lat: 21.149539260461108,
      lng: 79.08211870155534,
    }),
    []
  );

  //   const { isLoaded } = useJsApiLoader({
  //     id: "google-map-script",
  //     googleMapsApiKey: "AIzaSyD2SSBFBufP4oQKR7iLc9HHUk0oX-KsoWY",
  //   });

  //   const customMarkerIcon = useMemo(
  //     () => ({
  //       url: require("./../../assets/icons/marker_map.png"),
  //       fillColor: "#EB00FF",
  //       scale: 0.5,
  //     }),
  //     []
  //   );

  //   const customOpenPlotIcon = useMemo(
  //     () => ({
  //       url: require("./../../assets/img/openPlot.png"),
  //       fillColor: "#EB00FF",
  //       scale: 0.5,
  //     }),
  //     []
  //   );

  // This function requests the route with waypoints and displays it on the map
  const displayRoute = (map, directionsService) => {
    const request = {
      origin: { lat: 37.7749, lng: -122.4194 }, // Starting point (San Francisco)
      destination: { lat: 37.8715, lng: -122.273 }, // Destination point (Berkeley)
      waypoints: [
        {
          location: { lat: 37.8044, lng: -122.2711 }, // Example waypoint (Oakland)
          stopover: true, // Indicates it's a stop along the route
        },
        {
          location: { lat: 37.8324, lng: -122.2409 }, // Another waypoint (Emeryville)
          stopover: true,
        },
      ], // Array of waypoints (middle points)
      travelMode: window.google.maps.TravelMode.DRIVING, // Mode of travel (can be DRIVING, WALKING, etc.)
    };

    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        // Display the route if request is successful
        directionsRendererRef.current.setDirections(result);
      } else {
        console.error("Error fetching directions:", status);
      }
    });
  };

  useEffect(() => {
    if (mapLoaded && window.google) {
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();

      directionsRenderer.setMap(mapRef.current); // Attach the directionsRenderer to the map
      directionsRendererRef.current = directionsRenderer; // Store the renderer for future reference

      displayRoute(mapRef.current, directionsService); // Call the function to display the route
    }
  }, [mapLoaded]);
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyD2SSBFBufP4oQKR7iLc9HHUk0oX-KsoWY" }} // Add your Google Maps API key here
      defaultCenter={center}
      defaultZoom={11}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map; // Store the map instance for future reference
        setMapLoaded(true); // Mark map as loaded
      }}
    />
  );
};

// Prevent re-renders unless zoneId or trackId change
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.trackId === nextProps.trackId &&
    prevProps.zoneId === nextProps.zoneId
  );
};

export default memo(SingleMapComponents, areEqual);

// {house?.map((loc) => (
//   <Marker
//     key={loc._id}
//     title={loc.ownerName}
//     position={{
//       lat: Number(loc.latitude),
//       lng: Number(loc.longitude),
//     }}
//     icon={
//       loc.houseType === "open plot"
//         ? customOpenPlotIcon
//         : customMarkerIcon
//     }
//   />
// ))}
