import React, { useState } from "react";
import BigTableLayout from "../../commanComponents/CommonLayout/BigTableLayout";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { isEven } from "../../constants/Helper/NumbersFunction";
import { useNavigate } from "react-router-dom";
import {
   formatDate,
   getSubString,
   PaginationSrNo,
} from "../../constants/Helper/Helpers";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import Attachment from "../../commanComponents/Attachment";

const GrievanceList = ({ data, page = 1 }) => {
   const route = useNavigate();
   const [attachmentFile, setAttachmentFile] = useState("");
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const handleClosePopup = () => setIsPopupOpen(false);
   return (
      <>
         <div className="tableWrapper common_shadow">
            <div
               className="table-responsive position-relative tablemain"
               style={{ overflowY: "auto", height: "58vh" }}
            >
               <table className="table position-relative w-100">
                  <thead
                     className="w-100 bg_Table_Header border-0"
                     style={{
                        position: "sticky",
                        top: "0",
                        right: "0",
                        zIndex: "1",
                     }}
                  >
                     <tr>
                        <th
                           scope="col"
                           className="col_100px"
                        >
                           Sr. No
                        </th>
                        <th scope="col" className="col_200px">
                           Name
                        </th>
                        <th scope="col" className="col_150px">
                           Complaint Id
                        </th>
                        <th scope="col" className="col_150px">
                           Complaint Date
                        </th>
                        <th scope="col" className="col_150px">
                           Subject
                        </th>
                        <th scope="col" className="col_150px text-center">
                           Status
                        </th>
                        <th scope="col" className="col_150px text-center">
                           Attachment
                        </th>
                        <th scope="col" className="col_100px text-center row-fix-to-last table-th-bg-color">
                           Action
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {data?.map((item, index) => (
                        <tr
                           key={item._id || index}
                           className={`${isEven(index + 1) && "Table_Data"}`}
                        >
                           <th scope="row">{PaginationSrNo(page, index)}</th>
                           <td
                              style={{ whiteSpace: "nowrap" }}
                              className={`text-capitalize `}
                           >
                              {item?.name || "--"}
                           </td>
                           <td style={{ whiteSpace: "nowrap" }}>
                              {item?.complaintId || "--"}
                           </td>
                           <td style={{ whiteSpace: "nowrap" }}>
                              {formatDate(item?.createdAt) || "--"}
                           </td>
                           <td
                              style={{ whiteSpace: "nowrap" }}
                              className={`text-capitalize `}
                           >
                              {getSubString(item?.subject || "--", 40)}
                           </td>
                           <td
                              style={{ whiteSpace: "nowrap" }}
                              className={`${
                                 item?.status == "resolved" ||
                                 item?.status == "completed"
                                    ? "text_primary"
                                    : item?.status === "inprogress"
                                    ? "text_Support_Orange"
                                    : "text_support_Blue"
                              } text-capitalize font-weight_600 text-center`}
                           >
                              {item?.status || "--"}
                           </td>
                           <td
                              className="text-center cursor_pointer"
                              onClick={() => {
                                 setAttachmentFile(
                                    item?.attachmentUrl[0] &&
                                       item?.attachmentUrl[0]?.fileUrl
                                 );
                                 setIsPopupOpen(true);
                              }}
                              title="Attachment"
                           >
                              {svgIcons?.attachmentIcon}
                           </td>
                           <td
                              style={{ whiteSpace: "nowrap" }}
                              className={`text-capitalize row-fix-to-last text-center`}
                           >
                              <span
                                 className="cursor_pointer"
                                 onClick={() =>
                                    route(
                                       `/grievance/view-grievance?grievanceId=${item?._id}`
                                    )
                                 }
                                 title="View"
                              >
                                 {svgIcons.eyeIcon}
                              </span>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
               {data?.length == 0 && (
                  <h6 className="DataNotFoundText">No Grievance Found...</h6>
               )}
            </div>
         </div>

         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            title="Attachment"
         >
            <Attachment onClose={handleClosePopup} fileUrl={attachmentFile} />
         </CenteredPopup>
      </>
   );
};

export default GrievanceList;
