import LoginMain from "../components/Login/LoginMain"

const Login = () => {
  return (
    <>
    <LoginMain />
    </>
  )
}

export default Login