import "./NotificationCard.css";
import Avtar from "../../assets/icons/User-60.svg";
import {
  convertToDateInputFormat,
  formatToAmPm,
  getSubString,
} from "../../constants/Helper/Helpers";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { useNavigate } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
const NotificationCard = ({ data, getNotifications }) => {
  const navigate = useNavigate();

  const deketeNotification = async (id) => {
    try {
      const res = await commonApi.deleteNotification(id);
      if (res?.status === "SUCCESS") {
        getNotifications();
        toast.success(res.message || "Notification Deleted!");
      } else if (res?.status === "JWT_INVALID") {
        navigate("/login");
        toast.error(res?.message || "Please Log in Again!");
      } else {
        toast.error(res?.message || "Something Went Wrong!");
      }
    } catch (error) {
      toast.error(error?.message || "Something Went Wrong!");
    }
  };
  return (
    <div className="NotificationCard w-100">
      <div className="row w-100 common_shadow rounded bg_Notification_Green p-3 align-items-center">
        <div className="px-2 ">
          <img src={Avtar} alt="" />
        </div>
        <div>
          <div className="d-flex flex-column justify-content-between">
            <p>{getSubString(data?.description, 100)}</p>

            <div className="d-flex text_color-lable mt-2" style={{ gap: "10px" }}>
              <span>{convertToDateInputFormat(data?.updatedAt)}</span>
              <span>{formatToAmPm(data?.updatedAt)}</span>
            </div>
          </div>
        </div>
        <div>
          {data?.isActive ? (
            <span className="smallTick bg_primary mx-auto"></span>
          ) : (
            <span
              className="cursor_pointer"
              onClick={() => deketeNotification(data?._id)}
              title="Delete"
            >
              {svgIcons.redDeleteIcon}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
