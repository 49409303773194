import React, { useState, useCallback, memo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  RoundedbuttonWithIcon,
  RoundedbuttonWithIncreaseWidth,
} from "../../commanComponents/buttons/NavigationButtons";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { Heading_6 } from "../../commanComponents/NavigationBar/Typography";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import "./DepartmentsList.css";
import { useInfiniteQuery } from "react-query";
import { commonApi } from "../../constants/apis/adminCommunication";
import useDebounce from "../../constants/useDebounce";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import { CreateDepartmentForm } from "../../commanComponents/forms/DeparmentForms";
import Spinner from "../../commanComponents/Spinner";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

const DepartmentsList = memo(() => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [listUpdated, setListUpdated] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [departmentId, setDepartmentId] = useState(null);
  const [departmentName, setDepartmentName] = useState('');

  const fetchDepartments = useCallback(
    async ({ pageParam = 1 }) => {
      const token = "your-auth-token";
      const response = await commonApi.getDepartmentList(
        pageParam,
        debouncedSearchTerm
      );
      return response;
    },
    [debouncedSearchTerm]
  );

  const { data, isFetching, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    ["DepartmentsList_departments", debouncedSearchTerm, listUpdated],
    fetchDepartments,
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.totalPages > pages.length ? pages.length + 1 : undefined,
    }
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setDepartmentId(null);
    setDepartmentName('');
  };
  const handleEdit = (id, name) => {
    setDepartmentId(id);
    setDepartmentName(name);
    setIsPopupOpen(true);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div>
        <Heading_6
          text="Departments"
          className="fw-bold text-capitalize mb-3"
        />
        <Searchbox onChange={handleSearchChange} />
      </div>
      <div className="departmentButtons pt-2">
        <div className="departmentNavButtons" id="scrollableDiv">
          {(data?.pages[0].status !== "FAILED" && data?.pages.length) > 0 ? (
            <InfiniteScroll
              dataLength={
                data?.pages.flatMap((page) => page.result).length || 0
              }
              next={fetchNextPage}
              hasMore={hasNextPage}
              scrollableTarget="scrollableDiv"
            >
              {data?.pages
                .flatMap((page) => page.result)
                .map((department) => (
                  <RoundedbuttonWithIncreaseWidth
                    key={department?._id}
                    handleEdit={() => handleEdit(department?._id, department?.name)}
                    text={department?.name}
                    departmentId={department?._id}
                    designationCount={department?.designationCount}
                    isActive={department?.isActive}
                    className="text-capitalize w-100"
                    icon={svgIcons.PlusIcon}
                    setListUpdated={setListUpdated}
                  />
                ))}
            </InfiniteScroll>
          ) : (
            (!isFetching && <h6 className="DataNotFoundText">No Departments Found...</h6>)
          )}
          {isFetching && <div className="m-3 text-center"><Spinner/></div>}
        </div>
        {checkUserTabAccess("Manage_Departments")?.access === "write" && 
        <div>
          <RoundedbuttonWithIcon
            onClick={handleOpenPopup}
            text="ADD DEPARTMENT"
            className="text-capitalize w-100"
            icon={svgIcons.PlusIcon}
          />
        </div>}
      </div>
      <CenteredPopup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        title={ departmentId ? "Update Department" : "Create Department"}
      >
        <CreateDepartmentForm
          departmentId={departmentId}
          setDepartmentName={setDepartmentName}
          departmentName={departmentName}
          onClose={handleClosePopup}
          onSuccess={refetch} // Call refetch only when the form is successfully submitted
        />
      </CenteredPopup>
    </div>
  );
});

export default DepartmentsList;
