import React, { useEffect, useState } from "react";
import CardWrapper from "./CardWrapper";
import DoubleBarGraph from "./DoubleBarGraph";
import CollectionChart from "./CollectionChart";
import { isEven } from "../../constants/Helper/NumbersFunction";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import DoughnutChart from "./DoughnutChart";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { userRole } from "../../constants/Helper/getUserRole";
import { formatToAmPm } from "../../constants/Helper/Helpers";
import Spinner from "../../commanComponents/Spinner";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { useNavigate } from "react-router-dom";

export default function DashboardMain() {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [todayCollectionData, setTodayCollectionData] = useState([]);

   const fetchTodaysCollection = async () => {
      try {
         setLoading(true);
         const serverResponse = await commonApi.getTodaysCollection();
         if (serverResponse?.status === "SUCCESS") {
            setTodayCollectionData(serverResponse?.data);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "FAILED") {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (userRole() === "zonal") {
         fetchTodaysCollection();
      }
   }, []);

   useEffect(()=>{
      if (userRole() !=='zonal' && !checkUserTabAccess("Dashboard")) {
         toast.info('You do not have permission to access Dashboard')
        navigate(-1); // redirect to previous page if user dont not have Tab Access
        return;
     }
     },[])

   return (
      <div className="dashboardMain">
         <CardWrapper />

         <DoubleBarGraph
            title={userRole() === "zonal" ? "Contractor" : "Zone"}
         />
         <CollectionChart />
         {userRole() === "zonal" && (
            <div className="tableWrapper big_Shadow mt-4">
               <h6 style={{ padding: "16px 0px 4px 16px" }}>Today</h6>
               <div
                  className="GarbageCollectionList table-responsive position-relative tablemain"
                  style={{ overflowY: "auto", height: "58vh" }}
               >
                  <table className="table w-100 overflow-auto">
                     <thead
                        className="w-100  border-0"
                        style={{
                           position: "sticky",
                           top: "0",
                           right: "0",
                           zIndex: "1",
                        }}
                     >
                        <tr className="bg_Table_Header">
                           <th className="col_100px">Sr. No</th>
                           <th className="col_150px">Ward</th>
                           <th className="col_200px">Contractor Name</th>
                           <th className="col_150px">Vehicle Number</th>
                           <th className="col_350px ">Garbage Type</th>
                           <th className="col_100px">Time</th>
                        </tr>
                        <tr className="py-4">
                           <th
                              scope="col"
                              className="text-capitalize py-3"
                              colSpan={4}
                           ></th>
                           <th
                              scope="col"
                              className="text-capitalize p-0 position-relative"
                           >
                              <div className="d-flex garbage_type m-auto">
                                 <span className="bg_Table_Header py-1 px-2 col_100px" style={{borderRadius:'5px 0px 0px 5px'}}>
                                    Dry
                                 </span>
                                 <span className="bg_Table_Header py-1 px-2 col_100px">
                                    Wet
                                 </span>
                                 <span className="bg_Table_Header py-1 px-2 col_150px" style={{borderRadius:'0px 5px 5px 0px'}}>
                                 Hazardous
                                 </span>
                              </div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        {todayCollectionData?.length > 0 &&
                           todayCollectionData?.map((item, i) => (
                              <tr
                                 key={item._id || i}
                                 className={`${isEven(i + 1) && "Table_Data"}`}
                              >
                                 <th scope="row">{i + 1}</th>
                                 <td>{item?.wardName || "--"}</td>
                                 <td>{item?.contractorName || "--"}</td>
                                 <td>{item?.vehicleNo || "--"}</td>
                                 <td >
                                    <div className="d-flex">
                                       <span className=" col_100px">
                                          {item?.totalDryWaste} Kg
                                       </span>
                                       <span className=" col_100px">
                                          {item?.totalWetWaste} Lr
                                       </span>
                                       <span className=" col_100px">
                                          {item?.totalHazardousWaste} Kg
                                       </span>
                                    </div>
                                 </td>
                                 <td>
                                    {" "}
                                    {item?.createdAt
                                       ? formatToAmPm(item?.createdAt)
                                       : "--"}
                                 </td>
                              </tr>
                           ))}
                     </tbody>
                  </table>
                  {(!todayCollectionData ||
                     todayCollectionData?.length == 0) && (
                     <h6 className="DataNotFoundText">
                        No Collection Found...
                     </h6>
                  )}
                  {loading && (
                     <div className="d-flex justify-content-center mt-5">
                        <Spinner />
                     </div>
                  )}
               </div>
            </div>
         )}
         <DoughnutChart />
      </div>
   );
}
