import { useNavigate, useSearchParams } from "react-router-dom";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import { InputWithLabel } from "../../commanComponents/inputs/InputWithLabel";
import TextArea from "../../commanComponents/inputs/TextArea";
import {
   commonApi,
   getServerUrl,
} from "../../constants/apis/adminCommunication";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { formatDate } from "../../constants/Helper/Helpers";
import "./ViewGrievanceMain.css";
import Select from "react-select";
import { userRole } from "../../constants/Helper/getUserRole";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import Loader from "../../commanComponents/Loader";

const ViewGrievanceMain = () => {
   const [searchParams] = useSearchParams();
   const route = useNavigate();
   const [selectedZone, setSelectedZone] = useState([]);
   const [loading, setLoading] = useState(false);
   const [attachementsFromBackend, setAttachmentsFromBackend] = useState([]);

   const grievanceId = searchParams.get("grievanceId");
   const [grievance, setGrievance] = useState(null);
   const [reply, setReply] = useState(""); // State to handle the reply

   const getGrievance = async () => {
      try {
         setLoading(true);
         const response = await commonApi.getGrivanceById(grievanceId);
         if (response?.status === "SUCCESS") {
            setGrievance(response?.complaint);
            setSelectedZone(() => {
               const zone = response?.complaint?.zoneId?.map((zone) => {
                  return { label: zone?.name, value: zone?._id };
               });
               return zone;
            });
            setAttachmentsFromBackend(response?.complaint?.attachmentUrl);
         } else if (response?.status === "JWT_INVALID") {
            route("/login");
            setGrievance(null);
         } else {
            toast.error(`Failed: ${response?.message}`);
            setGrievance(null);
         }
      } catch (error) {
         toast.error("An error occurred while fetching grievance details.");
         console.error("Error fetching grievance:", error);
      }
      finally{
         setLoading(false)
      }
   };

   const handleApprove = async () => {
      if (!reply.trim()) {
         toast.error("Reply is required to approve the grievance.");
         return;
      }

      try {
         const response = await commonApi.changeGrievanceStatus(
            grievanceId,
            "resolved",
            reply
         );
         if (response.status === "SUCCESS") {
            toast.success("Grievance status updated successfully.");
            route(-1); // Navigate back after successful approval
         } else if (response.status === "JWT_INVALID") {
            route("/login");
         } else {
            toast.error(`Failed: ${response.message}`);
         }
      } catch (error) {
         toast.error("An error occurred while updating the grievance status.");
         console.error("Error updating grievance status:", error);
      }
   };

   useEffect(() => {
      if (grievanceId) {
         getGrievance();
      }
   }, [grievanceId]);

   useEffect(() => {
      if (userRole() !== "zonal" && !checkUserTabAccess("Grievance")) {
         toast.info("You do not have permission to access Grievance");
         route(-1); // redirect to previous page if user dont not have Tab Access
         return;
      }
   }, []);

   return (
      <>
         {loading && <Loader />}
         <div className="tableWrapper common_shadow p-4 announcementMainWrapper ">
            <h3 className="text-center text-uppercase">Grievance</h3>
            <div className="grievanceInputsWrapper transparant_Scrollbar">
               <div className="inputWrapper ">
                  <InputWithLabel
                     label={true}
                     labelText={"Name"}
                     placeholder={"Name"}
                     value={grievance?.name || ""}
                     disabled={true}
                  />
                  <InputWithLabel
                     label={true}
                     labelText={"Date"}
                     placeholder={"Date"}
                     value={grievance ? formatDate(grievance.createdAt) : ""}
                     disabled={true}
                  />
                  {grievance?.latitude && (
                     <InputWithLabel
                        label={true}
                        labelText={"Latitude"}
                        placeholder={"Latitude"}
                        value={grievance?.latitude || ""}
                        disabled={true}
                     />
                  )}
                  {grievance?.longitude && (
                     <InputWithLabel
                        label={true}
                        labelText={"Longitude"}
                        placeholder={"Longitude"}
                        value={grievance?.longitude || ""}
                        disabled={true}
                     />
                  )}

                  <InputWithLabel
                     label={true}
                     labelText={"Subject"}
                     placeholder={"Subject"}
                     value={grievance?.subject || ""}
                     disabled={true}
                  />
                  <div>
                     <label className="name text_color-lable">Zone*</label>
                     <Select
                        //  options={ZoneList}
                        isMulti={true}
                        value={selectedZone ? selectedZone : ""}
                        placeholder="Select a Zone"
                        isDisabled={true}
                     />
                  </div>
               </div>
               <div className="descriptionWrapper">
                  <TextArea
                     label={"Description*"}
                     placeholder={"Add Description*"}
                     rows={4}
                     value={grievance?.description || ""}
                     disabled={true}
                  />
                  <div>
                     <label className="name text_color-lable mt-3">
                        Attachments
                     </label>
                     <div className="imgWrapper mt-0">
                        {attachementsFromBackend?.length > 0 ? (
                           attachementsFromBackend?.map((data) => {
                              return (
                                 <div>
                                    <img
                                       className="common_shadow "
                                       alt={data?.fieldName || "document"}
                                       src={`${getServerUrl()}/getFiles/${
                                          data?.fileUrl
                                       }`}
                                    />
                                 </div>
                              );
                           })
                        ) : (
                           <p>No attachment found!</p>
                        )}
                     </div>
                  </div>
                  <div className="mt-3">
                     <TextArea
                        label={"Add Reply*"}
                        placeholder={"Reply..."}
                        rows={4}
                        value={reply}
                        onChange={(e) => setReply(e.target.value)}
                     />
                  </div>
               </div>
            </div>
            <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper mt-2">
               {(userRole() == "zonal" ||
                  checkUserTabAccess("Grievance")?.access === "write") && (
                  <RoundedBtn
                     text="Approve"
                     className="bg_primary text-white px-4 mr-4"
                     type={"submit"}
                     isDisabled={grievance?.status === "resolved"}
                     onClick={handleApprove}
                  />
               )}
               <RoundedBtn
                  text="Cancel"
                  className="bg-secondary text-white px-4"
                  onClick={() => route(-1)}
               />
            </div>
         </div>
      </>
   );
};

export default ViewGrievanceMain;
