import React from 'react'
import AllTrackList from '../components/allTracks/AllTrackList'

export default function AllTracks() {
  return (
    <div>
      <AllTrackList />
    </div>
  )
}
