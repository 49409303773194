import React from 'react'
import ContractorsList from '../components/contractorManagement/ContractorsList'

export default function ContractorManagement() {
  return (
    <div>
      <ContractorsList/>
    </div>
  )
}
