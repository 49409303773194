import { getServerUrl } from "../../constants/apis/adminCommunication";
import "./DocumentsShowCard.css";

export const DocumentsShowCard = ({ documents }) => {
   return (
      <div className="DocumentsShowCard transparant_Scrollbar">
         {(Array.isArray(documents) && documents?.length > 0 )? (
            documents?.map((document, i) => (
               <img
                  src={`${getServerUrl()}/getFiles/${document?.fileUrl}`}
                  alt={document?.documentName || "Document"}
                  className=" big_Shadow"
               />
            ))
         ) : (
            <p className="m-auto">No Documents</p>
         )}
      </div>
   );
};
