import React, { memo, useCallback, useState } from "react";
import EditAndToggleBtn from "../../commanComponents/buttons/EditAndToggleBtn";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { Heading_6 } from "../../commanComponents/NavigationBar/Typography";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import useDebounce from "../../constants/useDebounce";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { commonApi } from "../../constants/apis/adminCommunication";
import InfiniteScroll from "react-infinite-scroll-component";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import { DesignationForm } from "../../commanComponents/forms/DesignationForm";
import { toast } from "react-toastify";
import { isEven } from "../../constants/Helper/NumbersFunction";
import Loader from "../../commanComponents/Loader";
import Spinner from "../../commanComponents/Spinner";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

const DesignationsList = memo(() => {
   const [searchTerm, setSearchTerm] = useState("");
   const debouncedSearchTerm = useDebounce(searchTerm, 1000); // Debounce delay of 1000ms
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const queryClient = useQueryClient(); // Get queryClient instance
   const [designationId, setDesignationId] = useState("");

   const fetchDesignations = useCallback(
      async ({ pageParam = 1 }) => {
         try {
            const response = await commonApi.getDesignationList(
               pageParam,
               debouncedSearchTerm
            );
            return response;
         } catch (error) {
            console.error("Error fetching data:", error); // Debugging
            throw error;
         }
      },
      [debouncedSearchTerm]
   );

   const { data, fetchNextPage, hasNextPage, isFetching, refetch } =
      useInfiniteQuery(
         ["designationsList", debouncedSearchTerm],
         fetchDesignations,
         {
            refetchOnWindowFocus: false, 
            getNextPageParam: (lastPage, pages) => {
               return lastPage.totalPages > pages.length
                  ? pages.length + 1
                  : undefined;
            },
         }
      );

   const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
   };

   const handleOpenPopup = () => setIsPopupOpen(true);
   const handleClosePopup = () => {
      setDesignationId("");
      setIsPopupOpen(false);
   };

   const mutation = useMutation(
      (id) => commonApi.changeDesignationStatus(id), // Pass ID to the API function
      {
         onSuccess: (data) => {
            if (data.status === "SUCCESS") {
               toast.success("Designation status updated successfully!");
               queryClient.invalidateQueries([
                  "designationsList",
                  debouncedSearchTerm,
               ]); // Invalidate and refetch
            } else if (data?.status == "JWT_INVALID") {
               toast.error(`Failed : ${data?.message}`);
            } else {
               toast.error(data.message || "Error updating status!");
            }
         },
         onError: (error) => {
            toast.error(`Error updating status: ${error.message}`);
         },
      }
   );

   const handleToggle = (id) => {
      mutation.mutate(id); // Call the mutation function with the designation ID
   };

   const handleEdit = (id) => {
      setDesignationId(id);
      handleOpenPopup();
   };

   return (
      <div className="common_shadow rounded p-3 h-100 bg-white">
         <Heading_6
            text="Designations"
            className="fw-bold text-capitalize mb-3"
         />
         <div className="d-flex align-items-center justify-content-between">
            <div className="w-50 pr-2">
               <Searchbox onChange={handleSearchChange} />
            </div>
            {checkUserTabAccess("Manage_Departments")?.access === "write" && (
               <div className="w-50 d-flex justify-content-end">
                  <RoundedbuttonWithIcon
                     text="ADD DESIGNATION"
                     className="text-capitalize w-75"
                     icon={svgIcons.PlusIcon}
                     onClick={handleOpenPopup}
                  />
               </div>
            )}
         </div>
         <div className="container p-0 w-100 mt-3 position-relative">
            <div
               className="table-responsive position-relative tablemain"
               style={{ maxHeight: "55vh", overflowY: "auto" }}
               id="scrollableDesignationDiv"
            >
               {data?.pages[0].status !== "FAILED" && data?.pages.length > 0 ? (
                  <InfiniteScroll
                     dataLength={
                        data?.pages.flatMap((page) => page.result).length || 0
                     }
                     next={fetchNextPage}
                     hasMore={hasNextPage}
                     scrollableTarget="scrollableDesignationDiv"
                  >
                     <table className="table w-100">
                        <thead
                           className="rounded-pill w-100 bg_Table_Header border-0"
                           style={{ position: "sticky", top: "0", zIndex: "1" }}
                        >
                           <tr>
                              <th scope="col" >Sr. No</th>
                              <th scope="col">Department</th>
                              <th scope="col">Designation</th>
                              <th scope="col" className="text-center text-center row-fix-to-last table-th-bg-color">Action</th>
                           </tr>
                        </thead>
                        <tbody>
                           {data?.pages
                              .flatMap((page) => page.result)
                              .map((designation, index) => (
                                 <tr key={designation?._id}>
                                    <th
                                       scope="row"
                                       className={`${
                                          isEven(index + 1) && "Table_Data"
                                       }`}
                                    >
                                       {index + 1}
                                    </th>
                                    <td
                                       className={`text-capitalize ${
                                          isEven(index + 1) && "Table_Data"
                                       }`}
                                    >
                                       {designation?.departmentId?.name}
                                    </td>
                                    <td
                                       className={`text-capitalize ${
                                          isEven(index + 1) && "Table_Data"
                                       }`}
                                    >
                                       {designation?.name}
                                    </td>
                                    <td
                                      className={` row-fix-to-last text-center`}
                                    >
                                       <EditAndToggleBtn
                                          isActive={designation?.isActive}
                                          handleClick2={() =>
                                             handleToggle(designation._id)
                                          } // Pass ID to the handler
                                          handleClick1={() =>
                                             handleEdit(designation._id)
                                          } // Pass ID to the handler
                                          disabled={checkUserTabAccess("Manage_Departments")?.access !== "write"}
                                       />
                                    </td>
                                 </tr>
                              ))}
                        </tbody>
                     </table>
                  </InfiniteScroll>
               ) : (
                  !isFetching && (
                     <h6 className="DataNotFoundText">
                        No Designations Found...
                     </h6>
                  )
               )}
               {isFetching && (
                  <div className="m-3 text-center">
                     <Spinner />
                  </div>
               )}
            </div>
         </div>
         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            title={designationId ? "Update Designation" : "Create Designation"}
         >
            <DesignationForm
               designationId={designationId}
               onClose={handleClosePopup}
               refetch={refetch}
            />
         </CenteredPopup>
      </div>
   );
});

export default DesignationsList;
