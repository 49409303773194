import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../commanComponents/CommonLayout/AuthLayout";
import InputWithIcon from "../commanComponents/inputs/InputWithIcon";
import { svgIcons } from "../constants/utilities/ImagesExport/Icons";
import { Heading_3 } from "../commanComponents/NavigationBar/Typography";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { commonApi } from "../constants/apis/adminCommunication";
import { toast } from "react-toastify";

const ResetPasswordMain = () => {
  const { control, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const navigate = useNavigate();
  
  const mutation = useMutation((data) => {
    setLoading(true);
    return commonApi.resetPassword(data)
 }, {
    onSuccess: async (data) => {
      if (data?.status === "SUCCESS") {
        toast.success("Password reset successfully!");
        navigate("/login");
        setLoading(false)
      } else {
        toast.error(`Failed to reset password: ${data?.message}`);
        setLoading(false)
      }
    },
    onError: (error) => {
      toast.error("An error occurred while resetting the password.");
      console.error("Error:", error);
      setLoading(false)
    }
  });

  const onSubmit = (data) => {
    mutation.mutate({
      userId: data.userId,
      oldPassword: data.oldpassword,
      newPassword: data.newpassword,
      confirmPassword: data.confirmpassword
    });
  };

  const toggleVisibility = (field) => {
    setVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <AuthLayout>
      <div className="text-center">
        <Heading_3 text="Reset Password" className="text_primary text-center" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="py-2">
        <div className="d-flex row w-100 mx-auto">
          <div className="col-lg-6 col-12 p-0 pr-lg-2">
            <InputWithIcon
              label="User Id"
              control={control}
              name="userId"
              className="my-2"
              icon={svgIcons.userIcon}
              placeholder="User Id"
            />
          </div>
          <div className="col-lg-6 col-12 p-0 pl-lg-2">
            <InputWithIcon
              control={control}
              label="Old Password"
              name="oldpassword"
              className="my-2"
              type={visibility.oldPassword ? "text" : "password"}
              icon={
                visibility.oldPassword ? svgIcons.closeEye : svgIcons.openEye
              }
              placeholder="Old Password..."
              handleClick={() => toggleVisibility("oldPassword")}
            />
          </div>
          <div class="col-lg-6 col-12 p-0 pr-lg-2">
            <InputWithIcon
              control={control}
              label="New Password"
              name="newpassword"
              className="my-2"
              type={visibility.newPassword ? "text" : "password"}
              icon={
                visibility.newPassword ? svgIcons.closeEye : svgIcons.openEye
              }
              placeholder="New Password..."
              handleClick={() => toggleVisibility("newPassword")}
            />
          </div>
          <div class="col-lg-6 col-12 p-0 pl-lg-2">
            <InputWithIcon
              control={control}
              label="Confirm Password"
              name="confirmpassword"
              className="my-2"
              type={visibility.confirmPassword ? "text" : "password"}
              icon={
                visibility.confirmPassword
                  ? svgIcons.closeEye
                  : svgIcons.openEye
              }
              placeholder="Confirm Password..."
              handleClick={() => toggleVisibility("confirmPassword")}
            />
          </div>
        </div>

        <div class="my-3 text-center">
          <button
            type="submit"
            className="btn text-white mx-auto px-5 loginBtn"
          >
            {
                    loading && <span
                        className="spinner-border mr-2"
                        role="status"
                        aria-hidden="true"
                        style={{
                           width: "20px",
                           height: "20px",
                           borderWidth: "2px",
                        }}
                     ></span>
                  } Reset
          </button>
        </div>
        <div className="text-center">
          <p>
            Already have an Account{" "}
            <Link to="/login" className="text_primary">
              Sign in
            </Link>
          </p>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ResetPasswordMain;
