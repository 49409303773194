import React, { useEffect, useState } from "react";
import SelectBox, {
   InputWithLabel,
} from "../../commanComponents/inputs/InputWithLabel";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import { toast } from "react-toastify";
import { commonApi } from "../../constants/apis/adminCommunication";
import Select from "react-select";
import Loader from "../../commanComponents/Loader";
import { userRole } from "../../constants/Helper/getUserRole";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

function WasteInfoManagement() {
   const {
      register,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors },
   } = useForm();
   const navgation = useNavigate();
   const [params] = useSearchParams();
   const pageType = params.get("type");
   const westInfoId = params.get("westInfoId");
   const [loading, setLoading] = useState(false);

   const fetchWasteInfoById = async () => {
      try {
         setLoading(true);
         const serverResponse = await commonApi.geteWasteInfoById(westInfoId);

         if (serverResponse?.status === "SUCCESS") {
            setValue("wasteType", serverResponse?.wasteInfo?.wasteType);
            setValue("unit", serverResponse?.wasteInfo?.unit);
            setValue(
               "perPersonExpectedWaste",
               serverResponse?.wasteInfo?.perPersonExpectedWaste
            );
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "FAILED") {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   const handleUpdate = async (data) => {
      const DataToSend = {
         ...data,
         id: westInfoId,
         perPersonExpectedWaste: Number(data?.perPersonExpectedWaste),
      };
      try {
         setLoading(true);
         const serverResponse = await commonApi.updateWasteInfo(DataToSend);
         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
            navgation(-1);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };
   const handleAdd = async (data) => {
      try {
         setLoading(true);
         const serverResponse = await commonApi.addWestInfo({
            ...data,
            perPersonExpectedWaste: Number(data?.perPersonExpectedWaste),
         });

         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
            navgation(-1);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.error(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if ((pageType === "update" || pageType === "view") && westInfoId) {
         fetchWasteInfoById();
      }
   }, [pageType, westInfoId]);

   useEffect(()=>{
      if (!checkUserTabAccess("Garbage_Info") && userRole() !== 'zonal') {
         toast.info('You do not have permission to access Garbage Info')
         navgation(-1); // redirect to previous page if user dont not have Tab Access
        return;
     }
     },[])

   return (
      <>
         {loading && <Loader />}
         <div className="tableWrapper big_Shadow p-4 announcementMainWrapper">
            <h3 className="text-center text-uppercase">
               {pageType == "add"
                  ? "Add Garbage Info"
                  : pageType == "update"
                  ? "Update Garbage Info"
                  : "Garbage Info"}
            </h3>
            <div className="inputWrapper mt-4">
               <InputWithLabel
                  label={true}
                  labelText={"Garbage Type*"}
                  placeholder={"Enter Garbage Type*"}
                  disabled={pageType === "view"}
                  register={{
                     ...register("wasteType", {
                        required: "Garbage Type is required",
                     }),
                  }}
                  errorMessage={errors.wasteType}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Unit*"}
                  placeholder={"Enter Dry Unit*"}
                  disabled={pageType === "view"}
                  register={{
                     ...register("unit", {
                        required: "Unit is required",
                     }),
                  }}
                  errorMessage={errors.unit}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Per Person Expected Waste*"}
                  placeholder={"Per Person Expected Waste*"}
                  disabled={pageType === "view"}
                  type={"number"}
                  min="0"
                  register={{
                     ...register("perPersonExpectedWaste", {
                        required: "expected Waste is required",
                     }),
                  }}
                  errorMessage={errors.perPersonExpectedWaste}
               />
            </div>

            {(pageType == "add" || pageType == "update") && (
               <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper mt-3">
                  <RoundedBtn
                     text={pageType == "add" ? "Submit" : "Update"}
                     className="bg_primary text-white px-4 mr-4"
                     type={"submit"}
                     onClick={
                        pageType == "add"
                           ? handleSubmit(handleAdd)
                           : handleSubmit(handleUpdate)
                     }
                  />
                  <RoundedBtn
                     text="Cancel"
                     className="bg-secondary text-white px-4"
                     onClick={() => {
                        navgation(-1);
                     }}
                  />
               </div>
            )}
         </div>
      </>
   );
}

export default WasteInfoManagement;
