// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Customize the radio input */
.radio_btn {
    gap: 10px;
}
.radio_btn .custom-radio {
    width: 20px;
    /* Set the desired width */
    height: 20px;
    /* Set the desired height */
    position: relative;
}

/* Customize the checked dot color */
.radio_btn .custom-radio:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    /* Adjust the size of the dot */
    height: 12px;
    /* Adjust the size of the dot */
    background-color: var(--Primary_color);
    /* Set the desired dot color */
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

/* Hide the default dot */
.radio_btn .custom-radio:checked {
    background-color: transparent;
}

.radio_btn .custom-radio:checked {
    color: var(--Primary_color);
    /* Optional: Change label color when checked */
}`, "",{"version":3,"sources":["webpack://./src/commanComponents/buttons/RadioButton.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,SAAS;AACb;AACA;IACI,WAAW;IACX,0BAA0B;IAC1B,YAAY;IACZ,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA,oCAAoC;AACpC;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,+BAA+B;IAC/B,YAAY;IACZ,+BAA+B;IAC/B,sCAAsC;IACtC,8BAA8B;IAC9B,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA,yBAAyB;AACzB;IACI,6BAA6B;AACjC;;AAEA;IACI,2BAA2B;IAC3B,8CAA8C;AAClD","sourcesContent":["/* Customize the radio input */\n.radio_btn {\n    gap: 10px;\n}\n.radio_btn .custom-radio {\n    width: 20px;\n    /* Set the desired width */\n    height: 20px;\n    /* Set the desired height */\n    position: relative;\n}\n\n/* Customize the checked dot color */\n.radio_btn .custom-radio:checked::before {\n    content: '';\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    width: 12px;\n    /* Adjust the size of the dot */\n    height: 12px;\n    /* Adjust the size of the dot */\n    background-color: var(--Primary_color);\n    /* Set the desired dot color */\n    border-radius: 50%;\n    transform: translate(-50%, -50%);\n}\n\n/* Hide the default dot */\n.radio_btn .custom-radio:checked {\n    background-color: transparent;\n}\n\n.radio_btn .custom-radio:checked {\n    color: var(--Primary_color);\n    /* Optional: Change label color when checked */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
