import { useMutation } from "react-query";
import EditAndToggleBtn from "./EditAndToggleBtn";
import "./NavigationButtons.css";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useState } from "react";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

export const RoundedbuttonWithIcon = ({
  text,
  textClasses,
  className = "text-black",
  bg = "bg_light_green",
  icon: IconComponent, // Renamed for clarity
  onClick,
}) => {
  return (
    <button
      className={`${className} ${bg} d-flex px-2 justify-content-center align-items-center rounded btn border`}
      onClick={onClick}
      title={text}
    >
      {IconComponent && IconComponent} {/* Render as component */}
      <span className={`ml-2 ${textClasses}`}>{text}</span>
    </button>
  );
};

export const RoundedbuttonWithIncreaseWidth = ({
  text,
  className = "text-black",
  bg = "bg_light_green",
  isActive,
  icon: IconComponent,
  departmentId,
  handleEdit,
  designationCount,
  setListUpdated
}) => {
  const token = "your-auth-token"; // Replace with actual token logic
  const [status, setStatus] = useState(isActive);

  const mutation = useMutation(
    () => commonApi.changeDepartmentStatus(departmentId, token),
    {
      onSuccess: (data) => {
        if (data.status === "SUCCESS") {
          setStatus(!status);
          toast.success("Department status updated successfully!");
          setListUpdated((prev)=> !prev)
        } else if (data?.status == "JWT_INVALID") {
          toast.error(`Failed : ${data?.message}`);
        } else {
          toast.error("Error updating status!");
        }
      },
      onError: (error) => {
        toast.error(`Error updating status: ${error.message}`);
      },
    }
  );

  const handleToggle = () => {
    mutation.mutate(); // Call the mutation function to change department status
  };
  return (
    <div
      className={`${className} ${isActive? bg : 'bg_light_gray'} d-flex px-2 justify-content-between align-items-center rounded px-3 py-2 border position-relative RoundedbuttonWithIncreaseWidth mb-1`}
    >
      <span className="ml-2">{text}</span>
      <span className="count">{designationCount || 0}</span>
      <div className={`h-100 extraBtn ${isActive? bg : 'bg_light_gray'}`} >
        <EditAndToggleBtn
          departmentId={departmentId}
          isActive={status}
          handleClick1={handleEdit}
          handleClick2={handleToggle}
          disabled={checkUserTabAccess("Manage_Departments")?.access !== "write"}
        />
      </div>
    </div>
  );
};
