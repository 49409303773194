import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../components/NotFound";
import ManageDepartments from "../pages/ManageDepartments";
import ProfilePage from "../pages/ProfilePage";
import UserManagement from "../pages/UserManagement";
import Announcements from '../pages/Announcements';
import AnnouncementManagement from "../components/announcements/AnnouncementManagement";
import RoleAccess from "../pages/RoleAccess";
import UserCrudMain from "../components/UserManagement/crudUser/UserCrudMain";
import DumpYard from "../pages/DumpYard";
import DumpYardManagement from "../components/dumpYardManage/DumpYardManagement";
import ResidentialGarbageCollection from "../pages/GarbageCollection/ResidentialGarbageCollection";
import CommercialGarbageCollection from "../pages/GarbageCollection/CommercialGarbageCollection";
import WastInfo from "../pages/WastInfo";
import WasteInfoManagement from "../components/wasteInfo/WasteInfoManagement";
import ContractorManagement from "../pages/ContractorManagement";
import HandleContractorDetails from "../components/contractorManagement/HandleContractorDetails";
import SurveyManagement from "../pages/SurveyManagement";
import ViewSurvey from "../components/surveyManagement/ViewSurvey";
import ZoneAndWard from "../pages/ZoneAndWard";
import Grievance from "../pages/Grievance";
import ViewGrievanceMain from "../components/Grievance/ViewGrievanceMain";
import LiveTracking from "../pages/LiveTracking";
import Dashboard from "../pages/Dashboard";
import AllTracks from "../pages/AllTracks";
import Notification from "../pages/Notification";
import MyGrievance from "../pages/MyGrievance";
import AddGrievance from "../components/myGrievance/AddGrievance";
import SurveyFilterPage from "../components/surveyManagement/SurveyFilterPage";
import TransferRequestList from "../components/transferRequest/TransferRequestList";
import ViewTrasferRequest from "../components/transferRequest/ViewTrasferRequest";

const AdminRoute = () => {
  return (
    <Routes>
      {/* ------------------------------------- Admin Routes---------------------------------------------- */}
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/manage-departments" element={<ManageDepartments />} />
      <Route path="/user-management" element={<UserManagement />} />
      <Route path="/user-management/handle-user-details" element={<UserCrudMain />} />
      <Route path="/user-roll-access" element={<RoleAccess />} />
      <Route path="/garbage-info" element={<WastInfo />} />
      <Route path='/garbage-info/garbage-info-management' element={<WasteInfoManagement />} />
      <Route path="/garbage-collection/residential-garbage-collection" element={<ResidentialGarbageCollection />} />
      <Route path="/garbage-collection/commercial-garbage-collection" element={<CommercialGarbageCollection />} />
      <Route path="/zone-ward" element={<ZoneAndWard />} />
      <Route path="/grievance" element={<Grievance />} />
      <Route path="/grievance/view-grievance" element={<ViewGrievanceMain />} />
      <Route path="/live-tracking" element={<LiveTracking />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="*" element={<NotFound />} />
      <Route path='/announcements' element={<Announcements />} />
      <Route path='/announcements/announcement-management' element={<AnnouncementManagement />} />
      <Route path='/dump-yard' element={<DumpYard />} />
      <Route path='/dump-yard/dump-yard-management' element={<DumpYardManagement />} />

      {/* ------------------------------------- Zonal Routes---------------------------------------------- */}
      <Route path='/manage-contractors' element={<ContractorManagement />} />
      <Route path='/manage-contractors/handle-contractor-details' element={<HandleContractorDetails />} />
      <Route path='/survey-detail' element={<SurveyManagement />} />
      <Route path='/survey-detail/view-survey' element={<ViewSurvey />} />
      <Route path='/survey-detail/flat-details' element={<SurveyFilterPage />} />
      <Route path='/my-grievance' element={<MyGrievance />} />
      <Route path='/my-grievance/manage-grievance' element={<AddGrievance />} />
      <Route path='/all-track' element={<AllTracks />} />
      <Route path='/transfer-request' element={<TransferRequestList />} />
      <Route path='/transfer-request/view-transfer-request' element={<ViewTrasferRequest />} />
    </Routes>
  );
};

export default AdminRoute;
