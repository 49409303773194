import React, { useEffect, useState } from "react";
import Loader from "../../commanComponents/Loader";
import OrangeButton from "../../commanComponents/buttons/OrangeButton";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { userRole } from "../../constants/Helper/getUserRole";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

export default function SurveyFilterPage() {
   const [params] = useSearchParams();
   const [loading, setLoading] = useState(false);
   const navgation = useNavigate();
   const surveyDetailId = params.get("surveyDetailId");
   const [surveyDetail, setSurveyDetail] = useState([]);
   const [selectedWingFlatList, setSelectedWingFlatList] = useState([]);
   const [selectedWing, setSelectedWing] = useState("");

   const fetchSurveyDetailsByID = async (id) => {
      try {
         setLoading(true);
         const serverResponse = await commonApi.getSurveyById(id);

         if (serverResponse?.status === "SUCCESS") {
            setSurveyDetail(serverResponse?.result);
            setSelectedWing(
               serverResponse?.result?.wings?.length
                  ? serverResponse?.result?.wings[0]
                  : ""
            );
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed---- : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "FAILED") {
            toast.error(`Failed++++ : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed===== : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   const handleSelectedWingFlatList = () => {
      const data = surveyDetail?.ownersInfo?.filter((ele) => {
         return selectedWing == ele?.wing && ele;
      });
      setSelectedWingFlatList(data);
   };

   useEffect(() => {
      if (surveyDetailId) {
         fetchSurveyDetailsByID(surveyDetailId);
      }
   }, [surveyDetailId]);

   useEffect(() => {
      handleSelectedWingFlatList();
   }, [selectedWing]);

   useEffect(()=>{
      if (userRole() !=='zonal' && !checkUserTabAccess("Survey_Detail")) {
         toast.info('You do not have permission to access Survey Detail')
         navgation(-1); // redirect to previous page if user dont not have Tab Access
        return;
     }
     },[])

   return (
      <>
         {loading && <Loader />}
         <div className="tableWrapper big_Shadow p-4 ">
            <h3 className="text-center text-uppercase mb-4">Details</h3>
            <div className="orgBtnWrapper">
               {surveyDetail?.wings?.length > 0 &&
                  surveyDetail?.wings?.map((data, index) => {
                     return (
                        <OrangeButton
                           text={`Wing ${data}`}
                           className={
                              data !== selectedWing &&
                              "oranage-btn-not-selected"
                           }
                           onClick={() => {
                              setSelectedWing(data);
                           }}
                        />
                     );
                  })}
            </div>
            <div className="YellowCardWrapper">
               <h5>{`--Wing ${selectedWing} people list--`}</h5>

               <div className="wingHouseBtnWrapper">
                  {selectedWingFlatList?.length > 0 &&
                     selectedWingFlatList?.map((data, index) => {
                        return (
                           <button
                              onClick={() => {
                                 navgation(
                                    `/survey-detail/view-survey?surveyDetailId=${surveyDetailId}&flatId=${data?.id}`
                                 );
                              }}
                           >
                              Flat No- {data?.plotNo}
                           </button>
                        );
                     })}
               </div>
               {(!selectedWingFlatList ||
                  selectedWingFlatList?.length == 0) && (
                  <h6 className="DataNotFoundText">No Wings Data Found...</h6>
               )}
            </div>
         </div>
      </>
   );
}
