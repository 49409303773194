import React from 'react'
import DashboardMain from '../components/dashboard/DashboardMain'

const Dashboard = () => {
  return (
    <div>
      <DashboardMain />
    </div>
  )
}

export default Dashboard