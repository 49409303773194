import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { Heading_6 } from "../../commanComponents/NavigationBar/Typography";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import UserManagementList from "./UserManagementList";
import "./UserManagementMain.css";
import { commonApi } from "../../constants/apis/adminCommunication";
import useDebounce from "../../constants/useDebounce";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import UserFilter from "./UserFilter";
import { toast } from "react-toastify"; // Ensure this is imported
import { useNavigate } from "react-router-dom";
import Loader from "../../commanComponents/Loader";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { userRole } from "../../constants/Helper/getUserRole";

const UserManagementMain = () => {
   const [page, setPage] = useState(1);
   const [searchString, setSearchString] = useState("");
   const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [usertype, setUsertype] = useState([]);

   const route = useNavigate();

   const queryClient = useQueryClient(); // Initialize queryClient
   const queryKey = ["userList", usertype, page, debouncedSearchTerm];
   const { data, isLoading, isError, isFetching } = useQuery(
      queryKey,
      () => commonApi.getUserList(usertype, page, debouncedSearchTerm), // Use debouncedSearchTerm
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
      }
   );

   const mutation = useMutation(
      (id) => commonApi.changeUserStatus(id), // Pass ID to the API function
      {
         onSuccess: (data) => {
            if (data.status === "SUCCESS") {
               toast.success("User status updated successfully!"); // Correct message
               queryClient.invalidateQueries(queryKey); // Invalidate and refetch
            } else if (data?.status === "JWT_INVALID") {
               toast.error(`Failed: ${data?.message}`);
            } else {
               toast.error(data.message || "Error updating status!");
            }
         },
         onError: (error) => {
            toast.error(`Error updating status: ${error.message}`);
         },
      }
   );

   const handleToggle = (id) => {
      mutation.mutate(id); // Call the mutation function with the user ID
   };

   const handleOpenPopup = () => setIsPopupOpen(true);
   const handleClosePopup = () => setIsPopupOpen(false);

   const handleSearch = (e) => setSearchString(e.target.value);

   useEffect(() => {
      if (!checkUserTabAccess("User_Management") || userRole() == "zonal") {
         toast.info("You do not have permission to access User Management");
         route(-1); // redirect to previous page if user dont not have Tab Access
         return;
      }
   }, []);

   return (
      <div className="w-100 mx-auto UserManagementMain">
         <div className="search_btn_wrapper">
            <div className="d-flex align-items-center w-100">
               <div
                  className="px-4 py-2 d-flex align-items-center cursor_pointer bg_support_orange rounded mr-2"
                  onClick={handleOpenPopup}
               >
                  {svgIcons.filtersIcon}
                  <Heading_6 text="Filter" className="ml-3 mb-0 text-white" />
               </div>
               <div>
                  <Searchbox
                     onChange={handleSearch}
                     placeholder="Search Users..."
                  />
               </div>
            </div>
            {checkUserTabAccess("User_Management")?.access === "write" && (
               <div>
                  <RoundedbuttonWithIcon
                     text="Create User"
                     className="text-capitalize w-100 px-lg-5 px-2"
                     icon={svgIcons.PlusIcon}
                     textClasses="text-uppercase"
                     onClick={() =>
                        route("/user-management/handle-user-details")
                     }
                  />
               </div>
            )}
         </div>
         <div className="mw-100 py-0 UserManagementMain_TableWrapper">
            {isFetching && <Loader />}

            <UserManagementList
               users={data?.users || []}
               page={page}
               handleToggle={handleToggle}
            />
         </div>
         <div className="py-2">
            <PaginationButton
               page={page}
               setPage={setPage}
               totalPages={data?.totalPages || 1}
            />
         </div>
         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            title="Filter by Type"
         >
            <UserFilter
               onClose={handleClosePopup}
               usertype={usertype}
               setUsertype={setUsertype}
            />
         </CenteredPopup>
      </div>
   );
};

export default UserManagementMain;
