import React from "react";
import AnnouncementsList from "../components/announcements/AnnouncementsList";

function Announcements() {
   return (
      <div>
        <AnnouncementsList />
      </div>
   );
}

export default Announcements;
