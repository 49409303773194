import { useNavigate, useSearchParams } from "react-router-dom";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import { InputWithLabel } from "../../commanComponents/inputs/InputWithLabel";
import TextArea from "../../commanComponents/inputs/TextArea";
import {
   commonApi,
   getServerUrl,
} from "../../constants/apis/adminCommunication";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
   convertToDateInputFormat,
   formatDate,
} from "../../constants/Helper/Helpers";
import { useForm } from "react-hook-form";
import FileUpload from "../../commanComponents/fileUpload/FileUpload";
import Loader from "../../commanComponents/Loader";
import Select from "react-select";

const AddGrievance = () => {
   const [searchParams] = useSearchParams();
   const route = useNavigate();
   const grievanceId = searchParams.get("grievanceId");
   const pageType = searchParams.get("type");
   const [grievance, setGrievance] = useState(null);
   const [reply, setReply] = useState(""); // State to handle the reply
   const [selectedFiles, setSelectedFiles] = useState([]);
   const [loading, setLoading] = useState(false);
   const [attachementsFromBackend, setAttachmentsFromBackend] = useState([]);

   const {
      register,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors },
   } = useForm();

   const getGrievance = async () => {
      try {
         setLoading(true);
         const response = await commonApi.getGrivanceById(grievanceId);
         if (response.status === "SUCCESS") {
            setValue("subject", response?.complaint?.subject);
            setValue(
               "date",
               convertToDateInputFormat(response?.complaint?.date)
            );
            setValue("description", response?.complaint?.description);
            setReply(response?.complaint?.logs[0]?.reply);
            setSelectedFiles(response?.complaint?.attachmentUrl[0]);
            setAttachmentsFromBackend(response?.complaint?.attachmentUrl);
         } else if (response.status === "JWT_INVALID") {
            route("/login");
            setGrievance(null);
         } else {
            toast.error(`Failed: ${response.message}`);
            setGrievance(null);
         }
      } catch (error) {
         toast.error("An error occurred while fetching grievance details.");
         console.error("Error fetching grievance:", error);
      } finally {
         setLoading(false);
      }
   };
   
   const handleAdd = async (data) => {
      const formData = new FormData();
      formData.append("complaintData", JSON.stringify({ ...data }));
      if (selectedFiles[0]) {
         formData.append("attachmentUrl", selectedFiles[0]);
      }
      try {
         setLoading(true);
         const serverResponse = await commonApi.createGrievance(formData);

         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
            route(-1);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.error(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (grievanceId) {
         getGrievance();
      }
   }, [grievanceId]);

   return (
      <>
         {" "}
         {loading && <Loader />}
         <div className="tableWrapper common_shadow p-4 announcementMainWrapper ">
            <h3 className="text-center text-uppercase">Add Grievance</h3>
            <div className="grievanceInputsWrapper transparant_Scrollbar">
               <div className="inputWrapper mt-4">
                  <InputWithLabel
                     label={true}
                     labelText={"Subject"}
                     placeholder={"Subject"}
                     disabled={pageType == "view"}
                     register={{
                        ...register("subject", {
                           required: "Subject is required",
                        }),
                     }}
                     errorMessage={errors.subject}
                  />
                  <InputWithLabel
                     label={true}
                     labelText={"Date"}
                     type={"date"}
                     placeholder={"Date"}
                     disabled={pageType == "view"}
                     register={{
                        ...register("date", {
                           required: "Date is required",
                        }),
                     }}
                     errorMessage={errors.date}
                  />
               </div>

               <div className="inputWrapper mt-4">
                  <TextArea
                     label={"Description*"}
                     placeholder={"Add Description*"}
                     rows={4}
                     disabled={pageType == "view"}
                     register={{
                        ...register("description", {
                           required: "Description is required",
                        }),
                     }}
                     errorMessage={errors.description}
                  />
                  <div className="">
                     <FileUpload
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        slectedFileName={
                           selectedFiles?.length ? selectedFiles[0]?.name : ""
                        }
                        label={"Upload Attachment"}
                        style={{ width: "100%" }}
                        disabled={pageType == "view"}
                     />
                  </div>
               </div>
               <div className="mt-4">
                  {pageType !== "add" && (
                        <div className="mb-3">
                        <label className="name text_color-lable">
                           Attachments
                        </label>
                        <div className="imgWrapper mt-0">
                              {attachementsFromBackend?.length > 0 ? (
                                 attachementsFromBackend?.map((data) => {
                                    return (
                                       <div>
                                          <img
                                             className="common_shadow "
                                             alt={data?.fieldName || "document"}
                                             src={`${getServerUrl()}/getFiles/${
                                                data?.fileUrl
                                             }`}
                                          />
                                       </div>
                                    );
                                 })
                              ) : (
                                 <p>No attachment found!</p>
                              )}
                           </div>
                     </div>
                     )}
                  {pageType == "view" && (
                     <TextArea
                        label={"Reply*"}
                        placeholder={"Reply..."}
                        rows={4}
                        value={reply}
                        onChange={(e) => setReply(e.target.value)}
                        disabled={true}
                     />
                  )}
               </div>
            </div>
            <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper mt-2">
               {pageType !== "view" && (
                  <RoundedBtn
                     text="Submit"
                     className="bg_primary text-white px-4 mr-4"
                     type={"submit"}
                     onClick={handleSubmit(handleAdd)}
                  />
               )}

               <RoundedBtn
                  text="Cancel"
                  className="bg-secondary text-white px-4"
                  onClick={() => route(-1)}
               />
            </div>
         </div>
      </>
   );
};

export default AddGrievance;
