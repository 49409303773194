// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationButtonWithDropdown .icons{
display: flex;
justify-content: center;
align-items: center;
background-color: black;
height: 25px;
width: 25px;
border-radius: 50%;
padding: 2px;
}
.NavigationButtonWithDropdown .dropdown{
    overflow: hidden;
    transition: all .2s ease-in-out;
}
.NavigationButtonWithDropdown .activeDropdown{
max-height: 2000px;
}
.NavigationButtonWithDropdown .closeDropdown{
max-height: 0;
}`, "",{"version":3,"sources":["webpack://./src/commanComponents/buttons/NavigationButtonWithDropdown.css"],"names":[],"mappings":"AAAA;AACA,aAAa;AACb,uBAAuB;AACvB,mBAAmB;AACnB,uBAAuB;AACvB,YAAY;AACZ,WAAW;AACX,kBAAkB;AAClB,YAAY;AACZ;AACA;IACI,gBAAgB;IAChB,+BAA+B;AACnC;AACA;AACA,kBAAkB;AAClB;AACA;AACA,aAAa;AACb","sourcesContent":[".NavigationButtonWithDropdown .icons{\ndisplay: flex;\njustify-content: center;\nalign-items: center;\nbackground-color: black;\nheight: 25px;\nwidth: 25px;\nborder-radius: 50%;\npadding: 2px;\n}\n.NavigationButtonWithDropdown .dropdown{\n    overflow: hidden;\n    transition: all .2s ease-in-out;\n}\n.NavigationButtonWithDropdown .activeDropdown{\nmax-height: 2000px;\n}\n.NavigationButtonWithDropdown .closeDropdown{\nmax-height: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
