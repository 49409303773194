import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { RoundedBtn } from "../buttons/SubmitBtns";
import { InputWithLabel } from "../inputs/InputWithLabel";
import "./Forms.css";
import { commonApi } from "../../constants/apis/adminCommunication";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

export const CreateDepartmentForm = ({ onClose, onSuccess, departmentId, departmentName , setDepartmentName}) => {
  const [error, setError] = useState("");
  
  const createMutation = useMutation(
    (data) => commonApi.createDepartment(data.name, "your-auth-token"),
    {
      onSuccess: (data) => {
        if (data?.status === "SUCCESS") {
          toast.success("Department created successfully!");
          setDepartmentName(""); // Clear input after successful creation
          onSuccess(); // Call onSuccess to refetch departments
          onClose(); // Close the popup
        }else if (data?.status == "JWT_INVALID") {
          toast.error(`Failed : ${data?.message}`);
        } 
         else {
          toast.error(data?.message || "Department Not Created!");
        }
      },
      onError: (error) => {
        toast.error(`Error creating department: ${error.message}`);
      },
    }
  );

  const updateMutation = useMutation(
    (data) => commonApi.updateDepartment(departmentId, data.name, "your-auth-token"),
    {
      onSuccess: (data) => {
        if (data?.status === "SUCCESS") {
          toast.success("Department updated successfully!");
          setDepartmentName(""); // Clear input after successful update
          onSuccess(); // Call onSuccess to refetch departments
          onClose(); // Close the popup
        } else {
          toast.error(data?.message || "Department Not Updated!");
        }
      },
      onError: (error) => {
        toast.error(`Error updating department: ${error.message}`);
      },
    }
  );

  const handleChange = (e) => {
    setDepartmentName(e.target.value);
    if (error) {
      setError(""); // Clear error message on typing
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!departmentName.trim()) {
      setError("Department name is required");
      return;
    }
    setError(""); // Clear error
    if (departmentId) {
      updateMutation.mutate({ name: departmentName });
    } else {
      createMutation.mutate({ name: departmentName });
    }
  };

  return (
    <div className="CreateDepartmentForm">
      <form className="py-3" >
        <div className="inputwrapper mx-auto w-100" style={{textAlign:'start'}}>
        <label className="name">Department Name*</label>
          <InputWithLabel
            placeholder="Enter Department Name*"
            value={departmentName}
            onChange={handleChange}
            errorMessage={{message:error}} // Display validation error
          />
        </div>
        <div className="d-flex align-items-center justify-content-center buttonsWrapper mt-4">
        {checkUserTabAccess("Manage_Departments")?.access == "write" && (
          <RoundedBtn
            text={departmentId ? "Update" : "Create"}
            className="bg_primary text-white px-4"
            type="submit"
            onClick={handleSubmit}
            disabled={createMutation.isLoading || updateMutation.isLoading}
          />)}
          <RoundedBtn
            text="Close"
            className="bg-secondary text-white px-4"
            onClick={(e) => {
              e.preventDefault()
              onClose()
            }}
            disabled={createMutation.isLoading || updateMutation.isLoading}
          />
        </div>
      </form>
    </div>
  );
};
