import "./CommonLayout.css";
const BigTableLayout = ({ children, isShadow = true }) => {
   return (
      <div
         className={`tableWrapper w-100 mx-auto BigTableLayout ${
            isShadow ? "common_shadow" : ""
         }`}
      >
         <div className="p-0 w-100 position-relative">
            <div className="table-responsive position-relative w-100 tablemain">
               {children}
            </div>
         </div>
      </div>
   );
};

export default BigTableLayout;
