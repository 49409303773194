import React from 'react'
import { getServerUrl } from '../constants/apis/adminCommunication'

function Attachment({onClose, fileUrl}) {
    
  return (
    <div>
       <img className='AttachmentImage' alt={'Attachment'} src={typeof fileUrl === "object" ? URL.createObjectURL(fileUrl) : `${getServerUrl()}/getFiles/${fileUrl}`} />
    </div>
  )
}

export default Attachment
