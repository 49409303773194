// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.UserManagementMain .UserManagementMain_TableWrapper{
    border-radius: 10px;
    background-color: white;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
}


/* User Filter Form  */
.userFilterForm{
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    align-items: center;
}
.userFilterForm .buttonsWrapper{
    gap: 5px
}`, "",{"version":3,"sources":["webpack://./src/components/UserManagement/UserManagementMain.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gDAAgD;AACpD;;;AAGA,sBAAsB;AACtB;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,QAAQ;IACR,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI;AACJ","sourcesContent":["\n.UserManagementMain .UserManagementMain_TableWrapper{\n    border-radius: 10px;\n    background-color: white;\n    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);\n}\n\n\n/* User Filter Form  */\n.userFilterForm{\n    display: flex;\n    height: 100%;\n    flex-direction: column;\n    gap: 2px;\n    justify-content: center;\n    align-items: center;\n}\n.userFilterForm .buttonsWrapper{\n    gap: 5px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
