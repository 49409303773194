import React, { useContext, useEffect, useRef, useState } from "react";
import "./profile.css";
import defaultProfile from "../../assets/img/defaultProfile.svg";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { InputWithLabel } from "../../commanComponents/inputs/InputWithLabel";
import TextArea from "../../commanComponents/inputs/TextArea";
import Button from "../../commanComponents/buttons/Button";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
   commonApi,
   getServerUrl,
} from "../../constants/apis/adminCommunication";
import Cookies from "js-cookie";
import Loader from "../../commanComponents/Loader";
import { IoCameraReverse } from "react-icons/io5";
import { UserDataContext } from "../../constants/stateManagement/GetUserDataProvider";

export default function Profile() {
   const [isEdit, setIsEdit] = useState(false);
   const [loading, setLoading] = useState(false);
   const {
      register,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors },
   } = useForm();
   const cookieValue =
      Cookies.get("loginToken") && JSON.parse(Cookies.get("loginToken"));
   const userProfileId = cookieValue?.userDetails?._id;
   const [selectedImage, setSelectedImage] = useState("");
   const fileInputRef = useRef(null);
   const { userData, userDataLoading, getUserData } =
      useContext(UserDataContext);

   const handleWrapperClick = () => {
      fileInputRef.current.click();
   };

   const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
         setSelectedImage(file);
      }
   };

   const updateProfile = async (data) => {
      const formData = {
         id: userProfileId,
         mobile: String(data.mobile),
         name: data?.name,
         email: data?.email,
         address: data?.address,
         city: data?.city,
      };

      try {
         setLoading(true);
         const serverResponse = await commonApi.updateAdminUser(
            formData,
            false
         );

         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.error(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   const updateProfilePhoto = async () => {
      const formData = new FormData();
      formData.append(
         "userData",
         JSON.stringify({
            id: userProfileId,
         })
      );
      if (selectedImage) {
         formData.append("profile", selectedImage);
      }
      try {
         setLoading(true);
         const serverResponse = await commonApi.updateAdminUserProfile(
            formData
         );

         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
            setIsEdit(false);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.error(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (!isEdit) {
         userProfileId && getUserData(userProfileId);
      }
   }, [isEdit]);

   useEffect(() => {
      setValue("name", userData?.name);
      setValue("userId", userData?.userId);
      setValue("departmentId", userData?.departmentId?.name);
      setValue("designationId", userData?.designationId?.name);
      setValue("mobile", userData?.mobile);
      setValue("city", userData?.city);
      setValue("email", userData?.email);
      setValue("address", userData?.address);
      setSelectedImage(userData?.profile);
   }, [userData]);

   return (
      <div>
         {(loading || userDataLoading) && <Loader />}
         <div className="mainWrapper common_shadow">
            <div>
               <div className="ProfileImgWrapper">
                  <img
                     alt={"profile"}
                     src={
                        typeof selectedImage === "object" && selectedImage
                           ? URL.createObjectURL(selectedImage)
                           : selectedImage
                           ? `${getServerUrl()}/getFiles/${selectedImage}`
                           : defaultProfile
                     }
                  />
                  <div
                     onClick={handleWrapperClick}
                     style={{
                        pointerEvents: !isEdit && "none",
                        opacity: !isEdit && "60%",
                        color: !isEdit && "gray",
                     }}
                  >
                     <IoCameraReverse />
                     <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                     />
                  </div>
               </div>
               <RoundedbuttonWithIcon
                  text={isEdit ? "Cancel Edit" : "Edit"}
                  icon={svgIcons.editIcon}
                  onClick={() => setIsEdit((prev) => !prev)}
               />
            </div>
            <div>
               <InputWithLabel
                  label={true}
                  labelText={"User Id*"}
                  placeholder={"User Id*"}
                  disabled={true}
                  register={{
                     ...register("userId", {
                        required: "User Id is required",
                     }),
                  }}
                  errorMessage={errors.userId}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Name*"}
                  placeholder={"Name*"}
                  disabled={isEdit ? false : true}
                  register={{
                     ...register("name", {
                        required: "Name is required",
                     }),
                  }}
                  errorMessage={errors.name}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Designation"}
                  placeholder={"Designation"}
                  disabled={true}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Department"}
                  placeholder={"Department"}
                  disabled={true}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Mobile*"}
                  placeholder={"Mobile*"}
                  disabled={isEdit ? false : true}
                  register={{
                     ...register("mobile", {
                        required: "Mobile is required",
                     }),
                  }}
                  errorMessage={errors.mobile}
               />
               <InputWithLabel
                  label={true}
                  labelText={"City*"}
                  placeholder={"City*"}
                  disabled={isEdit ? false : true}
                  register={{
                     ...register("city", {
                        required: "City is required",
                     }),
                  }}
                  errorMessage={errors.city}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Email Id"}
                  placeholder={"Email Id"}
                  disabled={isEdit ? false : true}
                  register={{
                     ...register("email", {
                        required: "Email Id is required",
                     }),
                  }}
                  errorMessage={errors.email}
               />
            </div>
            <div>
               <TextArea
                  label={"Address*"}
                  placeholder={"Address*"}
                  disabled={isEdit ? false : true}
                  register={{
                     ...register("address", {
                        required: "Address is required",
                     }),
                  }}
                  errorMessage={errors.address}
               />
            </div>
            <div>
               <Button
                  text={"Submit"}
                  disabled={isEdit ? false : true}
                  onClick={async () => {
                     await handleSubmit(updateProfile)();
                     updateProfilePhoto();
                  }}
               />
            </div>
         </div>
      </div>
   );
}
