// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptionWrapper{
    gap: 20px;
    margin-top: 20px;
}

.announcementMainWrapper{
    width: 80%;
    margin: auto;
}

.inputWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .uploadFileWrapper {
    width: 50%;
    margin-top: 20px;
  }
  /* ---------------------- medea query ------------------------- */

@media (max-width: 1000px){
    .announcementMainWrapper{
        width: 100%;
    }
    .announcementMainWrapper h3{
        font-size: 22px;
    }
}

@media (max-width: 800px){
    .uploadFileWrapper {
        width: 100%;
      }
}`, "",{"version":3,"sources":["webpack://./src/components/announcements/announcements.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;EACX;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB;EACA,iEAAiE;;AAEnE;IACI;QACI,WAAW;IACf;IACA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,WAAW;MACb;AACN","sourcesContent":[".descriptionWrapper{\n    gap: 20px;\n    margin-top: 20px;\n}\n\n.announcementMainWrapper{\n    width: 80%;\n    margin: auto;\n}\n\n.inputWrapper {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 20px;\n  }\n\n  .uploadFileWrapper {\n    width: 50%;\n    margin-top: 20px;\n  }\n  /* ---------------------- medea query ------------------------- */\n\n@media (max-width: 1000px){\n    .announcementMainWrapper{\n        width: 100%;\n    }\n    .announcementMainWrapper h3{\n        font-size: 22px;\n    }\n}\n\n@media (max-width: 800px){\n    .uploadFileWrapper {\n        width: 100%;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
