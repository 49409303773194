import RoleAccessMain from "../components/roleaccess/RoleAccessMain"

const RoleAccess = () => {
  return (
    <>
        <RoleAccessMain />
    </>
  )
}

export default RoleAccess