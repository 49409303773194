import { useState } from "react";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import "./NavigationButtonWithDropdown.css";
import { isEven } from "../../constants/Helper/NumbersFunction";
import EditAndToggleBtn from "./EditAndToggleBtn";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

const NavigationButtonWithDropdown = ({
   departmentId,
   departmentName,
   users,
   handleDropdown,
   isOpen,
   handleUserIdChange,
   userId,
}) => {
  
   return (
      <div className="w-100 mx-auto NavigationButtonWithDropdown mb-2 text-capitalize">
         <div
            className="w-100 d-flex align-items-center justify-content-between p-2 bg_medium_light_green cursor_pointer"
            onClick={handleDropdown}
         >
            <span>{departmentName}</span>
            {users?.length > 0 && (
               <span className="icons cursor_pointer">
                  {isOpen ? svgIcons.upArrowIcon : svgIcons.downArrowIcon}
               </span>
            )}
         </div>

         <div
            className={`w-100 dropdown ${
               isOpen ? "activeDropdown" : "closeDropdown"
            }`}
         >
            {users?.map((user, i) => {
              return  <div
              className={`w-100 p-2 border-bottom departmentUserListBtn ${
                 isEven(i + 1) ? "Table_Data" : ""
              } ${userId == user?._id && "departmentUserListBtnSelected"}`}
              key={user?._id}
              onClick={() => handleUserIdChange(user?._id)}
           >
              <span>{user?.name}</span>
           </div>
            }
            )}
         </div>
      </div>
   );
};

export default NavigationButtonWithDropdown;

export const ZonalNavigationButtonWithDropdown = ({
   zoneData,
   handleDropdown,
   isOpen = false,
   handleToggle,
   handleEdit,
}) => {
   return (
      <div className="w-100 mx-auto NavigationButtonWithDropdown mb-2 text-capitalize">
         <div
            className={`w-100 d-flex align-items-center justify-content-between p-lg-3 p-2 ${zoneData?.isActive ? 'bg_medium_light_green' : 'bg_light_gray'} cursor_pointer`}
            onClick={handleDropdown}
         >
            <span>{zoneData?.name}</span>
            <span className="icons cursor_pointer">
               {isOpen ? svgIcons.upArrowIcon : svgIcons.downArrowIcon}
            </span>
         </div>

         <div
            className={`w-100 dropdown ${
               isOpen ? "activeDropdown" : "closeDropdown"
            }`}
         >
            <div className="Table_Data p-lg-3 p-2">
               <div className="my-3">
                  <span className="text_color-lable">Zone No.</span> :-{" "}
                  {zoneData?.zoneNo}
               </div>
               <div className="my-3">
                  <span className="text_color-lable">Address</span> :-{" "}
                  {zoneData?.address}
               </div>
               <div className="my-3">
                  <span className="text_color-lable">Total Wards</span> :-{" "}
                  {zoneData?.totalWards}
               </div>
               <div className="my-3">
                  <EditAndToggleBtn
                     isCentered={false}
                     isActive={zoneData?.isActive}
                     handleClick1={handleEdit} // Pass ID to the handler
                     handleClick2={handleToggle} // Pass ID to the handler
                     disabled={
                        checkUserTabAccess("Zone_Ward")?.access !== "write"
                     }
                  />
               </div>
            </div>
         </div>
      </div>
   );
};
