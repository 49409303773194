import { Marker } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { clickParticularVehicle } from "../../store/actions/liveTracking";

const UserMarker = ({ location }) => {
  const dispatch = useDispatch();
  const blackMarkerIcon = {
    url: require("./../../assets/icons/truck.png"), // Replace with your icon URL
    fillColor: "#EB00FF",
    scale: 0.5,
  };

  return (
    <>
      <Marker
        position={{
          lat: location?.latitude,
          lng: location?.longitude,
        }}
        onClick={(e) =>
          console.log(
            "e is",
            dispatch(
              clickParticularVehicle({
                zoneId: location?.zoneId,
                trackId: location?.trackId,
              })
            )
          )
        }
        icon={blackMarkerIcon}
      />
    </>
  );
};

export default UserMarker;
