import { useForm } from "react-hook-form";
import { InputWithLabel } from "../../../commanComponents/inputs/InputWithLabel";
import { Heading_6 } from "../../../commanComponents/NavigationBar/Typography";
import TextArea from "../../../commanComponents/inputs/TextArea";
import Select from "react-select";
import RadioButton from "../../../commanComponents/buttons/RadioButtons";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import FileUpload from "../../../commanComponents/fileUpload/FileUpload";
import { RoundedBtn } from "../../../commanComponents/buttons/SubmitBtns";
import { useNavigate, useSearchParams } from "react-router-dom";
import { commonApi } from "../../../constants/apis/adminCommunication";
import {
   convertToDateInputFormat,
   ImageBaseUrl,
   validateAadhaar,
} from "../../../constants/Helper/Helpers";
import { useQuery } from "react-query";
import Loader from "../../../commanComponents/Loader";
import { checkUserTabAccess } from "../../../constants/Helper/checkUserTabAccess";
import { userRole } from "../../../constants/Helper/getUserRole";

const UserCrudMain = () => {
   const MAX_FILE_SIZE = 2 * 1024 * 1024;
   const [departmentWiseUser, setDepartmentWiseUser] = useState([]);
   const [selectedDepartment, setSelectedDepartment] = useState("");
   const [designations, setDesignation] = useState([]);
   const [isAadharChanged, setIsAadharChanged] = useState(false);
   const [isPanChanged, setIsPanChanged] = useState(false);
   const [selectedDesignation, setSelectedDesignation] = useState("");
   const [ZoneList, setZoneList] = useState([]);
   const [selectedZone, setSelectedZone] = useState([]);
   const [zoneLoading, setZoneLoading] = useState(false);
   const [designetionLoading, setDesignationLoading] = useState(false);
   const [loading, setLoading] = useState(false);
   const [selectedFiles, setSelectedFiles] = useState({});
   const [selectedpan, setSelectedpan] = useState({});
   const [isFileChanged, setIsFileChanged] = useState(false);
   const [docsFromBackend, setDocsFromBackend] = useState([]);
   const navigation = useNavigate();
   const [params] = useSearchParams();
   const userId = params.get("userId");

   const {
      register,
      handleSubmit,
      setValue,
      watch,
      formState: { errors },
   } = useForm();

   const getDepartmentWiseUser = async () => {
      try {
         const response = await commonApi.getActiveDepartmentList("");
         if (response?.status === "SUCCESS") {
            const options = response.result.map((department) => ({
               value: department._id,
               label: department.name,
            }));
            setDepartmentWiseUser(options);
         } else if (response?.status === "JWT_INVALID") {
            toast.error(`Failed : ${response?.message}`);
         } else {
            toast.error(response?.message);
            setDepartmentWiseUser([]);
            throw new Error(response?.message || "Failed to fetch user data");
         }
      } catch (error) {
         console.error("Error fetching department users:", error);
         setDepartmentWiseUser([]);
      }
   };

   const getDesignationByDepartment = async (id) => {
      try {
         setDesignationLoading(true);
         const response = await commonApi.getDesignationListByDepartment(id);
         if (response?.status === "SUCCESS") {
            const options = response.result.map((designation) => ({
               value: designation._id,
               label: designation.name,
            }));
            setDesignation(options);
         } else if (response?.status === "JWT_INVALID") {
            toast.error(`Failed : ${response?.message}`);
         } else {
            setDesignation([]);
         }
      } catch (error) {
         console.error("Error fetching department users:", error);
         setDesignation([]);
      } finally {
         setDesignationLoading(false);
      }
   };

   const getActiveZoneList = async () => {
      setZoneLoading(true);
      try {
         const response = await commonApi.getActiveZoneList();
         if (response?.status === "SUCCESS") {
            const options = response.result.map((zone) => ({
               value: zone._id,
               label: zone.name,
            }));
            setZoneLoading(false);

            setZoneList(options);
         } else if (response?.status === "JWT_INVALID") {
            toast.error(`Failed : ${response?.message}`);
            setZoneLoading(false);
         } else {
            toast.error(response?.message);
            setZoneList([]);
            setZoneLoading(false);

            throw new Error(response?.message || "Failed to fetch Zones");
         }
      } catch (error) {
         console.error("Error fetching fetch Zones:", error);
         setZoneList([]);
         setZoneLoading(false);
      }
   };

   const AddUser = async (data) => {
      if (selectedFiles.length === 0) {
         toast.error("Please upload a document before submitting.");
         return;
      }

      const zoneIds = selectedZone.map((zone) => zone.value);
      const formData = new FormData();

      const updatedData = {
         ...data,
         departmentId: selectedDepartment?.value,
         designationId: selectedDesignation?.value,
         zoneId: zoneIds,
         userType: "department",
      };

      formData.append("aadhar", selectedFiles);
      if (selectedpan) {
         formData.append("pancard", selectedpan);
      }
      formData.append("userData", JSON.stringify(updatedData));
      setLoading(true);
      try {
         const response = await commonApi.createUser(formData, true);
         if (response?.status === "SUCCESS") {
            toast.success(`Success: ${response?.message}`);
            navigation("/user-management");
         } else {
            toast.error(`Failed: ${response?.message}`);
         }
      } catch (error) {
         console.error("Error creating user:", error);
         toast.error("Failed to create user.");
      } finally {
         setLoading(false);
      }
   };

   const updateUser = async (data) => {
      if (selectedFiles?.length === 0) {
         toast.error("Please upload a document before submitting.");
         return;
      }
      const zoneIds = selectedZone.map((zone) => zone.value);
      const formData = new FormData();
      let oldDocs = docsFromBackend;

      if (isAadharChanged) {
         const index = oldDocs.findIndex((doc) => doc.fieldName === "aadhar");
         if (index !== -1) {
            // Remove the object from the array
            oldDocs.splice(index, 1);
         }

         formData.append("aadhar", selectedFiles);
      }
      if (selectedpan && isPanChanged) {
         const index = oldDocs.findIndex((doc) => doc.fieldName === "pancard");
         if (index !== -1) {
            // Remove the object from the array
            oldDocs.splice(index, 1);
         }

         formData.append("pancard", selectedpan);
      }

      const updatedData = {
         id: userId,
         ...data,
         departmentId: selectedDepartment?.value,
         designationId: selectedDesignation?.value,
         zoneId: zoneIds,
         userType: "department",
         documents: oldDocs,
      };

      formData.append("userData", JSON.stringify(updatedData));
      setLoading(true);
      try {
         const response = await commonApi.updateAdminUser(
            isFileChanged ? formData : JSON.stringify(updatedData),
            isFileChanged
         );
         if (response?.status === "SUCCESS") {
            toast.success(`Success: ${response?.message}`);
            navigation("/user-management");
         } else {
            toast.error(`Failed: ${response?.message}`);
         }
      } catch (error) {
         console.error("Error creating user:", error);
         toast.error("Failed to create user.");
      } finally {
         setLoading(false);
      }
   };

   const fetchUserProfileData = async () => {
      if (!userId) return;
      setLoading(true);
      try {
         const data = await commonApi.getUserProfileData(userId);

         if (data?.status === "SUCCESS") {
            // Setting form values
            setValue("name", data?.user?.name);
            setValue("userId", data?.user?.userId);
            setValue("mobile", data?.user?.mobile);
            setValue("city", data?.user?.city);
            setValue("email", data?.user?.email);
            setValue("address", data?.user?.address);
            setValue("gender", data?.user?.gender);
            setValue("dob", convertToDateInputFormat(data?.user?.dob));
            setValue("aadharNo", data?.user?.aadharNo);

            // Set department and designation
            setSelectedDepartment({
               value: data?.user?.departmentId?._id,
               label: data?.user?.departmentId?.name,
            });
            setDocsFromBackend(data?.user?.documents);
            setSelectedDesignation({
               value: data?.user?.designationId?._id,
               label: data?.user?.designationId?.name,
            });

            // Preselect zones
            const selectedZones = data?.user?.zoneId?.map((zone) => ({
               value: zone?._id,
               label: zone?.name,
            }));
            setSelectedZone(selectedZones);

            // Set documents
            data?.user?.documents?.forEach((document) => {
               if (document.fieldName === "aadhar") {
                  setSelectedFiles({
                     name: document.documentName,
                     url: document.fileUrl,
                  });
               } else if (document.fieldName === "pancard") {
                  setSelectedpan({
                     name: document.documentName,
                     url: document.fileUrl,
                  });
               }
            });

            // Fetch designations based on department
            getDesignationByDepartment(data?.user?.departmentId?._id);
         } else if (data?.status === "JWT_INVALID") {
            toast.error(`Failed: ${data?.message}`);
         } else {
            toast.error(`Failed: ${data?.message}`);
         }
      } catch (error) {
         toast.error(`Failed: ${error?.message}`);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (userId) {
         fetchUserProfileData();
      }
   }, [userId]);

   useEffect(() => {
      setValue("gender", "male");
      getDepartmentWiseUser();
      getActiveZoneList();
   }, []);

   const handleFileSelection = (files) => {
      const selectedFile = files[0];
      if (files) {
         if (files[0]?.size > MAX_FILE_SIZE) {
            toast.error("File size should not exceed 2MB");
            setSelectedFiles([]);
            return;
         }
         setIsFileChanged(true);
         setIsAadharChanged(true);
         setSelectedFiles(selectedFile);
      } else {
         setIsFileChanged(false);
         setSelectedFiles([]);
      }
   };
   const handlePanSelection = (files) => {
      const selectedFile = files[0];
      if (files) {
         if (files[0]?.size > MAX_FILE_SIZE) {
            toast.error("File size should not exceed 2MB");
            setSelectedpan([]);
            return;
         }
         setIsFileChanged(true);
         setIsPanChanged(true);
         setSelectedpan(selectedFile);
      } else {
         setIsFileChanged(false);
         setSelectedpan([]);
      }
   };

   useEffect(() => {
      getDesignationByDepartment(selectedDepartment?.value);
   }, [selectedDepartment]);

   useEffect(() => {
      if (!checkUserTabAccess("User_Management") || userRole() == 'zonal') {
         toast.info("You do not have permission to access User Management");
         navigation(-1); // redirect to previous page if user dont not have Tab Access
         return;
      }
   }, []);

   return (
      <>
         {loading && <Loader />}
         <div className="w-100 mx-auto UserCrudMain pb-2">
            <div className="p-4 rounded-lg bg-white big_Shadow my-2">
               <Heading_6 text="Department & Designation" />
               <div className="form-row">
                  <div className="col-md-4 p-2">
                     <label className="name text_color-lable lable">
                        Department*
                     </label>
                     <Select
                        options={departmentWiseUser}
                        classNamePrefix="select"
                        onChange={(selected) => {
                           setDesignation([]);
                           setSelectedDepartment(selected);
                           setSelectedDesignation("");
                        }}
                        value={selectedDepartment ? selectedDepartment : ""}
                        placeholder="Select a Department..."
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <label className="name text_color-lable">
                        Designation*
                     </label>
                     <Select
                        options={designations}
                        isLoading={designetionLoading}
                        onChange={(selected) => {
                           setSelectedDesignation(selected);
                        }}
                        value={selectedDesignation ? selectedDesignation : ""}
                        placeholder="Select a designation"
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <label className="name text_color-lable">Zone*</label>
                     <Select
                        options={ZoneList}
                        isLoading={zoneLoading}
                        onChange={(selected) => {
                           setSelectedZone(selected);
                        }}
                        isMulti={true}
                        value={selectedZone ? selectedZone : ""}
                        placeholder="Select a Zone"
                     />
                  </div>
               </div>
            </div>

            <div className="p-4 rounded-lg bg-white big_Shadow my-2">
               <Heading_6 text=" User Information" />
               <div className="form-row">
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"Full Name*"}
                        placeholder={"Full Name"}
                        register={{
                           ...register("name", {
                              required: "Full Name is required",
                           }),
                        }}
                        errorMessage={errors?.name}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"Mobile*"}
                        placeholder={"Enter Mobile Number"}
                        register={{
                           ...register("mobile", {
                              required: "Mobile Number is required",
                           }),
                        }}
                        errorMessage={errors?.mobile}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <label className="name text_color-lable">Gender*</label>
                     <div className="w-100 d-flex flex-wrap mx-auto">
                        <div className="px-2">
                           <RadioButton
                              name="gender"
                              id="male"
                              label="Male"
                              value="male"
                              onChange={() => setValue("gender", "male")}
                              checked={
                                 watch("gender") === "male" ? true : false
                              }
                              register={{
                                 ...register("gender", {
                                    required: "Gender is required",
                                 }),
                              }}
                              errorMessage={errors?.gender?.message}
                           />
                        </div>
                        <div className="px-2">
                           <RadioButton
                              name="gender"
                              id="female"
                              label="Female"
                              value="female"
                              onChange={() => setValue("gender", "female")}
                              checked={
                                 watch("gender") === "female" ? true : false
                              }
                              register={{
                                 ...register("gender", {
                                    required: "Gender is required",
                                 }),
                              }}
                              errorMessage={errors?.gender?.message}
                           />
                        </div>
                        <div className="px-2">
                           <RadioButton
                              name="gender"
                              id="other"
                              label="Other"
                              value="other"
                              onChange={() => setValue("gender", "other")}
                              checked={
                                 watch("gender") === "other" ? true : false
                              }
                              register={{
                                 ...register("gender", {
                                    required: "Gender is required",
                                 }),
                              }}
                              errorMessage={errors?.gender?.message}
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <div className="form-row">
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"DOB*"}
                        placeholder={"Date of Birth"}
                        type="date"
                        register={{
                           ...register("dob", {
                              required: "DOB is required",
                           }),
                        }}
                        errorMessage={errors?.dob}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"Aadhaar Card*"}
                        placeholder={"Enter Aadhaar Card Number"}
                        register={{
                           ...register("aadharNo", {
                              required: "Aadhaar Number is required",
                              validate: validateAadhaar, // Use custom validation function
                           }),
                        }}
                        errorMessage={errors?.aadharNo}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"City*"}
                        placeholder={"Enter City"}
                        register={{
                           ...register("city", {
                              required: "City is required",
                           }),
                        }}
                        errorMessage={errors?.city}
                     />
                  </div>
               </div>
               <div className="form-row">
                  <div className="col-md-8 p-2">
                     <TextArea
                        label={"Address*"}
                        placeholder={"Enter Address"}
                        // disabled={isEdit ? false : true}
                        rows={4}
                        register={{
                           ...register("address", {
                              required: "Address is required",
                           }),
                        }}
                        errorMessage={errors.address}
                     />
                  </div>
               </div>
            </div>

            <div className="p-4 rounded-lg bg-white big_Shadow my-2">
               <div
                  className="d-flex align-items-center flex-wrap mb-2"
                  style={{ gap: "3px" }}
               >
                  <Heading_6 text="Document Upload" className="mb-0" />
                  <span className="text-warning">
                     (jpeg, png, jpeg Are allowed*)
                  </span>
               </div>
               <div className="d-flex" style={{ gap: "10px" }}>
                  <FileUpload
                     selectedFiles={selectedFiles}
                     setSelectedFiles={handleFileSelection}
                     label={"Document Upload"}
                     placeHolder={"Choose Aadhar Card"}
                     slectedFileName={selectedFiles?.name}
                     accept="image/png, image/jpeg, image/jpeg, application/pdf"
                     allowMultiple={false}
                     isLabel={false}
                  />
                  <FileUpload
                     selectedFiles={selectedpan}
                     setSelectedFiles={handlePanSelection}
                     placeHolder={"Choose Pan Card"}
                     slectedFileName={selectedpan?.name}
                     label={"Document Upload"}
                     accept="image/png, image/jpeg, image/jpeg, application/pdf"
                     allowMultiple={false}
                     isLabel={false}
                  />
               </div>
               {userId && (
                  <div className="imgWrapper">
                     {docsFromBackend?.length > 0 ? (
                        docsFromBackend?.map((data) => {
                           return (
                              <div>
                                 <p className="text-capitalize">
                                    {data?.fieldName}
                                 </p>
                                 <img
                                    className="common_shadow "
                                    alt={data?.fieldName || "document"}
                                    src={`${ImageBaseUrl}${data?.fileUrl}`}
                                 />
                              </div>
                           );
                        })
                     ) : (
                        <p>No Documents Available !</p>
                     )}
                  </div>
               )}
            </div>

            {checkUserTabAccess("User_Management")?.access === "write" && (
               <div className="p-4 rounded-lg my-2">
                  <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper">
                     <RoundedBtn
                        text={userId ? "Update User" : "Add User"}
                        className="bg_primary text-white px-4 mr-4"
                        type={"submit"}
                        onClick={handleSubmit(userId ? updateUser : AddUser)}
                     />
                     <RoundedBtn
                        text="Cancel"
                        className="bg-secondary text-white px-4"
                        onClick={() => {
                           navigation(-1);
                        }}
                     />
                  </div>
               </div>
            )}
         </div>
      </>
   );
};

export default UserCrudMain;
