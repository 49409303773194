import NotificationMain from "../components/Notification/NotificationMain"

const Notification = () => {
  return (
    <>
    <NotificationMain />
    </>
  )
}

export default Notification