import React from 'react';
import Cookies from "js-cookie";
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
  // Retrieve and parse the cookie value
  const cookieValue = Cookies.get('loginToken');
  let token = null;

  if (cookieValue) {
    try {
      const parsedCookie = JSON.parse(cookieValue);
      token = parsedCookie.token;
    } catch (e) {
      console.error('Failed to parse cookie', e);
    }
  }

  // Redirect to login if token is not available
  if (!token) {
    return <Navigate to="/login" />;
  }

  return <div>{children}</div>;
};

export default ProtectedRoutes;
