import React from 'react';
import "./Loader.css";
import loading from './../assets/icons/loading.gif';

const Loader = () => {
  return (
    <div className='loader-container'>
      <div className='customLoader'>
        <img src={loading} alt="Loading..." />
      </div>
   </div>
  );
}

export default Loader;
