import React, { createContext, useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { commonApi } from '../apis/adminCommunication';
import { toast } from 'react-toastify';

export const UserDataContext = createContext();

export const GetUserDataProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [userProfileId, setUserProfileId] = useState(null);

  const getTokenFromCookies = () => {
    const cookieValue = Cookies.get("loginToken") && JSON.parse(Cookies.get("loginToken"));
    setToken(cookieValue?.token); 
    setUserProfileId(cookieValue?.userDetails?._id); 
  };

  const getUserData = async (id) => {
    try {
      setUserDataLoading(true);
      const data = await commonApi?.getUserProfileData(id);
      if (data?.status === "SUCCESS") {
        setUserData(data?.user);
      } else {
        toast.error(`Failed: ${data?.message}`);
      }
    } catch (error) {
      toast.error(`Failed: ${error?.message}`);
    } finally {
      setUserDataLoading(false);
    }
  };

  useEffect(() => {
    if (userProfileId && token) { 
      getUserData(userProfileId);
    }
  }, [token, userProfileId]); 

  useEffect(() => {
    getTokenFromCookies(); 
  }, []);

  const refreshUserData = () => {
    getTokenFromCookies();
  };

  return (
    <UserDataContext.Provider value={{ userData, userDataLoading, getUserData, refreshUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};