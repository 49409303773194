import React from "react";
import "./FileUpload.css";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { PiX } from "react-icons/pi";

function FileUpload({
   selectedFiles,
   slectedFileName,
   disabled,
   setSelectedFiles,
   style = { width: "200px" },
   label,
   allowMultiple = false,
   isLabel = true,
   accept = "image/png, image/jpeg, image/jpeg",
   placeHolder = "Choose Files",
}) {
   const handleFileSelect = (event) => {
      const filesArray = Array.from(event.target.files);
      if (allowMultiple) {
         setSelectedFiles([...selectedFiles, ...filesArray]); 
      } else {
         setSelectedFiles([event.target.files[0]]); 
      }
   };
   
   const handleFileDrop = (event) => {
      event.preventDefault();
      const filesArray = Array.from(event.dataTransfer.files); 
      if (allowMultiple) {
         setSelectedFiles([...selectedFiles, ...filesArray]);
      } else {
         setSelectedFiles([filesArray[0]])
      }
   };

   const handleDragOver = (event) => {
      event.preventDefault();
   };

   return (
      <div style={style} className="h-auto" title={'File Upload'}>
         {isLabel && <label className="fileUploadLable">{label}</label>}
         <div
            className={`file-upload-container ${
               disabled && "disabled-file-upload-container"
            }`}
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
         >
            <label className="file-input-label">
               <input
                  type="file"
                  multiple={allowMultiple}
                  className="file-input"
                  onChange={handleFileSelect}
                  accept={accept}
               />
               <div>{svgIcons.uploadIcon}</div>
               <p
                  style={{ fontSize: "10px", margin: "10px 0px" }}
                  className="text_lime_green"
               >
                  {selectedFiles?.length >0 ? `${selectedFiles?.length} Files` :  slectedFileName ? slectedFileName : selectedFiles?.fileName}
               </p>
               <p>{placeHolder}</p>
            </label>
         </div>
      </div>
   );
}

export default FileUpload;
