import React from "react";
import BigTableLayout from "../../../commanComponents/CommonLayout/BigTableLayout";
import { isEven } from "../../../constants/Helper/NumbersFunction";
import {
  convertToDateInputFormat,
  formatToAmPm,
  PaginationSrNo,
} from "../../../constants/Helper/Helpers";

const GarbageCollectionTable = ({ data, page = 1 }) => {
  return (
    <BigTableLayout isShadow={false}>
      <table className="table w-100 overflow-auto">
        <thead
          className="w-100  border-0"
          style={{
            position: "sticky",
            top: "0",
            right: "0",
            zIndex: "1",
          }}
        >
          <tr className="bg_Table_Header">
            <th scope="col">Sr. No</th>
            <th scope="col">Date</th>
            <th scope="col">Time</th>

            <th scope="col" className="col_250px">
              Garbage Type
            </th>
          </tr>
          <tr className="py-4">
            <th scope="col" className="text-capitalize py-3" colSpan={3}></th>
            <th scope="col" className="text-capitalize p-0 position-relative">
              <div className="d-flex garbage_type">
                <span className="bg_Table_Header py-2 px-4 col_100px">Dry</span>
                <span className="bg_Table_Header py-2 px-4 col_100px">Wet</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => (
            <tr key={item._id || i}>
              <th
                scope="row"
                className={`text-center ${isEven(i + 1) && "Table_Data"}`}
              >
                {PaginationSrNo(page, i)}
              </th>
              <td
                className={`text-capitalize ${isEven(i + 1) && "Table_Data"}`}
              >
                {convertToDateInputFormat(item?.createdAt) || "--"}
              </td>
              <td
                className={`text-capitalize ${isEven(i + 1) && "Table_Data"}`}
              >
                {formatToAmPm(item?.createdAt) || "--"}
              </td>
              <td
                className={`text-capitalize ${isEven(i + 1) && "Table_Data"}`}
              >
                <div className="d-flex">
                  <span className="px-1 col_100px">
                    {item?.dryWasteCollected} Kg
                  </span>
                  <span className="px-1 col_100px">
                    {item?.wetWasteCollected} Lr
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </BigTableLayout>
  );
};

export default GarbageCollectionTable;
