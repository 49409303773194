import React, { useEffect, useState } from "react";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { ToggleButton } from "../../commanComponents/buttons/ToggleButtons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import { useNavigate } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import useDebounce from "../../constants/useDebounce";
import { isEven } from "../../constants/Helper/NumbersFunction";
import Loader from "../../commanComponents/Loader";
import { PaginationSrNo } from "../../constants/Helper/Helpers";
import { userRole } from "../../constants/Helper/getUserRole";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

function WastInfoList() {
   const navigate = useNavigate();
   const [page, setPage] = useState(1);
   const [searchString, setSearchString] = useState("");
   const [pageUpdated, setPageUpdated] = useState("");
   const [westInfo, setWestInfo] = useState([]);
   const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);

   const { data, isFetching, error } = useQuery(
      ["wasteInfo", page, debouncedSearchTerm, pageUpdated],
      () => commonApi?.getWestInfoList(page, searchString),
      {
         refetchOnWindowFocus: false,
         onSuccess: (data) => {
            if (data?.status == "SUCCESS") {
               setWestInfo(data?.result);
            } else if (data?.status == "JWT_INVALID") {
               toast.error(`Failed : ${data?.message}`);
            }
            if (data?.status == "FAILED") {
               setWestInfo([]);
            }
         },
         onError: (error) => {
            toast.error(`Failed : ${error?.message}`);
         },
      }
   );

   useEffect(() => {
      if (!checkUserTabAccess("Garbage_Info") && userRole() !== "zonal") {
         toast.info("You do not have permission to access Garbage Info");
         navigate(-1); // redirect to previous page if user dont not have Tab Access
         return;
      }
   }, []);

   return (
      <div>
         {isFetching && <Loader />}
         <div className="search_btn_wrapper">
            <div className="w-200">
               <Searchbox
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search Garbege Info..."
               />
            </div>
            <div className="buttons_wrapper">
               <RoundedbuttonWithIcon
                  text={"Add Garbege Info"}
                  textClasses="text-uppercase"
                  icon={svgIcons.PlusIcon}
                  onClick={() => {
                     navigate("/garbage-info/garbage-info-management?type=add");
                  }}
               />
            </div>
         </div>
         <div className="tableWrapper big_Shadow">
            <div
               className="table-responsive position-relative tablemain"
               style={{ overflowY: "auto", height: "58vh" }}
            >
               <table className="table position-relative w-100">
                  <thead
                     className="rounded-pill w-100 bg_Table_Header border-0"
                     style={{ position: "sticky", top: "-0.04px", zIndex: "1" }}
                  >
                     <tr>
                        <th scope="col" className="col_100px">
                           Sr. No
                        </th>
                        <th scope="col" className="col_200px">
                           Garbage Type
                        </th>
                        <th scope="col" className="col_150px">
                           Unit
                        </th>
                        <th scope="col" className="col_250px">
                           Per Person Expected garbage daily
                        </th>
                        <th
                           scope="col"
                           className="col_150px text-center row-fix-to-last table-th-bg-color"
                        >
                           Action
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {westInfo?.length > 0 &&
                        westInfo?.map((data, index) => {
                           return (
                              <tr
                                 className={`${
                                    isEven(index + 1) && "Table_Data"
                                 }`}
                              >
                                 <th scope="row">
                                    {" "}
                                    {PaginationSrNo(page, index)}
                                 </th>
                                 <td className="text-capitalize">
                                    {data?.wasteType ? data?.wasteType : "--"}
                                 </td>
                                 <td className="text-capitalize">
                                    {data?.unit ? data?.unit : "--"}
                                 </td>
                                 <td className="text-capitalize">
                                    {data?.perPersonExpectedWaste
                                       ? data?.perPersonExpectedWaste
                                       : "--"}
                                 </td>
                                 <td className="col_150px row-fix-to-last text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                       <div
                                          className="mr-2 cursor_pointer"
                                          title="View"
                                          onClick={() => {
                                             navigate(
                                                `/garbage-info/garbage-info-management?type=view&westInfoId=${data?._id}`
                                             );
                                          }}
                                       >
                                          {svgIcons.eyeIcon}
                                       </div>
                                       <div
                                          className="mr-2 cursor_pointer"
                                          title="Edit"
                                          onClick={() => {
                                             navigate(
                                                `/garbage-info/garbage-info-management?type=update&westInfoId=${data?._id}`
                                             );
                                          }}
                                       >
                                          {svgIcons.editIcon}
                                       </div>
                                    </div>
                                 </td>
                              </tr>
                           );
                        })}
                  </tbody>
               </table>
               {westInfo?.length == 0 && (
                  <h6 className="DataNotFoundText">No Waste Info Found...</h6>
               )}
            </div>
         </div>

         <div className="mt-2">
            <PaginationButton
               page={page}
               setPage={setPage}
               totalPages={data?.totalPages || 1}
            />
         </div>
      </div>
   );
}

export default WastInfoList;
