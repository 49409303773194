"use client";
import React from "react";
import { MdArrowForwardIos, MdOutlineArrowBackIosNew } from "react-icons/md";
import "./PaginationBtn.css";

export default function PaginationButton({
  page = 1,
  setPage,
  totalPages = 1,
}) {
  return (
    <div className="paginationButton">
      <button
        disabled={page === 1}
        className="d-flex align-items-center"
        onClick={() => setPage(page - 1)}
        title="Previous Page"
      >
        <MdOutlineArrowBackIosNew />
      </button>
      <button title="Current Page">{page}</button>
      <div title="Total Pages">
        {totalPages
          ? `of ${totalPages} Page${totalPages > 1 ? "s" : ""}`
          : "No Pages"}
      </div>

      <button
        disabled={page === totalPages || totalPages === 0}
        onClick={() => setPage(page + 1)}
        className="d-flex align-items-center"
        title="Next Page"
      >
        <MdArrowForwardIos />
      </button>
    </div>
  );
}
