import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { ToggleButton } from "./ToggleButtons";

const EditAndToggleBtn = ({
   isActive,
   handleClick1,
   handleClick2,
   isCentered = true,
   disabled
}) => {
   return (
      <div
         className={`d-flex align-items-center ${
            isCentered && "justify-content-center"
         }`}
      >
         {isActive && (
            <div className="mr-2 cursor_pointer" onClick={handleClick1} title="Edit">
               {svgIcons.editIcon}
            </div>
         )}
         <ToggleButton isActive={isActive} onChange={handleClick2} disabled={disabled} />
      </div>
   );
};

export default EditAndToggleBtn;
