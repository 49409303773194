export const userType = [
    {
        label: "Driver",
        value: "driver"
    },
    {
        label: "Contractor",
        value: "contractor"
    },
    {
        label: "Zonal",
        value: "zonal"
    },
    {
        label: "Collector",
        value: "collector"
    },
    {
        label: "Citizen User",
        value: "citizenuser"
    },
    
]

export const GarbageUnits = [
    {
        label: "Kg",
        value: "kg"
    },
    {
        label: "Liter",
        value: "ltr"
    }
]


export const roleAccessData = [
    { name: "Manage Departments", key: "Manage_Departments" },
    { name: "User Management", key: "User_Management" },
    { name: "Role Access", key: "Role_Access" },
    { name: "Garbage Info", key: "Garbage_Info" },
    { name: "Garbage Collection", key: "Garbage_Collection" },
    { name: "Zone & Ward", key: "Zone_Ward" },
    { name: "Live Tracking", key: "Live_Tracking" },
    { name: "Grievance", key: "Grievance" },
    { name: "Survey Detail", key: "Survey_Detail" },
    { name: "Dump Yard Manage", key: "Dump_Yard_Manage" },
    { name: "Announcements", key: "Announcements" },
  ];
