import React from "react";
import "./RadioButton.css"; // Import the custom CSS file

const RadioButton = ({
  name,
  id,
  label,
  checked,
  onChange,
  className,
  value,
  onClick,
  register,
}) => {
  return (
    <div
      className={`form-check d-flex align-items-center radio_btn cursor_pointer ${className}`}
      title={value}
    >
      <input
        className="form-check-input custom-radio mt-0 cursor_pointer"
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
        register={register}
      />
      <label className="form-check-label cursor_pointer" htmlFor={id} title={name}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
