import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { RoundedBtn } from "../buttons/SubmitBtns";
import { DropdownInput, InputWithLabel } from "../inputs/InputWithLabel";
import "./Forms.css";
import { commonApi } from "../../constants/apis/adminCommunication";
import { useNavigate } from "react-router-dom";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

export const DesignationForm = ({ refetch, onClose, designationId }) => {
   const [departmentName, setDepartmentName] = useState("");
   const [selectedDepartment, setSelectedDepartment] = useState(null);
   const [error, setError] = useState("");
   const [departmentError, setDepartmentError] = useState("");
   const route = useNavigate();

   // Fetch departments for dropdown options
   const {
      data: departmentsData,
      isError: isDepartmentsError,
      error: departmentsError,
   } = useQuery(
      "DesignationFormdepartments",
      () => commonApi.getActiveDepartmentList(),
      {
         refetchOnWindowFocus: false,
         onSuccess: (data) => {
            if (data?.status !== "SUCCESS") {
               toast.error(data?.message || "Failed to load departments");
            }
         },
         onError: (error) => {
            toast.error(`Error fetching departments: ${error.message}`);
         },
      }
   );

   // Mutation for creating or updating a designation
   const mutation = useMutation(
      (data) =>
         designationId
            ? commonApi.updateDesignation(
                 designationId,
                 data.name,
                 data.departmentId
              )
            : commonApi.createDesignation(data.name, data.departmentId),
      {
         onSuccess: (data) => {
            if (data?.status === "SUCCESS") {
               toast.success(
                  designationId
                     ? "Designation updated successfully!"
                     : "Designation created successfully!"
               );
               setDepartmentName("");
               setSelectedDepartment(null);
               onClose();
               refetch();
            } else if (data?.status === "JWT_INVALID") {
               toast.error(`Failed: ${data?.message}`);
            } else {
               toast.error(
                  data?.message ||
                     `Designation Not ${designationId ? "Updated" : "Created"}!`
               );
            }
         },
         onError: (error) => {
            toast.error(
               `Error ${designationId ? "updating" : "creating"} designation: ${
                  error.message
               }`
            );
         },
      }
   );

   const getDesignationById = async () => {
      try {
         const res = await commonApi.getDesignationById(designationId);
         if (res?.status === "SUCCESS") {
            const department = departmentsData?.result.find(
               (dept) => dept._id === res?.designation?.departmentId
            );
            if (department) {
               setSelectedDepartment({
                  value: department._id,
                  label: department.name,
               });
            }
            setDepartmentName(res?.designation?.name);
         } else if (res?.status === "JWT_INVALID") {
            toast.error(res?.message || "Please login and try again!");
            route("/login");
         } else {
            toast.error(res?.message || "Something Went Wrong!");
         }
      } catch (error) {
         toast.error(error?.message || "Something Went Wrong!");
      }
   };

   const handleDepartmentChange = (option) => {
      setSelectedDepartment(option);
   };

   const handleChange = (e) => {
      setDepartmentName(e.target.value);
      if (error) {
         setError(""); // Clear error message on typing
      }
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      if (!departmentName || !selectedDepartment) {
         !departmentName && setError("Designation name is required");
         !selectedDepartment && setDepartmentError("Department is required");
         return;
      }
      setError(""); // Clear error
      setDepartmentError(""); // Clear error

      mutation.mutate({
         name: departmentName,
         departmentId: selectedDepartment.value, // Pass the selected department ID
      });
   };

   useEffect(() => {
      if (designationId) {
         getDesignationById();
      }
   }, [designationId, departmentsData]);

   // Convert department data to options format for DropdownInput
   const options =
      departmentsData?.result.map((dept) => ({
         value: dept._id,
         label: dept.name,
      })) || [];

   return (
      <div className="DesignationForm">
         <form>
            <div className="inputwrapper mx-auto w-100 my-4">
               <div style={{ textAlign: "start" }}>
                  <label className="name">Department*</label>
                  <DropdownInput
                     labelText="Select Department*"
                     options={options}
                     placeholder="Select a department*"
                     onChange={(e) => {
                        setDepartmentError('');
                        handleDepartmentChange(e);
                     }}
                     value={selectedDepartment ? selectedDepartment.value : ""}
                     id="department-dropdown"
                     errorMessage={{ message: departmentError }}
                  />
               </div>
            </div>
            <div
               className="inputwrapper mx-auto w-100"
               style={{ textAlign: "start" }}
            >
               <label className="name">Designation Name*</label>
               <InputWithLabel
                  placeholder="Enter Designation Name*"
                  value={departmentName}
                  onChange={handleChange}
                  errorMessage={{ message: error }}
               />
            </div>
            <div className="d-flex align-items-center justify-content-center buttonsWrapper mt-3">
               {checkUserTabAccess("Manage_Departments")?.access == "write" && (
                  <RoundedBtn
                     text={designationId ? "Update" : "Create"}
                     className="bg_primary text-white px-4"
                     type="submit"
                     onClick={handleSubmit}
                     disabled={mutation.isLoading}
                  />
               )}
               <RoundedBtn
                  text="Close"
                  className="bg-secondary text-white px-4"
                  onClick={(e) => {
                     e.preventDefault();
                     onClose();
                  }}
                  disabled={mutation.isLoading}
               />
            </div>
         </form>
      </div>
   );
};
