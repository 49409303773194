import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
   Colors,
} from "chart.js";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { userRole } from "../../constants/Helper/getUserRole";
import Spinner from "../../commanComponents/Spinner";

ChartJS.register(
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend
);

export default function DoubleBarGraph({ title }) {
   const [loading, setLoading] = useState(false);
   const [dryData, setDryData] = useState([]);
   const [wetData, setWetData] = useState([]);
   const [hazardousData, setHazardousData] = useState([]);
   const [users, setUsers] = useState([]);
   const [month, setMonth] = useState("");

   const monthOptions = [
      { label: "January", value: 1 },
      { label: "February", value: 2 },
      { label: "March", value: 3 },
      { label: "April", value: 4 },
      { label: "May", value: 5 },
      { label: "June", value: 6 },
      { label: "July", value: 7 },
      { label: "August", value: 8 },
      { label: "September", value: 9 },
      { label: "October", value: 10 },
      { label: "November", value: 11 },
      { label: "December", value: 12 },
   ];

   const handleCollectionData = (data) => {
      setDryData([]);
      setWetData([]);
      setHazardousData([]);
      setUsers([]);
      data?.length > 0 &&
         data?.map((e) => {
            setUsers((prev) => [...prev, userRole() === "zonal"? e?.userName : e?.zoneName]);
            setWetData((prev) => [...prev, e?.totalWetWaste]);
            setDryData((prev) => [...prev, e?.totalDryWaste]);
            setHazardousData((prev) => [...prev, e?.totalHazardousWaste]);
         });
   };

   const fetchContractorCollection = async (month) => {
      try {
         setLoading(true);
         const serverResponse = await commonApi.getContractorCollection(month);
         if (serverResponse?.status === "SUCCESS") {
            handleCollectionData(serverResponse?.data);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "FAILED") {
            handleCollectionData([]);
          console.log(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed: ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   const fetchZoneWiseCollection = async (month) => {
      try {
         setLoading(true);
         const serverResponse = await commonApi.getZoneWiseCollection(month);
         if (serverResponse?.status === "SUCCESS") {
            handleCollectionData(serverResponse?.zoneWiseWasteCollection);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "FAILED") {
            handleCollectionData([]);
            console.log(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (userRole() === "zonal") {
         fetchContractorCollection(month?.value);
      }
      else{
         fetchZoneWiseCollection(month?.value)
      }
   }, [month]);

   const data = {
      labels: users,
      datasets: [
         {
            label: "Dry (Kg)",
            data: dryData,
            backgroundColor: "rgba(118, 185, 95, 1)",
            borderRadius: 8,
            barThickness: 28,
         },
         {
            label: "Wet (Ltr)",
            data:wetData ,
            backgroundColor: "rgba(13, 110, 253, 1)",
            borderRadius: 8,
            barThickness: 28,
         },
         {
            label: "Hazardous (Kg)",
            data:hazardousData ,
            backgroundColor: "#fc6262",
            borderRadius: 8,
            barThickness: 28,
         },
      ],
   };

   const options = {
      plugins: {
         legend: {
            display: true,
            position: "top",
         },
         legend: {
            labels: {
               usePointStyle: true,
               boxWidth: 10,
               padding: 20,
            },
         },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
         x: {
            stacked: false,
            grid: {
               display: false,
            },
            title: {
               display: true,
               text: title,
               color: "black",
               font: {
                  size: 14,
                  weight: "500",
               },
            },
            categoryPercentage: 0.8,
            barPercentage: 0.9,
            ticks: {
               autoSkip: false,
               maxRotation: 0,
               minRotation: 0,
            },
            barThickness: 28,
         },
         y: {
            beginAtZero: true,
            grid: {
               display: true,
               drawBorder: false,
            },
            title: {
               display: true,
               text: "Kg / Lr",
               color: "black",
               font: {
                  size: 14,
                  weight: "500",
               },
            },
            ticks: {
               maxTicksLimit: 8,
            },
         },
      },
   };

   return (
      <div className="mt-4 doubleBarGraphWrapper big_Shadow">
         <div className="d-flex justify-content-between">
            <div>
               <h5>{title}</h5>
               <p>Garbage Collection</p>
            </div>
            <div className="d-flex align-items-center">
               {loading && <Spinner />}
               <div className="ml-3" title="Filter By Month" style={{width:'150px'}}>
                  <Select
                     options={monthOptions}
                     placeholder="Month"
                     onChange={(month) => {
                        setMonth(month);
                     }}
                     value={month}
                  />
               </div>
            </div>
         </div>
         <div className="chartContainer transparant_Scrollbar">
            <div style={{
                  minWidth: `${users && users?.length * 140}px`, 
                  height:'100%'
               }}>
            <Bar data={data} options={options} />
            </div>
         </div>
      </div>
   );
}
