import React, { useState } from "react";
import BigTableLayout from "../../commanComponents/CommonLayout/BigTableLayout";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import EditAndToggleBtn from "../../commanComponents/buttons/EditAndToggleBtn";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import UserFilter from "./UserFilter";
import { DocumentsShowCard } from "../../commanComponents/Images/ImagesShowCard";
import { useNavigate } from "react-router-dom";
import { isEven } from "../../constants/Helper/NumbersFunction";
import { PaginationSrNo } from "../../constants/Helper/Helpers";

const UserManagementList = ({ users, page, handleToggle }) => {
   const route = useNavigate();

   const [attachment, setAttachment] = useState({
      isPopupOpen: false,
      url: [],
   });

   const handleAttachmentClick = (url) => {
      setAttachment({
         isPopupOpen: true,
         url: url,
      });
   };

   const handlePopupClose = () => {
      setAttachment({
         isPopupOpen: false,
         url: [],
      });
   };

   return (
      <BigTableLayout>
         <table className="table w-100 overflow-auto">
            <thead
               className="w-100 bg_Table_Header border-0"
               style={{ position: "sticky", top: "0", right: "0", zIndex: "1" }}
            >
               <tr>
                  <th className="col_100px">
                     Sr. No
                  </th>
                  <th className="col_150px">User ID</th>
                  <th className="col_200px">Name</th>
                  <th className="col_200px">Department</th>
                  <th className="col_200px">Designation</th>
                  <th className="col_200px">Mobile</th>
                  <th className="col_150px">Gender</th>
                  <th className="col_150px">Age</th>
                  <th className="col_200px">Adhar Card Number</th>
                  <th className="col_150px text-center">Attachment</th>
                  <th className="col_150px text-center row-fix-to-last table-th-bg-color">Action</th>
               </tr>
            </thead>
            <tbody>
               {users?.map((item, i) => (
                  <tr
                     key={item._id || i}
                     className={`${isEven(i + 1) && "Table_Data"}`}
                  >
                     <th scope="row">{PaginationSrNo(page, i)}</th>
                     <td className={`text-capitalize `}>
                        {item?.userId || "--"}
                     </td>
                     <td className={`text-capitalize col_250px`} >
                        {item?.name || "--"}
                     </td>
                     <td className={`text-capitalize `}>
                        {item?.departmentId?.name || "--"}
                     </td>
                     <td className={`text-capitalize `}>
                        {item?.designationId?.name || "--"}
                     </td>
                     <td className={`text-capitalize `}>
                        {item?.mobile || "--"}
                     </td>
                     <td className={`text-capitalize `}>
                        {item?.gender || "--"}
                     </td>
                     <td className={`text-capitalize `}>{item?.age || "--"}</td>
                     <td className={`text-capitalize `}>
                        {item?.aadharNo || "--"}
                     </td>
                     <td className={`text-capitalize cursor_pointer  text-center`}>
                        <span
                           onClick={() =>
                              handleAttachmentClick(item?.documents)
                           }
                           title="Attachment"
                        >
                           {svgIcons.attachmentIcon}
                        </span>
                     </td>
                     <td className={`text-capitalize row-fix-to-last text-center`}>
                        <EditAndToggleBtn
                           isActive={item?.isActive}
                           handleClick1={() =>
                              route(
                                 `/user-management/handle-user-details?userId=${item._id}`
                              )
                           } // Pass ID to the handler
                           handleClick2={() => handleToggle(item._id)} // Pass ID to the handler
                        />
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
         {users?.length == 0 && (
            <h6 className="DataNotFoundText">No Users Found...</h6>
          )}
         <CenteredPopup
            isOpen={attachment.isPopupOpen}
            onClose={handlePopupClose}
            title="Documents"
            style={{maxWidth:'40%', minWidth:'500px'}}
         >
            <DocumentsShowCard documents={attachment?.url} />
         </CenteredPopup>
      </BigTableLayout>
   );
};

export default UserManagementList;
