// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoleAccessForm_form_wrapper .RoleAccessForm_form{
    height: 50vh;
    overflow-y: auto;
}
.RoleAccessForm_form_wrapper .buttonsWrapper{
    gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/roleaccess/RoleaccessForm.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,QAAQ;AACZ","sourcesContent":[".RoleAccessForm_form_wrapper .RoleAccessForm_form{\n    height: 50vh;\n    overflow-y: auto;\n}\n.RoleAccessForm_form_wrapper .buttonsWrapper{\n    gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
