import React, { useEffect, useState } from "react";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { ToggleButton } from "../../commanComponents/buttons/ToggleButtons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import { useNavigate } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { Mutation, useMutation, useQuery } from "react-query";
import useDebounce from "../../constants/useDebounce";
import { isEven } from "../../constants/Helper/NumbersFunction";
import EditAndToggleBtn from "../../commanComponents/buttons/EditAndToggleBtn";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import { DocumentsShowCard } from "../../commanComponents/Images/ImagesShowCard";
import Loader from "../../commanComponents/Loader";
import BigTableLayout from "../../commanComponents/CommonLayout/BigTableLayout";
import { PaginationSrNo } from "../../constants/Helper/Helpers";
import { Heading_6 } from "../../commanComponents/NavigationBar/Typography";
import Filter from "./Filter";
import { userRole } from "../../constants/Helper/getUserRole";

function ContractorsList() {
   const navigate = useNavigate();
   const [page, setPage] = useState(1);
   const [wardList, setWardList] = useState([]);
   const [selectedWard, setSelectedWard] = useState("");
   const [searchString, setSearchString] = useState("");
   const [pageUpdated, setPageUpdated] = useState("");
   const [periodOfContract, setPeriodOfContract] = useState("");
   const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);
   const [loading, setLoading] = useState(false);
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [attachment, setAttachment] = useState({
      isPopupOpen: false,
      url: [],
   });

   const { data, isLoading, isFetching, isError } = useQuery(
      ["contractorList", page, debouncedSearchTerm, pageUpdated],
      () =>
         commonApi.getUserList(
            [{ lable: "Contractor", value: "contractor" }],
            page,
            searchString,
            selectedWard?.value,
            periodOfContract?.value
         ),
      {
         refetchOnWindowFocus: false,
         onSuccess: (data) => {
            if (data?.status == "JWT_INVALID") {
               toast.error(`Failed : ${data?.message}`);
            }
         },
         onError: (error) => {
            toast.error(`Failed : ${error?.message}`);
         },

         keepPreviousData: true,
      }
   );

   const fetchActiveWardList = async () => {
      try {
         const response = await commonApi.getActiveWardList();
         if (response?.status === "SUCCESS") {
            const options = response.ward.map((ward) => ({
               value: ward._id,
               label: ward.name,
            }));

            setWardList(options);
         } else if (response?.status === "JWT_INVALID") {
            toast.error(`Failed : ${response?.message}`);
         } else {
            toast.error(response?.message);
            setWardList([]);

            throw new Error(response?.message || "Failed to fetch Zones");
         }
      } catch (error) {
         console.error("Error fetching fetch Zones:", error);
         setWardList([]);
      }
   };

   const handleAttachmentClick = (url) => {
      setAttachment({
         isPopupOpen: true,
         url: url,
      });
   };

   const handlePopupClose = () => {
      setAttachment({
         isPopupOpen: false,
         url: [],
      });
   };
   const mutation = useMutation(
      (id) => {
         setLoading(true);
         return commonApi.changeUserStatus(id);
      }, // Pass ID to the API function
      {
         onSuccess: (data) => {
            if (data?.status === "SUCCESS") {
               toast.success(`Success: ${data?.message}`);
               setLoading(false);
               setPageUpdated((prev) => !prev);
            } else if (data?.status === "JWT_INVALID") {
               setLoading(false);
               toast.error(`Failed: ${data?.message}`);
            } else {
               setLoading(false);
               toast.error(data.message || "Error updating status!");
            }
         },
         onError: (error) => {
            setLoading(false);
            toast.error(`Error updating status: ${error.message}`);
         },
      }
   );
   const handleOpenFilterPopup = () => setIsPopupOpen(true);
   const handleCloseFilterPopup = () => setIsPopupOpen(false);

   const handleToggle = (id) => {
      mutation.mutate(id);
   };

   useEffect(() => {
      fetchActiveWardList();
   }, []);

   useEffect(() => {
      if (userRole() !== "zonal") {
         navigate(-1); // redirect to previous page if user dont not have Tab Access
         return;
      }
   }, []);

   return (
      <div>
         {(isFetching || loading) && <Loader />}
         <div className="search_btn_wrapper">
            <div className="d-flex align-items-center w-100">
               <div
                  className="px-4 py-2 d-flex align-items-center cursor_pointer bg_support_orange rounded mr-2"
                  onClick={handleOpenFilterPopup}
               >
                  {svgIcons.filtersIcon}
                  <Heading_6 text="Filter" className="ml-3 mb-0 text-white" />
               </div>
               <div className="w-200">
                  <Searchbox
                     onChange={(e) => {
                        setSearchString(e.target.value)
                     }}
                     placeholder="Search Contractors..."
                  />
               </div>
            </div>
            <div className="buttons_wrapper">
               <RoundedbuttonWithIcon
                  text={"Add Contractor"}
                  textClasses="text-uppercase"
                  icon={svgIcons.PlusIcon}
                  onClick={() => {
                     navigate(
                        "/manage-contractors/handle-contractor-details?type=add"
                     );
                  }}
               />
            </div>
         </div>
         <BigTableLayout>
            <table className="table w-100 overflow-auto">
               <thead
                  className="w-100 bg_Table_Header border-0"
                  style={{
                     position: "sticky",
                     top: "0",
                     right: "0",
                     zIndex: "1",
                  }}
               >
                  <tr>
                     <th style={{ whiteSpace: "nowrap" }} className="col_100px">
                        Sr. No
                     </th>
                     <th className="col_150px">User ID</th>
                     <th className="col_200px">Name</th>
                     <th className="col_150px">Mobile</th>
                     <th className="col_150px">Gender</th>
                     <th className="col_150px">Age</th>
                     <th className="col_200px">Adhar Card Number</th>
                     {/* <th scope=" className="col_200px"col">Password</th> */}
                     <th className="col_150px text-center">Attachment</th>
                     <th className="col_150px text-center row-fix-to-last table-th-bg-color">
                        Action
                     </th>
                  </tr>
               </thead>
               <tbody>
                  {data?.users?.map((item, i) => (
                     <tr
                        key={item._id || i}
                        className={`${isEven(i + 1) && "Table_Data"}`}
                     >
                        <th scope="row">{PaginationSrNo(page, i)}</th>
                        <td
                           className={`text-capitalize `}
                           style={{ whiteSpace: "nowrap" }}
                        >
                           {item?.userId || "--"}
                        </td>
                        <td
                           className={`text-capitalize `}
                           style={{ whiteSpace: "nowrap" }}
                        >
                           {item?.name || "--"}
                        </td>
                        <td
                           className={`text-capitalize `}
                           style={{ whiteSpace: "nowrap" }}
                        >
                           {item?.mobile || "--"}
                        </td>
                        <td
                           className={`text-capitalize `}
                           style={{ whiteSpace: "nowrap" }}
                        >
                           {item?.gender || "--"}
                        </td>
                        <td
                           className={`text-capitalize `}
                           style={{ whiteSpace: "nowrap" }}
                        >
                           {item?.age || "--"}
                        </td>
                        <td
                           className={`text-capitalize `}
                           style={{ whiteSpace: "nowrap" }}
                        >
                           {item?.aadharNo || "--"}
                        </td>
                        <td
                           className={`text-capitalize cursor_pointer  text-center `}
                           style={{ whiteSpace: "nowrap" }}
                        >
                           <span
                              onClick={() =>
                                 handleAttachmentClick(item?.documents)
                              }
                              title="Attachment"
                           >
                              {svgIcons.attachmentIcon}
                           </span>
                        </td>
                        <td
                           className={`text-capitalize row-fix-to-last text-center `}
                        >
                           <div
                              style={{
                                 whiteSpace: "nowrap",
                                 display: "flex",
                                 alignItems: "center",
                                 gap: "16px",
                                 margin: "auto",
                                 width: "fit-content",
                                 cursor: "pointer",
                              }}
                           >
                              <div
                                 onClick={() =>
                                    navigate(
                                       `/manage-contractors/handle-contractor-details?type=view&contractorDetailId=${item._id}`
                                    )
                                 }
                                 title="View"
                              >
                                 {svgIcons?.eyeIcon}
                              </div>
                              <EditAndToggleBtn
                                 isActive={item?.isActive}
                                 handleClick1={() =>
                                    navigate(
                                       `/manage-contractors/handle-contractor-details?type=update&contractorDetailId=${item._id}`
                                    )
                                 } // Pass ID to the handler
                                 handleClick2={() => handleToggle(item._id)} // Pass ID to the handler
                              />
                           </div>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
            {(!data?.users || data?.users?.length == 0) && (
               <h6 className="DataNotFoundText">No Contractors Found...</h6>
            )}
            <CenteredPopup
               isOpen={attachment.isPopupOpen}
               onClose={handlePopupClose}
               title="Documents"
               style={{maxWidth:'40%', minWidth:'500px'}}
            >
               <DocumentsShowCard documents={attachment?.url} />
            </CenteredPopup>
         </BigTableLayout>

         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleCloseFilterPopup}
            title="Filter"
         >
            <Filter
               setSelectedWard={setSelectedWard}
               selectedWard={selectedWard}
               onClose={handleCloseFilterPopup}
               wardList={wardList}
               setPageUpdated={setPageUpdated}
               periodOfContract={periodOfContract}
               setPeriodOfContract={setPeriodOfContract}
            />
         </CenteredPopup>

         <div className="mt-2">
            <PaginationButton
               page={page}
               setPage={setPage}
               totalPages={data?.totalPages || 1}
            />
         </div>
      </div>
   );
}

export default ContractorsList;
