// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.departmentButtons {
    flex-grow: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.departmentNavButtons {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: .5em;
    max-height: 49vh;
}

.departmentNavButtons .infinite-scroll-component {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.departmentNavButtons .infinite-scroll-component::-webkit-scrollbar {
    width: 10px;
    /* Width of the vertical scrollbar */
    height: 10px;
    /* Height of the horizontal scrollbar */
}

.departmentNavButtons .infinite-scroll-component::-webkit-scrollbar-track {
    background: var(--base_light-yellow);
    /* Color of the scrollbar track */
    border-radius: 10px;
    /* Rounded corners for the scrollbar track */
}

.departmentNavButtons .infinite-scroll-component::-webkit-scrollbar-thumb {
    background-color: var(--base_light-yellow);
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners for the scrollbar thumb */
    border: 2px solid #ccc;
    /* Adding a border around the scrollbar thumb */
}`, "",{"version":3,"sources":["webpack://./src/components/manage departments/DepartmentsList.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,oCAAoC;IACpC,YAAY;IACZ,uCAAuC;AAC3C;;AAEA;IACI,oCAAoC;IACpC,iCAAiC;IACjC,mBAAmB;IACnB,4CAA4C;AAChD;;AAEA;IACI,0CAA0C;IAC1C,iCAAiC;IACjC,mBAAmB;IACnB,4CAA4C;IAC5C,sBAAsB;IACtB,+CAA+C;AACnD","sourcesContent":[".departmentButtons {\n    flex-grow: 1;\n    justify-content: space-between;\n    display: flex;\n    flex-direction: column;\n}\n\n.departmentNavButtons {\n    position: relative;\n    overflow-y: auto;\n    overflow-x: hidden;\n    display: flex;\n    flex-direction: column;\n    gap: .5em;\n    max-height: 49vh;\n}\n\n.departmentNavButtons .infinite-scroll-component {\n    overflow-y: auto !important;\n    overflow-x: hidden !important;\n}\n\n.departmentNavButtons .infinite-scroll-component::-webkit-scrollbar {\n    width: 10px;\n    /* Width of the vertical scrollbar */\n    height: 10px;\n    /* Height of the horizontal scrollbar */\n}\n\n.departmentNavButtons .infinite-scroll-component::-webkit-scrollbar-track {\n    background: var(--base_light-yellow);\n    /* Color of the scrollbar track */\n    border-radius: 10px;\n    /* Rounded corners for the scrollbar track */\n}\n\n.departmentNavButtons .infinite-scroll-component::-webkit-scrollbar-thumb {\n    background-color: var(--base_light-yellow);\n    /* Color of the scrollbar thumb */\n    border-radius: 10px;\n    /* Rounded corners for the scrollbar thumb */\n    border: 2px solid #ccc;\n    /* Adding a border around the scrollbar thumb */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
