import React, { useState } from "react";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import Select from "react-select";

const Filter = ({
   onClose,
   setSelectedHouseType,
   setPageUpdated,
   selectedHouseType,
}) => {
   const houseTypeList = [
      { label: "Select All", value: "" },
      { label: "Owner", value: "owner" },
      { label: "Flatscheme", value: "flatscheme" },
      { label: "Residential", value: "residential" },
      { label: "Rented", value: "rented" },
   ];
   return (
      <div>
         <div
            style={{
               textAlign: "start",
               width: "80%",
               margin: "auto",
               marginBottom: "20px ",
            }}
         >
            <label className="">House Type</label>
            <Select
               options={houseTypeList}
               onChange={(selected) => {
                  setSelectedHouseType(selected);
               }}
               placeholder="Select House Type"
               value={selectedHouseType ? selectedHouseType : ""}
            />
         </div>

         <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper">
            <RoundedBtn
               text="Apply"
               className="bg_primary text-white px-4 mr-3"
               type="button"
               onClick={() => {
                  setPageUpdated((prev) => !prev);
                  onClose();
               }}
            />
            <RoundedBtn
               text="Reset"
               className="btn-outline-secondary px-4 mr-3"
               type="button"
               onClick={() => {
                  setSelectedHouseType({label: "Select All", value: "" })
                  setPageUpdated((prev) => !prev);
                  onClose();
               }}
            />
            <RoundedBtn
               text="Close"
               className="bg-secondary text-white px-4"
               onClick={(e) => {
                  e.preventDefault();
                  onClose();
               }}
            />
         </div>
      </div>
   );
};

export default Filter;
