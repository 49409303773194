// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button{
    outline: none !important;
    white-space: nowrap;
}
.RoundedbuttonWithIncreaseWidth .count {
    width: 20px;
    height: 20px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
}

.RoundedbuttonWithIncreaseWidth:hover .extraBtn {
    right: 5px;
}

.RoundedbuttonWithIncreaseWidth .extraBtn {
    display: flex;
    position: absolute;
    right: -50%;
    align-items: center;
    justify-content: center;
    z-index: 3;
    transition: all 0.3s ease-in;
}`, "",{"version":3,"sources":["webpack://./src/commanComponents/buttons/NavigationButtons.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,4BAA4B;AAChC","sourcesContent":["button{\n    outline: none !important;\n    white-space: nowrap;\n}\n.RoundedbuttonWithIncreaseWidth .count {\n    width: 20px;\n    height: 20px;\n    background-color: black;\n    color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    border-radius: 50%;\n}\n\n.RoundedbuttonWithIncreaseWidth:hover .extraBtn {\n    right: 5px;\n}\n\n.RoundedbuttonWithIncreaseWidth .extraBtn {\n    display: flex;\n    position: absolute;\n    right: -50%;\n    align-items: center;\n    justify-content: center;\n    z-index: 3;\n    transition: all 0.3s ease-in;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
