import { useRef, useState } from "react";
import "./OTPInput.css";
import Timer from "../../commanComponents/Timer";
import { toast } from "react-toastify";

const OTPInput = ({ isOpen = true, onVerify }) => {
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(120);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value.length > 0 && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      inputRefs.current[index - 1].focus();
    }

    // Block space button
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all OTP fields are filled
    if (otp.some((digit) => digit === "")) {
      toast.error("Please fill in all OTP fields.");
      return;
    }

    onVerify(otp.join(""));
  };

  return (
    <div className={`OTPInput ${isOpen ? "openotp" : "closeotp"}`}>
      <p className="text-center mb-0">OTP Sent On Your Number.</p>
      <form className="form mx-auto">
        <div className="inputs">
          {[...Array(4)].map((_, index) => (
            <input
              key={index}
              type="tel"
              maxLength="1"
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="otp-input"
              required
            />
          ))}
        </div>
        <div className="my-3 text-center">
          <button
            onClick={handleSubmit}
            className="btn bg_primary text-white mx-auto px-3"
          >
            Verify OTP
          </button>
        </div>
      </form>
      {/* Use Timer component */}
      <Timer timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
    </div>
  );
};

export default OTPInput;
