import { useNavigate } from "react-router-dom";
import DepartmentsList from "./DepartmentsList";
import DesignationsList from "./DesignationsList";
import "./ManageDepartmentsMain.css";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { userRole } from "../../constants/Helper/getUserRole";

const ManageDepartmentsMain = () => {
   const navigate = useNavigate();

   useEffect(()=>{
    if (!checkUserTabAccess("Manage_Departments") || userRole() == 'zonal') {
      toast.info('You do not have permission to access Manage Departments')
      navigate(-1); // redirect to previous page if user dont not have Tab Access
      return;
   }
   },[])

   return (
      <div className="w-100 manageDepartmentsWrapper">
         <div className="row w-100 gap-4 h-100 mx-auto">
            {" "}
            {/* Changed class to className */}
            <div className="col-4 pr-2 p-0 position-relative h-100">
               {" "}
               {/* Changed class to className */}
               <div className="common_shadow rounded p-3 h-100 bg-white">
                  <DepartmentsList />
               </div>
            </div>
            <div className="col-8 p-0 h-100">
               {" "}
               <DesignationsList />
            </div>
         </div>
      </div>
   );
};

export default ManageDepartmentsMain;
