import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { InputWithLabel } from "../inputs/InputWithLabel";
import { RoundedBtn } from "../buttons/SubmitBtns";
import { commonApi } from "../../constants/apis/adminCommunication";
import { useTryCatch } from "../../constants/apis/Apicall";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

const AddWardForm = ({ ward, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const [zoneId, setZoneId] = useState(""); // State for selected zone ID
  const [wardName, setWardName] = useState(""); // State for ward name
  const [options, setOptions] = useState([]);
  const { getActiveList, loading, error } = useTryCatch();
  const queryClient = useQueryClient();
  const isFetched = useRef(false);
  const route = useNavigate();

  const fetchZones = async () => {
    if (!isFetched.current) {
      isFetched.current = true; // Set ref to true after first fetch

      const response = await getActiveList(
        commonApi.getActiveZoneList,
        "",
        "Failed to fetch zones",
        true // Redirect to login on error
      );

      if (response?.status === "SUCCESS") {
        const formattedOptions = response.result.map((zone) => ({
          value: zone._id,
          label: `${zone.name} (${zone.zoneNo})`,
        }));
        setOptions(formattedOptions);
      } else {
        toast.error(response.message || "Failed to fetch zones");
      }
    }
  };

  useEffect(() => {
    fetchZones();
    if (ward) {
      setZoneId(ward.zoneId?._id || ""); // Use zoneId._id if it exists
      setWardName(ward.name || "");
      setValue("ward", ward.name || ""); // Set initial value for ward name input
    } else {
      setZoneId(""); // Clear zoneId if creating a new ward
      setWardName(""); // Clear ward name if creating a new ward
    }
  }, [ward]);

  const mutation = useMutation(
    async () => {
      const data = { name: wardName, zoneId };
      if (ward) {
        return commonApi.updateWard(ward._id, data.name, data.zoneId);
      } else {
        return commonApi.addWard(data.name, data.zoneId);
      }
    },
    {
      onSuccess: (data) => {
        if (data.status === "SUCCESS") {
          toast.success(`Ward ${ward ? "updated" : "created"} successfully!`);
          queryClient.invalidateQueries("wards");
          onClose();
          reset();
        } else if (data.status === "JWT_INVALID") {
          toast.error("please Login and try again!");
          route("/login");
        } else {
          toast.error(
            data.message || `Error ${ward ? "updating" : "creating"} ward!`
          );
        }
      },
      onError: (error) => {
        toast.error(
          `Error ${ward ? "updating" : "creating"} ward: ${error.message}`
        );
      },
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(); // Trigger the mutation
  };

  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      textAlign: "left",
    }),
    input: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    container: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    control: (provided) => ({
      ...provided,
      borderColor: "lightgray",
      boxShadow: "none",
      "&:hover": {
        borderColor: "gray",
      },
    }),
  };

  return (
    <div className="mx-auto text-start" style={{ maxWidth: "300px" }}>
    
      {error && (
        <div className="d-flex justify-content-center">
          <span className="text-danger">Error: {error}</span>
        </div>
      )}
      <form style={{textAlign:'start'}}>
        <div className="my-2 mb-2">
          <label>Zone*</label>
          <Select
          isLoading={loading}
            options={options}
            placeholder="Select Zone*"
            styles={customStyles}
            value={options.find((opt) => opt.value === zoneId)}
            onChange={(selectedOption) => setZoneId(selectedOption.value)}
          />
        </div>
        <div className="mt-3 mb-4">
        <InputWithLabel
        labelText={'Ward Name*'}
        label={true}
          placeholder="Enter Ward Name*"
          value={wardName}
          onChange={(e) => setWardName(e.target.value)}
          required
        />
        </div>
        <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper">
        {checkUserTabAccess("Zone_Ward")?.access == "write" &&
          <RoundedBtn
            text={ward ? "Update" : "Create"}
            className="bg_primary text-white px-4"
            type="submit"
            onClick={onSubmit}
            disabled={mutation.isLoading}
          />
        }
          <RoundedBtn
            text="Cancel"
            className="bg-secondary text-white px-4 ml-2"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            disabled={mutation.isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default AddWardForm;
