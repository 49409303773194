import React, { useEffect, useState } from "react";
import "./InputWithLabel.css";

export const InputWithLabel = ({
   label = false,
   labelText,
   value,
   disabled,
   placeholder,
   type = "text",
   errorMessage,
   id,
   onChange,
   min,
   register,
}) => {

   const handleChange = (e) => {
      if (onChange) {
         onChange(e); // Call the onChange handler passed from parent
      }
   };

   return (
      <div className="input-container InputWithLabel">
         {label && (
            <label htmlFor={id} className="name">
               {labelText}
            </label>
         )}
         <div className="d-flex flex-column">
            <input
               id={id}
               placeholder={placeholder}
               type={type}
               value={value}
               min={min}
               className="input"
               disabled={disabled}
               onChange={handleChange}
               {...register}
            />
            { errorMessage && (
               <p className="error-message text-danger ">
                  {errorMessage?.message}
               </p>
            )}
         </div>
      </div>
   );
};
export const DropdownInput = ({
   label = false,
   labelText,
   options = [],
   placeholder,
   onChange,
   value,
   errorMessage,
   id,
}) => {
   const handleChange = (e) => {
      if (onChange) {
         onChange({
            value: e.target.value,
            label: e.target.options[e.target.selectedIndex].text,
         }); // Send both value and label to the parent
      }
   };

   return (
      <div className="input-container DropdownInput InputWithLabel" title={labelText}>
         {label && (
            <label htmlFor={id} className="Label">
               {labelText}
            </label>
         )}
         <div className="d-flex flex-column">
            <select
               id={id}
               className="input"
               onChange={handleChange}
               value={value}
            >
               <option value="" disabled>
                  {placeholder || "Select an option"}
               </option>
               {options.map((option) => (
                  <option key={option.value} value={option.value}>
                     {option.label}
                  </option>
               ))}
            </select>
            { errorMessage && (
               <p className="error-message text-danger ">
                  {errorMessage?.message}
               </p>
            )}
         </div>
      </div>
   );
};

const SelectBox = ({
   firstOption,
   label,
   options = [],
   displayName,
   otherDisplayName,
   value,
   disable = false,
   register,
   errors,
   defaultValue,
   selectedValue,
}) => {
   return (
      <div className="position-relative DropdownInput ">
         {label && <label className="name">{label}</label>}
         <select
            {...register}
            className="form-control custom_input"
            disabled={disable}
         >
            <option value="" className="text_color-lable text-lowercase">
               {firstOption}
            </option>
            {options?.map((data, index) => {
               return (
                  <option
                     className="small text-capitalize"
                     key={index}
                     value={value ? data[value] : data}
                     defaultValue={value ? data[value] : data == defaultValue}
                     selected={
                        value
                           ? data[value] === selectedValue
                           : data === selectedValue
                     }
                  >
                     {otherDisplayName
                        ? `${data[displayName]} (${data[otherDisplayName]})`
                        : displayName
                        ? data[displayName]
                        : data}
                  </option>
               );
            })}
         </select>
         {errors && (
            <p className="error-message text-danger">{errors.message}</p>
         )}
      </div>
   );
};

export default SelectBox;
