import { useForm } from "react-hook-form";
import AuthLayout from "../../commanComponents/CommonLayout/AuthLayout";
import InputWithIcon from "../../commanComponents/inputs/InputWithIcon";
import { Heading_3 } from "../../commanComponents/NavigationBar/Typography";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import OTPInput from "./OTPInput";
import ChangePassword from "./ChangePassword";

const ForgotPasswordMain = () => {
  const { control, handleSubmit, watch } = useForm();
  const [isOtpOpen, setIsOtpOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [mobile, setMobile] = useState("");
  const navigate = useNavigate();
  const [isOTPVerified, setisOTPVerified] = useState(false);

  // Mutation for generateOTP API call
  const generateOtpMutation = useMutation(
    ({ userId, mobile }) => {
      setLoading(true);
      return commonApi.generateOTP(userId, mobile);
   }, 
    {
      onSuccess: (data) => {
        if (data?.status === "SUCCESS") {
          toast.success(`Your OTP -${data?.otp}`);
          setIsOtpOpen(true);
          setLoading(false)
        } else {
          toast.error(`Failed to send OTP: ${data?.message}`);
          setIsOtpOpen(false);
          setLoading(false)
        }
      },
      onError: (error) => {
        toast.error("An error occurred while sending the OTP.");
        setIsOtpOpen(false);
        console.error("Error:", error);
        setLoading(false)
      },
    }
  );

  // Mutation for verifyOTP API call
  const verifyOtpMutation = useMutation(
    ({ otp, userId, mobile }) => commonApi.verifyOTP(otp, userId, mobile),
    {
      onSuccess: (data) => {
        if (data?.status === "SUCCESS") {
          toast.success("OTP verified successfully!");
          setisOTPVerified(true);
          setIsOtpOpen(false);
        } else {
          toast.error(`Failed to verify OTP: ${data?.message}`);
          setisOTPVerified(false);
        }
      },
      onError: (error) => {
        toast.error("An error occurred while verifying the OTP.");
        setisOTPVerified(false);
        console.error("Error:", error);
      },
    }
  );

  // Submit form handler for OTP generation
  const onSubmit = (data) => {
    setUserId(data.userId);
    setMobile(data.mobile);
    generateOtpMutation.mutate({
      userId: data.userId,
      mobile: data.mobile,
    });
  };

  // OTP verification handler
  const handleVerifyOtp = (otp) => {
    verifyOtpMutation.mutate({
      otp,
      userId,
      mobile,
    });
  };

  return (
    <AuthLayout>
      <div className="text-center">
        <Heading_3
          text="Forget Password"
          className="text_primary text-center mb-0"
        />
      </div>
      {!isOTPVerified ? (
        <>
          <form className="py-2">
            <div className="w-100 mx-auto">
              <InputWithIcon
                label="User Id"
                control={control}
                name="userId"
                className="my-2"
                icon={svgIcons.userIcon}
                placeholder="User Id"
              />
              <InputWithIcon
                control={control}
                label="Mobile No"
                name="mobile"
                className="my-2"
                icon={svgIcons.mobileIcon}
                placeholder="Mobile..."
              />
            </div>

            <div className="my-3 text-center">
              <button
                onClick={handleSubmit(onSubmit)}
                className="btn loginBtn text-white mx-auto px-5"
              >
               {
                    loading && <span
                        className="spinner-border mr-2"
                        role="status"
                        aria-hidden="true"
                        style={{
                           width: "20px",
                           height: "20px",
                           borderWidth: "2px",
                        }}
                     ></span>
                  } Submit
              </button>
            </div>

            <OTPInput isOpen={isOtpOpen} onVerify={handleVerifyOtp} />

            <div className="text-center">
              <p className="mb-0">
                Already have an Account{" "}
                <Link to="/login" className="text_primary">
                  Sign in
                </Link>
              </p>
            </div>
          </form>
        </>
      ) : (
        <ChangePassword userId={userId} />
      )}
    </AuthLayout>
  );
};

export default ForgotPasswordMain;
