import React, { useState } from "react";
import { DropdownInput } from "../../commanComponents/inputs/InputWithLabel";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import { userType } from "../../constants/utilities/Data";
import Select from "react-select";

const UserFilter = ({ onClose, setUsertype, usertype }) => {
   const [selectedUserType, setSelectedUserType] = useState(usertype);

   const handleApply = () => {
      setUsertype(selectedUserType); // Pass the selected value to the parent
      onClose(); // Close the popup after applying the filter
   };

   const handleReset = () => {
      setUsertype([]); // Pass the empty array value to the parent for reset
      onClose(); // Close the popup after applying the filter
   };

   return (
      <div style={{ width: "300px", textAlign: "start", margin: "auto" }}>
         <label className="name">Filter by User Type</label>
         <Select
            options={userType}
            onChange={(selected) => {
               setSelectedUserType(selected);
            }}
            isMulti={true}
            value={selectedUserType ? selectedUserType : ""}
            placeholder="Select User Type"
         />

         <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper">
            <div className="mr-3">
               <RoundedBtn
                  text="Apply"
                  className="bg_primary text-white px-4"
                  type="button"
                  onClick={handleApply}
               />
            </div>
            <RoundedBtn
               text="Reset"
               className="btn-outline-secondary px-4 mr-3"
               type="button"
               onClick={handleReset}
            />
            <RoundedBtn
               text="Close"
               className="bg-secondary text-white px-4"
               onClick={(e) => {
                  e.preventDefault();
                  onClose();
               }}
            />
         </div>
      </div>
   );
};

export default UserFilter;
