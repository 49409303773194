import React from 'react'
import MyGrievanceList from '../components/myGrievance/MyGrievanceList'

export default function MyGrievance() {
  return (
    <div>
      <MyGrievanceList />
    </div>
  )
}
