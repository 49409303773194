import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import { SidebarContext } from "../../constants/stateManagement/SidebarProvider";

export default function NavButton({ tabInfo }) {
   const { closeSideNav, setCloseNavBar } = useContext(SidebarContext);
   const currentUrl = useLocation().pathname.split("/");
   const [openDropdown, setOpentDropdown] = useState(false);
   const navigation = useNavigate();

   const handleNavigation = (path) => {
      navigation(path);
      setOpentDropdown(!openDropdown);
   };

   return (
      <div>
         <NavLink
            to={tabInfo?.items || closeSideNav ? "#" : tabInfo?.url}
            onClick={() => {
               tabInfo?.items &&
                  !closeSideNav &&
                  setOpentDropdown(!openDropdown);
            }}
            style={{
               textDecoration: "none",
               cursor: closeSideNav ? "default" : "pointer",
            }}
            className={
               currentUrl?.includes(tabInfo?.activeurl)
                  ? "tab_active"
                  : "tab_inactive"
            }
            title={tabInfo?.tab}
         >
            <button
               className={`${
                  closeSideNav ? "CloasedSidebarNavBtn" : "sidebarTabBtn"
               }`}
               
            >
               <div>
                  <div>{tabInfo?.icon}</div>{" "}
                  <div style={{ display: closeSideNav && "none" }}>
                     {tabInfo?.tab}{" "}
                  </div>
               </div>
               {!closeSideNav && (
                  <div style={{ display: tabInfo?.items ? "block" : "none" }}>
                     {openDropdown ? <IoIosArrowUp /> : <FaAngleDown />}
                  </div>
               )}
            </button>
         </NavLink>
         <div
            style={{
               display: openDropdown && !closeSideNav ? "block" : "none",
            }}
            className="NavBtnDroppdown"
         >
            {tabInfo?.items?.map((data) => {
               return (
                  <div
                     onClick={() => handleNavigation(data?.url)}
                     className={
                        currentUrl?.includes(data?.activeurl)
                           ? "dropdownTabActive"
                           : "dropdownTabInActive"
                     }
                  >
                     {data?.icon} {data?.tab}
                  </div>
               );
            })}
         </div>
      </div>
   );
}
