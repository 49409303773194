import React, { useEffect, useState } from "react";
import SelectBox, {
   InputWithLabel,
} from "../../commanComponents/inputs/InputWithLabel";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import { toast } from "react-toastify";
import { commonApi } from "../../constants/apis/adminCommunication";
import Select from "react-select";
import Loader from "../../commanComponents/Loader";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { userRole } from "../../constants/Helper/getUserRole";

function DumpYardManagement() {
   const {
      register,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors },
   } = useForm();
   const navgation = useNavigate();
   const [zoneList, setWardZone] = useState([]);
   const [loading, setLoading] = useState(false);
   const [selectedZone, setSelectedZone] = useState({});
   const [params] = useSearchParams();
   const pageType = params.get("type");
   const dumpId = params.get("dumpId");

   const formatZoneList = (data) => {
      const formatedData = data?.map((e) => {
         return { value: e?._id, label: e?.name };
      });
      setWardZone(formatedData);
   };
   const fetchDumpYardDetails = async () => {
      try {
         setLoading(true);
         const serverResponse = await commonApi.getDumpYardDetails(dumpId);

         if (serverResponse?.status === "SUCCESS") {
            setValue("name", serverResponse?.dumpYard?.name);
            setValue("dryCapacity", serverResponse?.dumpYard?.dryCapacity);
            setValue("wetCapacity", serverResponse?.dumpYard?.wetCapacity);
            setValue("address", serverResponse?.dumpYard?.address);
            setValue("latitude", serverResponse?.dumpYard?.latitude);
            setValue("longitude", serverResponse?.dumpYard?.longitude);
            setSelectedZone({label:serverResponse?.dumpYard?.zoneId?.name, value:serverResponse?.dumpYard?.zoneId?._id});
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "FAILED") {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   const fetchDumpZoneList = async () => {
      try {
         const serverResponse = await commonApi.getActiveZones();

         if (serverResponse?.status === "SUCCESS") {
            formatZoneList(serverResponse?.result);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "FAILED") {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      }
   };

   const handleUpdate = async (data) => {
      const DataToSend = {
         id: dumpId,
         name: data?.name,
         address: data?.address,
         zoneId: selectedZone?.value,
         dryCapacity: Number(data?.dryCapacity),
         wetCapacity: Number(data?.wetCapacity),
         location: {
            latitude: Number(data?.latitude),
            longitude: Number(data?.longitude),
         },
      };
      try {
         setLoading(true);
         const serverResponse = await commonApi.updateDumpYard(DataToSend);
         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
            navgation(-1);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };
   const handleAdd = async (data) => {
      const dataToSend = {
         name: data?.name,
         address: data?.address,
         zoneId: selectedZone?.value,
         dryCapacity: Number(data?.dryCapacity),
         wetCapacity: Number(data?.wetCapacity),
         location: {
            latitude: Number(data?.latitude),
            longitude: Number(data?.longitude),
         },
      };
      try {
         setLoading(true);
         const serverResponse = await commonApi.addDumpYard(dataToSend);

         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
            navgation(-1);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.error(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (pageType === "update" && dumpId) {
         fetchDumpYardDetails();
      }
   }, [pageType, dumpId]);

   useEffect(() => {
      fetchDumpZoneList();
   }, []);

   useEffect(() => {
      if (!checkUserTabAccess("Dump_Yard_Manage") || userRole() == 'zonal') {
         toast.info("You do not have permission to access Dump Yard Manage");
         navgation(-1); // redirect to previous page if user dont not have Tab Access
         return;
      }
   }, []);

   return (
      <>
         {loading && <Loader />}
         <div className="tableWrapper big_Shadow p-4 announcementMainWrapper">
            <h3 className="text-center text-uppercase">
               {pageType == "add" ? "Add Dump Yard" : "Update Dump Yard"}
            </h3>
            <div className="inputWrapper mt-4">
               <InputWithLabel
                  label={true}
                  labelText={"Dump Yard Name*"}
                  placeholder={"Enter Dump Yard Name*"}
                  register={{
                     ...register("name", {
                        required: "Dump Yard Name is required",
                     }),
                  }}
                  errorMessage={errors.name}
               />
               <div>
                  <label className="input_lable">Zone*</label>
                  <Select
                     name=""
                     options={zoneList}
                     className=" custom_select"
                     onChange={(selected) => {
                        setSelectedZone(selected);
                     }}
                     value={selectedZone}
                     classNamePrefix="select"
                  />
               </div>
               <InputWithLabel
                  label={true}
                  labelText={"Dry Capacity*"}
                  placeholder={"Enter Dry Capacity*"}
                  register={{
                     ...register("dryCapacity", {
                        required: "Dry Capacity is required",
                     }),
                  }}
                  errorMessage={errors.dryCapacity}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Wet Capacity*"}
                  placeholder={"Enter Wet Capacity*"}
                  register={{
                     ...register("wetCapacity", {
                        required: "Wet Capacity is required",
                     }),
                  }}
                  errorMessage={errors.wetCapacity}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Latitude (Geo Tag)*"}
                  placeholder={"Enter Latitude*"}
                  register={{
                     ...register("latitude", {
                        required: "Latitude is required",
                     }),
                  }}
                  errorMessage={errors.latitude}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Longitude (Geo Tag)*"}
                  placeholder={"Enter Longitude*"}
                  register={{
                     ...register("longitude", {
                        required: "Longitude is required",
                     }),
                  }}
                  errorMessage={errors.longitude}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Address*"}
                  placeholder={"Enter Address Capacity*"}
                  register={{
                     ...register("address", {
                        required: "Address is required",
                     }),
                  }}
                  errorMessage={errors.address}
               />
            </div>
            <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper mt-3">
               {checkUserTabAccess("Dump_Yard_Manage")?.access === "write" && (
                  <RoundedBtn
                     text={pageType == "add" ? "Submit" : "Update"}
                     className="bg_primary text-white px-4 mr-4"
                     type={"submit"}
                     onClick={
                        pageType == "add"
                           ? handleSubmit(handleAdd)
                           : handleSubmit(handleUpdate)
                     }
                  />
               )}
               <RoundedBtn
                  text="Cancel"
                  className="bg-secondary text-white px-4"
                  onClick={() => {
                     navgation(-1);
                  }}
               />
            </div>
         </div>
      </>
   );
}

export default DumpYardManagement;
