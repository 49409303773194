import garbageImg from "../../assets/background/garbageCollecting.svg";
import Logo from "../Logo";
import "./AuthLayout.css";

const AuthLayout = ({ children }) => {
   return (
      <div className="AuthMainWrapper ">
         <div
         >
            <div className="AuthContainer">
               <div className=" garbageImg">
                  <img src={garbageImg} className="w-100" alt="" />
               </div>
               <div
                  className="w-100 loginInputWrapper"
                  style={{ backgroundColor: "rgba(214, 255, 221, 0.71)" }}
               >
                  {children}
               </div>
            </div>
            <div className="logo mx-auto mt-3">
               <Logo />
            </div>
         </div>
      </div>
   );
};

export default AuthLayout;
