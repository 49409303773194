import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Heading_3 } from "../../commanComponents/NavigationBar/Typography";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons"; // Make sure you have this path correct
import "./LoginMain.css";
import InputWithIcon from "../../commanComponents/inputs/InputWithIcon";
import { useMutation } from "react-query";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import AuthLayout from "../../commanComponents/CommonLayout/AuthLayout";
import { UserDataContext } from "../../constants/stateManagement/GetUserDataProvider";

const LoginMain = () => {
   const { control, handleSubmit } = useForm();
   const [passwordVisibility, setPasswordVisibility] = useState(false);
   const [loading, setLoading] = useState(false);
   const { refreshUserData } = useContext(UserDataContext);

   const route = useNavigate();
   const mutation = useMutation(
      (data) => {
         setLoading(true);
         return commonApi.login(data);
      },
      {
         onSuccess: async (data) => {
            if (data?.status === "SUCCESS") {
               if (
                  [
                     "contractor",
                     "collector",
                     "driver",
                     "citizenuser",
                     "survey-user",
                  ]?.includes(data?.userDetails?.userType)
               ) {
                  toast.info(
                     "You don't have access to the admin web portal. Please use the GOT mobile app."
                  );
               }
               else{
                  Cookies.set(
                     "loginToken",
                     JSON.stringify({
                        token: data?.token,
                        userDetails: data?.userDetails,
                     }),
                     { expires: 7 }
                  );
                  refreshUserData()
                  toast.success("Login Sucessfully!");
                  route("/dashboard");
               }
               setLoading(false);
            } else {
               setLoading(false);
               toast.error(`Failed to login: ${data?.message}`);
            }
         },
      }
   );

   const onSubmit = (data) => {
      mutation.mutate(data);
   };

   const cookieValue = Cookies.get("loginToken");
   let token = null;

   if (cookieValue) {
      try {
         const parsedCookie = JSON.parse(cookieValue);
         token = parsedCookie.token;
      } catch (e) {
         console.error("Failed to parse cookie", e);
      }
   }

   return (
      <AuthLayout>
         <div className="text-center">
            <Heading_3 text="Sign In" className="text_primary text-center" />
            <span className="mx-auto">Sign in to stay connected.</span>
         </div>
         <form onSubmit={handleSubmit(onSubmit)} >
            <InputWithIcon
               label="User Id"
               control={control}
               name="userId"
               className="my-2"
               icon={svgIcons.userIcon}
               placeholder="User Id"
            />
            <InputWithIcon
               control={control}
               label="Password"
               name="password"
               className="my-2"
               type={passwordVisibility ? "text" : "password"}
               icon={passwordVisibility ? svgIcons.closeEye : svgIcons.openEye} // Replace with appropriate icon if needed
               placeholder="Password..."
               handleClick={() => setPasswordVisibility(!passwordVisibility)}
            />
            <div className="d-flex justify-content-between">
               <Link to="/reset-password" className="text_primary">
                  Reset Password?
               </Link>
               <Link to="/forgot-password" className="text_primary">
                  Forgot password?
               </Link>
            </div>
            <div className="my-3 text-center">
               <button
                  type="submit"
                  className="btn loginBtn text-white mx-auto px-5 d-flex align-items-center "
               >
                  {loading && (
                     <span
                        className="spinner-border mr-2"
                        role="status"
                        aria-hidden="true"
                        style={{
                           width: "20px",
                           height: "20px",
                           borderWidth: "2px",
                        }}
                     ></span>
                  )}{" "}
                  Sign In
               </button>
            </div>
         </form>
      </AuthLayout>
   );
};

export default LoginMain;
