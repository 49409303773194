import { useEffect, useState } from "react";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import BigTableLayout from "../../commanComponents/CommonLayout/BigTableLayout";
import { commonApi } from "../../constants/apis/adminCommunication";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import NotificationCard from "./NotificationCard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../commanComponents/Loader";

const NotificationMain = () => {
  const [notifications, setnotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const getNotifications = async () => {
    try {
      setLoading(true)
      const res = await commonApi.getNotifications();
      if (res?.status === "SUCCESS") {
        setnotifications(res?.notification);
      } else if (res?.status === "JWT_INVALID") {
        toast.error(res?.message || "Please Log in Again!");
        navigate("/login")
      } else {
        setnotifications([]);
      }
    } catch (error) {
      toast.error(error?.message || "Something Went Wrong!");
    }
    finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <>
    {(loading ) && <Loader/>}
    <div className="tableWrapper w-100 mx-auto BigTableLayout">
      <div className="py-2 px-4 rounded w-100 position-relative big_Shadow">
        <div className="d-flex pt-2 pb-4 mb-3 border-bottom justify-content-end ">
          <div className="w-50">
            <RoundedbuttonWithIcon
              text="Mark As Read"
              className="text-capitalize ml-auto"
              icon={svgIcons.doubleRight}
              // onClick={handleOpenPopup}
            />
          </div>
        </div>
        <div className="container">
          {notifications?.map((notification) => (
            <div className="mb-3" key={notification?._id}>
              <NotificationCard data={notification} getNotifications={getNotifications} />
            </div>
          ))}
            {notifications?.length == 0 && (
            <h6 className="DataNotFoundText">No Notifications Found...</h6>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default NotificationMain;
