// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ManageDepartmentsMain.css */
.manageDepartmentsWrapper {
    height: 72vh; /* Ensure it takes up a portion of the viewport height */
    display: flex;
    flex-direction: column;
}

.manageDepartmentsWrapper .departmentButtons {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.manageDepartmentsWrapper .departmentNavButtons {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: .5em;
}

.manageDepartmentsWrapper .tablemain {
    width: 100%;
    max-height: 55vh; /* Ensures the table scrolls if content overflows */
    overflow-y: auto;
    overflow-x: hidden;
}

/* Custom scrollbar styling */
.manageDepartmentsWrapper .tablemain::-webkit-scrollbar,
.manageDepartmentsWrapper  .departmentNavButtons::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.manageDepartmentsWrapper .tablemain::-webkit-scrollbar-thumb,
.manageDepartmentsWrapper .departmentNavButtons::-webkit-scrollbar-thumb {
    background-color: var(--base_light-yellow);
    border-radius: 10px;
    border: 2px solid #ccc;
}

.manageDepartmentsWrapper .tablemain::-webkit-scrollbar-track,
.manageDepartmentsWrapper .departmentNavButtons::-webkit-scrollbar-track {
    background: var(--base_light-yellow);
    border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/manage departments/ManageDepartmentsMain.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,YAAY,EAAE,wDAAwD;IACtE,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,gBAAgB,EAAE,mDAAmD;IACrE,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA,6BAA6B;AAC7B;;IAEI,WAAW;IACX,YAAY;AAChB;;AAEA;;IAEI,0CAA0C;IAC1C,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;;IAEI,oCAAoC;IACpC,mBAAmB;AACvB","sourcesContent":["/* ManageDepartmentsMain.css */\n.manageDepartmentsWrapper {\n    height: 72vh; /* Ensure it takes up a portion of the viewport height */\n    display: flex;\n    flex-direction: column;\n}\n\n.manageDepartmentsWrapper .departmentButtons {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n}\n\n.manageDepartmentsWrapper .departmentNavButtons {\n    flex-grow: 1;\n    overflow-y: auto;\n    overflow-x: hidden;\n    display: flex;\n    flex-direction: column;\n    gap: .5em;\n}\n\n.manageDepartmentsWrapper .tablemain {\n    width: 100%;\n    max-height: 55vh; /* Ensures the table scrolls if content overflows */\n    overflow-y: auto;\n    overflow-x: hidden;\n}\n\n/* Custom scrollbar styling */\n.manageDepartmentsWrapper .tablemain::-webkit-scrollbar,\n.manageDepartmentsWrapper  .departmentNavButtons::-webkit-scrollbar {\n    width: 10px;\n    height: 10px;\n}\n\n.manageDepartmentsWrapper .tablemain::-webkit-scrollbar-thumb,\n.manageDepartmentsWrapper .departmentNavButtons::-webkit-scrollbar-thumb {\n    background-color: var(--base_light-yellow);\n    border-radius: 10px;\n    border: 2px solid #ccc;\n}\n\n.manageDepartmentsWrapper .tablemain::-webkit-scrollbar-track,\n.manageDepartmentsWrapper .departmentNavButtons::-webkit-scrollbar-track {\n    background: var(--base_light-yellow);\n    border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
