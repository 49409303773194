// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grievanceInputsWrapper {
   max-height: 50vh;
   overflow-y: auto;
}

.grievanceUserTypeFilters {
   display: flex;
   gap: 10px;
   /* margin-bottom: 14px; */
}

.grievanceUserTypeFilters > button {
   padding: 4px 10px;
   border-radius: 10px;
   background-color: #fff;
   border: 1px solid #8a92a6;
   color: #8a92a6;
}

.SelectedUserTypeFilterBtn {
   background: linear-gradient(
      90deg,
      rgba(251, 185, 108, 1) 0%,
      rgba(252, 158, 49, 1) 100%
   ) !important;
   border-color: rgb(218, 116, 0) !important;
   color: #fff !important;
   font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/Grievance/ViewGrievanceMain.css"],"names":[],"mappings":"AAAA;GACG,gBAAgB;GAChB,gBAAgB;AACnB;;AAEA;GACG,aAAa;GACb,SAAS;GACT,yBAAyB;AAC5B;;AAEA;GACG,iBAAiB;GACjB,mBAAmB;GACnB,sBAAsB;GACtB,yBAAyB;GACzB,cAAc;AACjB;;AAEA;GACG;;;;eAIY;GACZ,yCAAyC;GACzC,sBAAsB;GACtB,gBAAgB;AACnB","sourcesContent":[".grievanceInputsWrapper {\n   max-height: 50vh;\n   overflow-y: auto;\n}\n\n.grievanceUserTypeFilters {\n   display: flex;\n   gap: 10px;\n   /* margin-bottom: 14px; */\n}\n\n.grievanceUserTypeFilters > button {\n   padding: 4px 10px;\n   border-radius: 10px;\n   background-color: #fff;\n   border: 1px solid #8a92a6;\n   color: #8a92a6;\n}\n\n.SelectedUserTypeFilterBtn {\n   background: linear-gradient(\n      90deg,\n      rgba(251, 185, 108, 1) 0%,\n      rgba(252, 158, 49, 1) 100%\n   ) !important;\n   border-color: rgb(218, 116, 0) !important;\n   color: #fff !important;\n   font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
