import React, { useState, useEffect, memo } from "react";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { Heading_6 } from "../../commanComponents/NavigationBar/Typography";
import "./DepartmentsListWithUsers.css";
import useDebounce from "../../constants/useDebounce";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import { CreateDepartmentForm } from "../../commanComponents/forms/DeparmentForms";
import NavigationButtonWithDropdown from "../../commanComponents/buttons/NavigationButtonWithDropdown";
import { toast } from "react-toastify";
import { commonApi } from "../../constants/apis/adminCommunication";
import Loader from "../../commanComponents/Loader";
import Spinner from "../../commanComponents/Spinner";

const DepartmentsListWithUsers = memo(({ handleUserIdChange, userId }) => {
   const [searchTerm, setSearchTerm] = useState("");
   const debouncedSearchTerm = useDebounce(searchTerm, 1000);
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [departmentWiseUser, setDepartmentWiseUser] = useState([]);
   const [activeDepartmentId, setActiveDepartmentId] = useState(null);
   const [loading, setLoading] = useState(false);

   const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
   };

   const handleOpenPopup = () => setIsPopupOpen(true);
   const handleClosePopup = () => {
      setIsPopupOpen(false);
   };

   const getDepartmentWiseUser = async () => {
      setLoading(true);
      try {
         const response = await commonApi.getDepartmentWiseUsers(
            debouncedSearchTerm
         );
         if (response?.status === "SUCCESS") {
            setDepartmentWiseUser(response.result);
            setLoading(false);
         } else {
            toast.error(response?.message);
            setDepartmentWiseUser([]);
            setLoading(false);
            throw new Error(response?.message || "Failed to fetch user data");
         }
      } catch (error) {
         setLoading(false);
         console.error("Error fetching department users:", error);
         setDepartmentWiseUser([]);
      }
   };

   useEffect(() => {
      getDepartmentWiseUser();
   }, [debouncedSearchTerm]);

   const handleDropdown = (departmentId) => {
      setActiveDepartmentId(
         activeDepartmentId === departmentId ? null : departmentId
      ); // Toggle dropdown
   };

   return (
      <div className="d-flex flex-column h-100">
         <div>
            <Heading_6
               text="Departments"
               className="fw-bold text-capitalize mb-3"
            />
            <Searchbox onChange={handleSearchChange} />
         </div>
         <div className="DepartmentsListWithUsers pt-2">
            <div
               className="DepartmentsListWithUsersbuttons transparant_Scrollbar"
               id="scrollableDiv"
            >
               {departmentWiseUser?.length > 0 ? (
                  departmentWiseUser?.map((department) => (
                     <NavigationButtonWithDropdown
                        key={department?.id}
                        departmentId={department?.id}
                        departmentName={department?.name}
                        users={department?.user}
                        isOpen={activeDepartmentId === department?.id}
                        handleUserIdChange={handleUserIdChange}
                        handleDropdown={() => handleDropdown(department?.id)}
                        userId={userId}
                     />
                  ))
               ) : (
                  !loading && <h6>No Departments Found...</h6>
               )}

               {loading && (
                  <div className="m-3 text-center">
                     <Spinner />
                  </div>
               )}
            </div>
         </div>
         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            title="Create Department"
         >
            <CreateDepartmentForm
               onClose={handleClosePopup}
               onSuccess={getDepartmentWiseUser} // Refresh the list after successful form submission
            />
         </CenteredPopup>
      </div>
   );
});

export default DepartmentsListWithUsers;
