import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Heading_6 } from "../../commanComponents/NavigationBar/Typography";
import { InputWithLabel } from "../../commanComponents/inputs/InputWithLabel";
import RadioButton from "../../commanComponents/buttons/RadioButtons";
import TextArea from "../../commanComponents/inputs/TextArea";
import {
   commonApi,
   getServerUrl,
} from "../../constants/apis/adminCommunication";
import {
   convertToDateInputFormat,
   formatDate,
   validateAadhaar,
} from "../../constants/Helper/Helpers";
import FileUpload from "../../commanComponents/fileUpload/FileUpload";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import "./contractor.css";
import { MdDeleteOutline } from "react-icons/md";
import Loader from "../../commanComponents/Loader";
import { userRole } from "../../constants/Helper/getUserRole";

const HandleContractorDetails = () => {
   const MAX_FILE_SIZE = 2 * 1024 * 1024;
   const [ZoneList, setZoneList] = useState([]);
   const [wardList, setWardList] = useState([]);
   const [isAadharChanged, setIsAadharChanged] = useState(false);
   const [isPanChanged, setIsPanChanged] = useState(false);
   const [selectedZone, setSelectedZone] = useState([]);
   const [selectedWard, setSelectedWard] = useState([]);
   const [periodOfContract, setPeriodOfContract] = useState("");
   const [validTillContractDate, setValidTillContractDate] = useState("");
   const [zoneLoading, setZoneLoading] = useState(false);
   const [wardLoading, setWardLoading] = useState(false);
   const [selectedFiles, setSelectedFiles] = useState({});
   const [vehicleDetails, setVahicleDetails] = useState([]);
   const [vehicleDetailsError, setVahicleDetailsError] = useState('');
   const [loading, setLoading] = useState(false);
   const [selectedpan, setSelectedpan] = useState({});
   const [docsFromBackend, setDocsFromBackend] = useState([]);
   const [isFileChanged, setIsFileChanged] = useState(false);
   const navigation = useNavigate();
   const [params] = useSearchParams();
   const pageType = params.get("type");
   const contractorDetailId = params.get("contractorDetailId");
   const {
      register,
      handleSubmit,
      setValue,
      watch,
      getValues,
      formState: { errors },
   } = useForm();

   const PeriodOfContractList = [
      { label: "1 Years", value: "1 years" },
      { label: "2 Years", value: "2 years" },
      { label: "3 Years", value: "3 years" },
      { label: "4 Years", value: "4 years" },
      { label: "5 Years", value: "5 years" },
      { label: "6 Years", value: "6 years" },
      { label: "7 Years", value: "7 years" },
      { label: "8 Years", value: "8 years" },
      { label: "9 Years", value: "9 years" },
      { label: "10 Years", value: "10 years" },
   ];

   const handlePeriodOfContractChange = (selected) => {
      setPeriodOfContract(selected);
      const year = +selected?.value?.split(" ")[0];

      if (selected) {
         const today = new Date();
         const futureDate = new Date(
            today.setFullYear(today.getFullYear() + year)
         );
         const formattedDate = futureDate.toISOString().split("T")[0];
         setValidTillContractDate(formattedDate);
      } else {
         setValidTillContractDate("");
      }
   };

   const getActiveZoneList = async () => {
      setZoneLoading(true);
      try {
         const response = await commonApi.getActiveZoneList();
         if (response?.status === "SUCCESS") {
            const options = response.result.map((zone) => ({
               value: zone._id,
               label: zone.name,
            }));
            setZoneLoading(false);

            setZoneList(options);
         } else if (response?.status === "JWT_INVALID") {
            toast.error(`Failed : ${response?.message}`);
            setZoneLoading(false);
         } else {
            toast.error(response?.message);
            setZoneList([]);
            setZoneLoading(false);

            throw new Error(response?.message || "Failed to fetch Zones");
         }
      } catch (error) {
         console.error("Error fetching fetch Zones:", error);
         setZoneList([]);
         setZoneLoading(false);
      }
   };

   const getActiveZoneWiseWardList = async (zondeIds) => {
      setWardLoading(true);

      try {
         const response = await commonApi.fetchActiveZoneWiseWardList(zondeIds);
         if (response?.status === "SUCCESS") {
            const options = response.ward.map((ward) => ({
               value: ward._id,
               label: ward.name,
            }));
            setWardLoading(false);

            setWardList(options);
         } else if (response?.status === "JWT_INVALID") {
            toast.error(`Failed : ${response?.message}`);
            setWardLoading(false);
         } else {
            setWardList([]);
            setWardLoading(false);
         }
      } catch (error) {
         console.error("Error fetching fetch Zones:", error);
         setWardList([]);
         setWardLoading(false);
      }
   };

   const getContractorDetails = async (id) => {
      try {
         setLoading(true);
         const response = await commonApi.getUserProfileData(id);
         if (response?.status === "SUCCESS") {
            setValue("name", response?.user?.name);
            setValue("userId", response?.user?.userId);
            setValue("mobile", response?.user?.mobile);
            setValue("city", response?.user?.city);
            setValue(
               "dob",
               response?.user?.dob
                  ? convertToDateInputFormat(response?.user?.dob)
                  : "--"
            );
            setPeriodOfContract(
               response?.user?.periodOfContract
                  ? {
                       label: `${
                          response?.user?.periodOfContract?.split(" ")[0]
                       } Year`,
                       value: response?.user?.periodOfContract,
                    }
                  : ""
            );
            setValidTillContractDate(response?.user?.validTillContractDate);
            // setValue("email", response?.user?.email);
            setValue("address", response?.user?.address);
            setValue("gender", response?.user?.gender);
            setValue("aadharNo", response?.user?.aadharNo);
            setSelectedZone(() => {
               const zone = response?.user?.zoneId?.map((zone) => {
                  return { label: zone?.name, value: zone?._id };
               });
               return zone;
            });
            setSelectedWard(() => {
               const ward = response?.user?.wardId?.map((ward) => {
                  return { label: ward?.name, value: ward?._id };
               });
               return ward;
            });
            setVahicleDetails(response?.user?.vehicleInfo);
            setDocsFromBackend(response?.user?.documents);
         } else if (response?.status === "JWT_INVALID") {
            toast.error(`Failed : ${response?.message}`);
         } else {
            toast.error(`Failed : ${response?.message}`);
         }
      } catch (error) {
         console.error("Error fetching fetch Zones:", error);
      } finally {
         setLoading(false);
      }
   };

   const addUser = async (data) => {
      console.log({...data});

      if (selectedFiles.length === 0) {
         toast.error("Please upload a document before submitting.");
         return;
      }

      if(vehicleDetails?.length == 0){
         setVahicleDetailsError('Vehicle details must contain at least 1 items')
         return
      }

      const zoneIds = selectedZone?.map((zone) => zone?.value);
      const wardIds = selectedWard?.map((ward) => ward?.value);
      const formData = new FormData();

      const updatedData = {
         ...data,
         zoneId: zoneIds,
         wardId: wardIds,
         periodOfContract: periodOfContract?.value,
         vehicleInfo: vehicleDetails,
         userType: "contractor",
         validTillContractDate,
      };

      formData.append("aadhar", selectedFiles);
      if (selectedpan) {
         formData.append("pancard", selectedpan);
      }
      formData.append("userData", JSON.stringify(updatedData));

      try {
         setLoading(true);
         const response = await commonApi.createUser(formData);
         if (response?.status === "SUCCESS") {
            toast.success(`Success: ${response?.message}`);
            navigation("/manage-contractors");
         } else {
            toast.error(`Failed: ${response?.message}`);
         }
      } catch (error) {
         console.error("Error creating user:", error);
         toast.error("Failed to create user.");
      } finally {
         setLoading(false);
      }
   };

   const updateUserDetails = async (data) => {
      if (selectedFiles.length === 0) {
         toast.error("Please upload a document before submitting.");
         return;
      }

      if(vehicleDetails?.length == 0){
         setVahicleDetailsError('Vehicle details must contain at least 1 items')
         return
      }

      const zoneIds = selectedZone.map((zone) => zone.value);
      const wardIds = selectedWard?.map((ward) => ward?.value);
      const formData = new FormData();

      let oldDocs = docsFromBackend;

      if (isAadharChanged) {
         const index = oldDocs.findIndex((doc) => doc.fieldName === "aadhar");

         if (index !== -1) {
            // Remove the object from the array
            oldDocs.splice(index, 1);
         }

         formData.append("aadhar", selectedFiles);
      }
      if (selectedpan && isPanChanged) {
         const index = oldDocs.findIndex((doc) => doc.fieldName === "pancard");
         if (index !== -1) {
            // Remove the object from the array
            oldDocs.splice(index, 1);
         }

         formData.append("pancard", selectedpan);
      }

      const updatedData = {
         id: contractorDetailId,
         ...data,
         zoneId: zoneIds,
         wardId: wardIds,
         periodOfContract: periodOfContract?.value,
         vehicleInfo: vehicleDetails,
         userType: "contractor",
         validTillContractDate,
         documents: oldDocs,
      };

      formData.append("userData", JSON.stringify(updatedData));

      try {
         setLoading(true);
         const response = await commonApi.updateAdminUser(
            isFileChanged ? formData : JSON.stringify(updatedData),
            isFileChanged
         );
         if (response?.status === "SUCCESS") {
            toast.success(`Success: ${response?.message}`);
            navigation("/manage-contractors");
         } else {
            toast.error(`Failed: ${response?.message}`);
         }
      } catch (error) {
         console.error("Error creating user:", error);
         toast.error("Failed to create user.");
      } finally {
         setLoading(false);
      }
   };

   const handleFileSelection = (files) => {
      const selectedFile = files[0];
      if (files) {
         if (files[0]?.size > MAX_FILE_SIZE) {
            toast.error("File size should not exceed 2MB");
            setSelectedFiles([]);
            return;
         }
         setIsFileChanged(true);
         setIsAadharChanged(true);
         setSelectedFiles(selectedFile);
      } else {
         setIsFileChanged(false);
         setSelectedFiles([]);
      }
   };

   const handlePanSelection = (files) => {
      const selectedFile = files[0];
      if (files) {
         if (files[0]?.size > MAX_FILE_SIZE) {
            toast.error("File size should not exceed 2MB");
            setSelectedpan([]);
            return;
         }
         setIsFileChanged(true);
         setIsPanChanged(true);
         setSelectedpan(selectedFile);
      } else {
         setIsFileChanged(false);
         setSelectedpan([]);
      }
   };

   const addVehicleDetails = () => {
      const vehicleNo = getValues("vehicleNo");
      const vehicleType = getValues("vehicleType");
      const registrationNo = getValues("registrationNo");
      if (!vehicleNo || !vehicleType || !registrationNo) {
         toast.error("All vehicle details fileds are required");
         return;
      }
      const data = {
         vehicleNo,
         vehicleType,
         registrationNo,
      };
      setVahicleDetails((prev) => [...prev, data]);
      setValue("vehicleNo", "");
      setValue("vehicleType", "");
      setValue("registrationNo", "");
   };

   const handleVehicleDelete = (index) => {
      const filterData = vehicleDetails.filter((ele, i) => {
         return i !== index && ele;
      });

      setVahicleDetails(filterData);
   };

   useEffect(() => {
      setValue("gender", "male");
      getActiveZoneList();

      if (pageType !== "add") {
         getContractorDetails(contractorDetailId);
      }
   }, []);

   useEffect(() => {
      const zoneIds = selectedZone.map((zone) => {
         return zone?.value;
      });
      getActiveZoneWiseWardList(zoneIds);
   }, [selectedZone]);

   useEffect(() => {
      if (userRole() !== "zonal") {
         navigation(-1); // redirect to previous page if user dont not have Tab Access
         return;
      }
   }, []);

   return (
      <>
         {loading && <Loader />}
         <div className="w-100 mx-auto UserCrudMain pb-2">
            <div className="p-4 rounded-lg bg-white big_Shadow my-2">
               <div className="mb-4">
                  <Heading_6
                     text=" Contractor Information"
                     className={"greenSubHeading"}
                  />
               </div>
               <div className="form-row">
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"Full Name*"}
                        placeholder={"Enter Full Name"}
                        register={{
                           ...register("name", {
                              required: "Full Name is required",
                           }),
                        }}
                        errorMessage={errors?.name}
                        disabled={pageType == "view"}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"Mobile*"}
                        placeholder={"Enter Mobile Number"}
                        register={{
                           ...register("mobile", {
                              required: "Mobile Number is required",
                           }),
                        }}
                        errorMessage={errors?.mobile}
                        disabled={pageType == "view"}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <label className="name text_color-lable">Gender*</label>
                     <div className="w-100 d-flex flex-wrap mx-auto">
                        <div className="px-2">
                           <RadioButton
                              name="gender"
                              id="male"
                              label="Male"
                              value="male"
                              disabled={pageType == "view"}
                              onChange={() => setValue("gender", "male")}
                              checked={
                                 watch("gender") === "male" ? true : false
                              }
                              register={{
                                 ...register("gender", {
                                    required: "Gender is required",
                                 }),
                              }}
                              errorMessage={errors?.gender?.message}
                           />
                        </div>
                        <div className="px-2">
                           <RadioButton
                              name="gender"
                              id="female"
                              label="Female"
                              value="female"
                              disabled={pageType == "view"}
                              onChange={() => setValue("gender", "female")}
                              checked={
                                 watch("gender") === "female" ? true : false
                              }
                              register={{
                                 ...register("gender", {
                                    required: "Gender is required",
                                 }),
                              }}
                              errorMessage={errors?.gender?.message}
                           />
                        </div>
                        <div className="px-2">
                           <RadioButton
                              name="gender"
                              id="other"
                              label="Other"
                              value="other"
                              disabled={pageType == "view"}
                              onChange={() => setValue("gender", "other")}
                              checked={
                                 watch("gender") === "other" ? true : false
                              }
                              register={{
                                 ...register("gender", {
                                    required: "Gender is required",
                                 }),
                              }}
                              errorMessage={errors?.gender?.message}
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <div className="form-row">
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"DOB*"}
                        placeholder={"Date of Birth"}
                        type="date"
                        register={{
                           ...register("dob", {
                              required: "DOB is required",
                           }),
                        }}
                        disabled={pageType == "view"}
                        errorMessage={errors?.dob}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"Aadhaar Card*"}
                        placeholder={"Enter Aadhaar Card Number"}
                        register={{
                           ...register("aadharNo", {
                              required: "Aadhaar Number is required",
                              validate: validateAadhaar, // Use custom validation function
                           }),
                        }}
                        disabled={pageType == "view"}
                        errorMessage={errors?.aadharNo}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"City*"}
                        placeholder={"Enter City"}
                        register={{
                           ...register("city", {
                              required: "City is required",
                           }),
                        }}
                        disabled={pageType == "view"}
                        errorMessage={errors?.city}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <label className="name text_color-lable">
                        Period of Contract*
                     </label>
                     <Select
                        options={PeriodOfContractList}
                        onChange={handlePeriodOfContractChange}
                        value={periodOfContract ? periodOfContract : ""}
                        placeholder="Select a period of Contract"
                        isDisabled={pageType == "view"}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <InputWithLabel
                        label={true}
                        labelText={"Contract Valid Till Date*"}
                        placeholder={"Contract Valid Till Date"}
                        value={validTillContractDate}
                        disabled={true}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <label className="name text_color-lable">Zone*</label>
                     <Select
                        options={ZoneList}
                        isLoading={zoneLoading}
                        onChange={(selected) => {
                           setSelectedZone(selected);
                        }}
                        isMulti={true}
                        value={selectedZone ? selectedZone : ""}
                        placeholder="Select a Zone"
                        isDisabled={pageType == "view"}
                     />
                  </div>
                  <div className="col-md-4 p-2">
                     <label className="name text_color-lable">Ward*</label>
                     <Select
                        options={wardList}
                        isLoading={wardLoading}
                        onChange={(selected) => {
                           setSelectedWard(selected);
                        }}
                        isMulti={true}
                        value={selectedWard ? selectedWard : ""}
                        placeholder="Select a Ward"
                        isDisabled={pageType == "view"}
                     />
                  </div>
               </div>
               <div className="form-row">
                  <div className="col-md-8 p-2">
                     <TextArea
                        label={"Address*"}
                        placeholder={"Enter Address"}
                        // disabled={isEdit ? false : true}
                        rows={4}
                        register={{
                           ...register("address", {
                              required: "Address is required",
                           }),
                        }}
                        disabled={pageType == "view"}
                        errorMessage={errors.address}
                     />
                  </div>
               </div>
            </div>
            <div className="p-4 rounded-lg bg-white big_Shadow my-2">
               <div
                  className="d-flex align-items-center flex-wrap mb-4"
                  style={{ gap: "3px", display: "flex", alignItems: "center" }}
               >
                  <Heading_6
                     text="Document Upload"
                     className={"greenSubHeading"}
                  />
                  <span className="text-warning">(png, jpeg are allowed*)</span>
               </div>
               <div className="d-flex" style={{ gap: "10px" }}>
                  <FileUpload
                     selectedFiles={selectedFiles}
                     setSelectedFiles={handleFileSelection}
                     slectedFileName={selectedFiles?.name}
                     placeHolder={"Choose Aadhar Card"}
                     label={"Document Upload"}
                     accept="image/png, image/jpeg, image/jpeg, application/pdf"
                     isLabel={false}
                     disabled={pageType == "view"}
                  />
                  <FileUpload
                     selectedFiles={selectedpan}
                     placeHolder={"Choose Pan Card"}
                     setSelectedFiles={handlePanSelection}
                     slectedFileName={selectedpan?.name}
                     label={"Document Upload"}
                     accept="image/png, image/jpeg, image/jpeg, application/pdf"
                     allowMultiple={false}
                     isLabel={false}
                     disabled={pageType == "view"}
                  />
               </div>
               {pageType !== "add" && (
                  <div className="imgWrapper">
                     {docsFromBackend?.length > 0 ? (
                        docsFromBackend?.map((data) => {
                           return (
                              <div>
                                 <p className="text-capitalize">
                                    {data?.fieldName}
                                 </p>
                                 <img
                                    className="common_shadow "
                                    alt={data?.fieldName || "document"}
                                    src={`${getServerUrl()}/getFiles/${
                                       data?.fileUrl
                                    }`}
                                 />
                              </div>
                           );
                        })
                     ) : (
                        <p>No Documents Available !</p>
                     )}
                  </div>
               )}
            </div>

            <div className="p-4 rounded-lg bg-white big_Shadow my-2">
               <div className="d-flex align-items-center justify-content-between ">
                  <div className="mb-4">
                     <Heading_6
                        text="Vehicle Details"
                        className={"greenSubHeading"}
                     />
                  </div>
                  {pageType !== "view" && (
                     <RoundedBtn
                        text={"Add"}
                        className="bg_primary text-white px-4"
                        type={"submit"}
                        isDesabled={pageType === "view" && true}
                        onClick={addVehicleDetails}
                     />
                  )}
               </div>
               <div className="addVehicleInputWrapper">
                  <div>
                     <InputWithLabel
                        label={true}
                        labelText={"Vehicle No*"}
                        placeholder={"Enter Vehicle No"}
                        register={{
                           ...register("vehicleNo"),
                        }}
                        errorMessage={errors?.vehicleNo}
                        disabled={pageType == "view"}
                     />
                  </div>
                  <div>
                     <InputWithLabel
                        label={true}
                        labelText={"Enter Vehicle Type*"}
                        placeholder={"Enter Vehicle Type"}
                        register={{
                           ...register("vehicleType"),
                        }}
                        disabled={pageType == "view"}
                        errorMessage={errors?.vehicleType}
                     />
                  </div>
                  <div>
                     <InputWithLabel
                        label={true}
                        labelText={"Registration No*"}
                        placeholder={"Enter Registration No"}
                        register={{
                           ...register("registrationNo"),
                        }}
                        errorMessage={errors?.registrationNo}
                        disabled={pageType == "view"}
                     />
                  </div>
               </div>
               <div className={"vehicleDetailsWrapper"}>
               {vehicleDetailsError &&  <p className="error-message text-danger ">
                  {vehicleDetailsError}
               </p>}
                  {vehicleDetails?.length > 0 ? (
                     vehicleDetails?.map((vehicleDetails, index) => {
                        return (
                           <div className="YellowCardVehicleWrapper">
                              <div>
                                 <div className="infoTextWrapper">
                                    <h6>Vehicle No:</h6>
                                    <p className="text-capitalize">
                                       {vehicleDetails?.vehicleNo || "--"}
                                    </p>
                                 </div>
                                 <div className="infoTextWrapper">
                                    <h6>Vehicle Type:</h6>
                                    <p className="text-capitalize">
                                       {vehicleDetails?.vehicleType || "--"}
                                    </p>
                                 </div>
                                 <div className="infoTextWrapper">
                                    <h6>Registration No:</h6>
                                    <p className="text-capitalize">
                                       {vehicleDetails?.registrationNo || "--"}
                                    </p>
                                 </div>
                              </div>
                              {pageType !== "view" && (
                                 <div className="VehicledeleteIcon">
                                    <div
                                       onClick={() =>
                                          handleVehicleDelete(index)
                                       }
                                    >
                                       <MdDeleteOutline />
                                    </div>
                                 </div>
                              )}
                           </div>
                        );
                     })
                  ) : (
                     <h6 className="DataNotFoundText">
                        No Vehicle Details Available...
                     </h6>
                  )}
               </div>
            </div>

            {pageType !== "view" && (
               <div className="p-4 rounded-lg my-2">
                  <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper">
                     <RoundedBtn
                        text={pageType == "add" ? "Submit" : "Update"}
                        className="bg_primary text-white px-4 mr-4"
                        type={"submit"}
                        onClick={
                           pageType == "add" ? handleSubmit(addUser) : handleSubmit(updateUserDetails)
                        }
                     />
                     <RoundedBtn
                        text="Cancel"
                        className="bg-secondary text-white px-4"
                        onClick={() => {
                           navigation(-1);
                        }}
                     />
                  </div>
               </div>
            )}
         </div>
      </>
   );
};

export default HandleContractorDetails;
