import AdminLiveTracking from "../components/Live_Tracking/AdminLiveTracking";

const LiveTracking = () => {
  return (
    <>
      <AdminLiveTracking />
    </>
  );
};

export default LiveTracking;
