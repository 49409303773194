import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import "./InputWithIcon.css";

const InputWithIcon = ({
  control,
  name,
  icon,
  placeholder,
  className,
  type = "text",
  handleClick,
  label,
  isDisabled = false,
}) => {
  return (
    <div className={className} title={name}>
      <label htmlFor={name} className="text_color-lable mb-1">
        {label}
      </label>
      <div className={`InputWithIcon position-relative`}>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <>
              <input
                {...field}
                type={type}
                disabled={isDisabled}
                className="pl-2 py-2 w-100 rounded"
                placeholder={placeholder}
              />
              <span
                className="Icon"
                style={{ cursor: "pointer" }}
                onClick={handleClick}
              >
                {icon}
              </span>
            </>
          )}
        />
      </div>
    </div>
  );
};
export default InputWithIcon;
