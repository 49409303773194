import React, { useEffect, useState } from "react";
import RadioButton from "../../commanComponents/buttons/RadioButtons";
import {
   Heading_3,
   Heading_5,
   Heading_6,
} from "../../commanComponents/NavigationBar/Typography";
import { roleAccessData } from "../../constants/utilities/Data";
import "./RoleaccessForm.css";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "../../commanComponents/Spinner";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

const RoleAccessForm = ({ userId }) => {
   const [user, setUser] = useState(null);
   const [loading, setLoading] = useState(false);
   const [noData, setNoData] = useState(false);
   const [tabAccess, setTabAccess] = useState([]);
   const route = useNavigate();

   const getUserWithTabAccess = async () => {
      try {
         setLoading(true);
         setNoData(false);
         const response = await commonApi.getUserWithTabAccessById(userId);
         if (response?.status === "SUCCESS") {
            setUser(response?.user);
            setTabAccess(response?.user?.tabAccess || []);
            if (!response?.user) {
               setNoData(true);
            }
         } else if (response?.status === "JWT_INVALID") {
            toast.error(response?.message);
            setUser(null);
            route("/login");
         } else {
            toast.error(response?.message);
            setUser(null);
            throw new Error(response?.message || "Failed to fetch user data");
         }
      } catch (error) {
         console.error("Error fetching department users:", error);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (userId) {
         getUserWithTabAccess();
      }
   }, [userId]);

   const handleAccessChange = (roleName, accessType) => {
      const roleIndex = tabAccess.findIndex(
         (access) => access.tabName.toLowerCase() === roleName.toLowerCase()
      );

      // Check if the current accessType exists for this role
      if (roleIndex > -1) {
         const currentAccess = tabAccess[roleIndex].access.toLowerCase();

         if (currentAccess === accessType.toLowerCase()) {
            // If the same accessType is being clicked, remove it
            const updatedAccess = tabAccess.filter(
               (access, index) => index !== roleIndex
            );
            setTabAccess(updatedAccess);
         } else {
            // Otherwise, update to the new accessType
            const updatedAccess = tabAccess.map((access, index) =>
               index === roleIndex ? { ...access, access: accessType } : access
            );
            setTabAccess(updatedAccess);
         }
      } else {
         // If no current access for this role, add the new access
         const updatedAccess = [
            ...tabAccess.filter(
               (access) =>
                  access.tabName.toLowerCase() !== roleName.toLowerCase()
            ),
            { tabName: roleName, access: accessType },
         ];
         setTabAccess(updatedAccess);
      }
   };

   const handleSubmit = async () => {
      try {
         const response = await commonApi.giveTabAccessToUser(
            userId,
            tabAccess,
            user?.isActive
         );
         if (response?.status === "SUCCESS") {
            toast.success("User Access Updated successfully!");
            getUserWithTabAccess();
         } else if (response?.status === "JWT_INVALID") {
            toast.error(response?.message);
            route("/login");
            setUser(null);
         } else {
            toast.error(response?.message);
         }
      } catch (error) {
         toast.error("Error updating user access.");
      }
   };

   const handleReset = () => {
      setTabAccess(user?.tabAccess || []);
   };

   const getAccessForRole = (roleName, accessType) => {
      return tabAccess?.some(
         (tab) =>
            tab.tabName.toLowerCase() === roleName.toLowerCase() &&
            tab.access.toLowerCase() === accessType.toLowerCase()
      );
   };

   return (
      <div className="common_shadow rounded px-3 pt-3 pb-1 h-100 bg-white RoleAccessForm_form_wrapper">
         {loading ? (
            <div className="d-flex h-100 w-100 justify-content-center align-items-center text-center">
               <Spinner />
            </div>
         ) : noData ? (
            <div className="d-flex h-100 w-100 justify-content-center align-items-center text-center">
               <Heading_3 text="No Data Found!" />
            </div>
         ) : user ? (
            <>
               <div>
                  <Heading_6
                     text={`User Name: ${user?.userId?.name || '--'}`}
                     className="text-capitalize mb-0"
                  />
                  <div className="text-capitalize">
                     <span>
                        {`Depaertment: ${user?.userId?.departmentId?.name || "--"} `}{" "}
                     </span>
                     ,{" "}
                     <span>
                        {" "}
                        {` Designation: ${user?.userId?.designationId?.name || "--"}`}
                     </span>
                  </div>
               </div>
               <div className="w-100 my-2">
                  <div className="w-100 d-flex rounded-pill py-2 align-items-center px-4 mb-2 bg_Table_Header">
                     <div className="w-50 px-2">
                        <Heading_5
                           text="Tab"
                           className="text-capitalize mb-0"
                        />
                     </div>
                     <div className="w-50 px-2 text-center">
                        <Heading_5
                           text="Access"
                           className="text-capitalize mb-0"
                        />
                     </div>
                  </div>
                  <div className="w-100 RoleAccessForm_form transparant_Scrollbar">
                     {roleAccessData.map((role) => (
                        <div
                           key={role.key}
                           className="w-100 d-flex rounded-pill py-2 align-items-center px-4 my-1"
                        >
                           <div className="w-50 px-2">
                              <Heading_6
                                 text={role.name}
                                 className="text-capitalize mb-0"
                              />
                           </div>
                           <div className="w-50 px-2 text-center">
                              <div className="w-100 d-flex flex-wrap justify-content-center mx-auto">
                                 <RadioButton
                                    name={role.key}
                                    id={`${role.key}Read`}
                                    label="Read"
                                    checked={getAccessForRole(role.key, "read")}
                                    onClick={() =>
                                       handleAccessChange(role.key, "read")
                                    }
                                 />
                                 <RadioButton
                                    name={role.key}
                                    id={`${role.key}Write`}
                                    label="Write"
                                    className="mx-3"
                                    checked={getAccessForRole(
                                       role.key,
                                       "write"
                                    )}
                                    onClick={() =>
                                       handleAccessChange(role.key, "write")
                                    }
                                 />
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
                  <div className="w-100">
                     {checkUserTabAccess("Role_Access")?.access === "write" && (
                        <div className="d-flex align-items-center justify-content-center pt-1 buttonsWrapper">
                           <RoundedBtn
                              text="Submit"
                              className="bg_primary text-white px-4"
                              type="submit"
                              onClick={handleSubmit}
                           />
                           <RoundedBtn
                              onClick={handleReset}
                              text="Reset"
                              className="bg-secondary text-white px-4"
                           />
                        </div>
                     )}
                  </div>
               </div>
            </>
         ) : (
            <div className="d-flex h-100 w-100 justify-content-center align-items-center text-center">
               <h5>Please Select Department And User!</h5>
            </div>
         )}
      </div>
   );
};

export default RoleAccessForm;
