import React, { useEffect, useState } from "react";
import { InputWithLabel } from "../../commanComponents/inputs/InputWithLabel";
import { useForm } from "react-hook-form";
import TextArea from "../../commanComponents/inputs/TextArea";
import "./announcements.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import FileUpload from "../../commanComponents/fileUpload/FileUpload";
import { toast } from "react-toastify";
import {
   commonApi,
   getServerUrl,
} from "../../constants/apis/adminCommunication";
import Loader from "../../commanComponents/Loader";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { userRole } from "../../constants/Helper/getUserRole";
import { convertToDateInputFormat } from "../../constants/Helper/Helpers";

function AnnouncementManagement() {
   const {
      register,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors },
   } = useForm();
   const navgation = useNavigate();
   const [selectedFiles, setSelectedFiles] = useState([]);
   const [attachementFromBackend, setAttachmentFromBackend] = useState([]);
   const [date, setDate] = useState([]);
   const [params] = useSearchParams();
   const [loading, setLoading] = useState(false);
   const pageType = params.get("type");
   const announcementId = params.get("announcementId");

   const fetchAnnouncementDetails = async () => {
      try {
         setLoading(true);
         const serverResponse = await commonApi.getAnnouncementDetails(
            announcementId
         );

         if (serverResponse?.status === "SUCCESS") {
            setValue("title", serverResponse?.announcement?.title);
            setValue("description", serverResponse?.announcement?.description);
            setValue(
               "date",
               serverResponse?.announcement?.date
                  ? convertToDateInputFormat(serverResponse?.announcement?.date)
                  : "--"
            );
            setAttachmentFromBackend(serverResponse?.announcement?.attachment);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "FAILED") {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   const handleUpdate = async (data) => {
      const formData = new FormData();
      formData.append(
         "announcementData",
         JSON.stringify({ ...data, id: announcementId })
      );
      if (selectedFiles.length > 0) {
         selectedFiles.forEach((file, index) => {
            formData.append(`attachment`, file);
         });
      }

      try {
         setLoading(true);
         const serverResponse = await commonApi.updateAnnouncementDetails(
            formData
         );

         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
            navgation(-1);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.error(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   const handleAdd = async (data) => {
      const formData = new FormData();
      formData.append("announcementData", JSON.stringify(data));
      if (selectedFiles.length > 0) {
         selectedFiles.forEach((file, index) => {
            formData.append(`attachment`, file);
         });
      }

      try {
         setLoading(true);
         const serverResponse = await commonApi.addAnnouncementDetails(
            formData
         );

         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
            navgation(-1);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.error(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (pageType === "update" && announcementId) {
         fetchAnnouncementDetails();
      }
   }, [pageType, announcementId]);

   useEffect(() => {
      if (!checkUserTabAccess("Announcements") || userRole() == "zonal") {
         toast.info("You do not have permission to access Announcements");
         navgation(-1); // redirect to previous page if user dont not have Tab Access
         return;
      }
   }, []);

   return (
      <>
         {loading && <Loader />}
         <div className="tableWrapper common_shadow p-4 announcementMainWrapper">
            <h3 className="text-center text-uppercase">
               {pageType == "add" ? "Add Announcement" : "Update Announcement"}
            </h3>
            <div className="inputWrapper mt-4">
               <InputWithLabel
                  label={true}
                  labelText={"Subject*"}
                  placeholder={"Subject*"}
                  register={{
                     ...register("title", {
                        required: "Subject is required",
                     }),
                  }}
                  errorMessage={errors.title}
               />
               <InputWithLabel
                  label={true}
                  labelText={"Publish Date*"}
                  placeholder={"Publish Date*"}
                  type={"date"}
                  register={{
                     ...register("date", {
                        required: "Publish Date is required",
                     }),
                  }}
                  errorMessage={errors?.date}
               />
            </div>
            <div className="descriptionWrapper">
               <TextArea
                  label={"Description*"}
                  placeholder={"Add Description*"}
                  rows={3}
                  register={{
                     ...register("description", {
                        required: "Description is required",
                     }),
                  }}
                  errorMessage={errors.description}
               />
            </div>
            <div className="uploadFileWrapper">
               <FileUpload
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  label={"Upload Attachment"}
                  allowMultiple={true}
               />
            </div>

            {pageType !== "add" && (
               <div className="mt-3">
                  <label className="name text_color-lable">Attachments</label>
                  <div className="imgWrapper mt-0">
                     {(Array.isArray(attachementFromBackend) && attachementFromBackend?.length > 0) ? (
                        attachementFromBackend?.map((img) => {
                           return (
                              <div>
                                 <p>{img?.documentName}</p>
                                 <img
                                    className="common_shadow "
                                    alt={"attachement"}
                                    src={`${getServerUrl()}/getFiles/${img?.fileUrl}`}
                                 />
                              </div>
                           );
                        })
                     ) : (
                        <p>No attachment found!</p>
                     )}
                  </div>
               </div>
            )}

            <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper mt-3">
               {checkUserTabAccess("Announcements")?.access === "write" && (
                  <RoundedBtn
                     text={pageType == "add" ? "Submit" : "Update"}
                     className="bg_primary text-white px-4 mr-4"
                     type={"submit"}
                     onClick={
                        pageType == "add"
                           ? handleSubmit(handleAdd)
                           : handleSubmit(handleUpdate)
                     }
                  />
               )}
               <RoundedBtn
                  text="Cancel"
                  className="bg-secondary text-white px-4"
                  onClick={() => {
                     navgation(-1);
                  }}
               />
            </div>
         </div>
      </>
   );
}

export default AnnouncementManagement;
