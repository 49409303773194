// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentsShowCard {
   display: flex !important;
   gap: 30px !important;
   align-items: center;
   overflow-x: auto;
   padding: 10px;
}

.DocumentsShowCard img {
   height: 330px;
   object-fit: contain;
   border-radius: 8px;
   background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/commanComponents/Images/DocumentsShowCard.css"],"names":[],"mappings":"AAAA;GACG,wBAAwB;GACxB,oBAAoB;GACpB,mBAAmB;GACnB,gBAAgB;GAChB,aAAa;AAChB;;AAEA;GACG,aAAa;GACb,mBAAmB;GACnB,kBAAkB;GAClB,sBAAsB;AACzB","sourcesContent":[".DocumentsShowCard {\n   display: flex !important;\n   gap: 30px !important;\n   align-items: center;\n   overflow-x: auto;\n   padding: 10px;\n}\n\n.DocumentsShowCard img {\n   height: 330px;\n   object-fit: contain;\n   border-radius: 8px;\n   background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
