import ResetPasswordMain from '../../components/ResetPasswordMain'

const ResetPassword = () => {
  return (
    <>
     <ResetPasswordMain />   
    </>
  )
}

export default ResetPassword