import React from "react";
import './inputs.css'

function TextArea({label, placeholder, register, errorMessage, disabled,onChange, rows=1, isLabel=true, value }) {
   return (
      <div className="textArea" title={label}>
     {isLabel && <label>{label}</label>}
         <textarea
            {...register}
            className="form-control"
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            value={value}
            rows={rows}
         />
         {errorMessage && <p className="error-message text-danger">{errorMessage.message}</p>}
      </div> 
   );
}

export default TextArea;
