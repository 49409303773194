import React, { useEffect, useState } from "react";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import { useNavigate } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import useDebounce from "../../constants/useDebounce";
import { isEven } from "../../constants/Helper/NumbersFunction";
import Loader from "../../commanComponents/Loader";
import { formatDate, PaginationSrNo } from "../../constants/Helper/Helpers";
import { userRole } from "../../constants/Helper/getUserRole";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

const TransferRequestList = () => {
   const navigate = useNavigate();
   const [page, setPage] = useState(1);
   const [searchString, setSearchString] = useState("");
   const [trasferReqList, setTrasferReqList] = useState([]);
   const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);

   const { data, isFetching, error } = useQuery(
      ["transferReqList", page, debouncedSearchTerm],
      () => commonApi?.getTransferReqList(page, searchString),
      {
         refetchOnWindowFocus: false,
         onSuccess: (data) => {
            if (data?.status == "SUCCESS") {
               setTrasferReqList(data?.result);
            } else if (data?.status == "JWT_INVALID") {
               toast.error(`Failed : ${data?.message}`);
            }
            if (data?.status == "FAILED") {
               setTrasferReqList([]);
            }
         },
         onError: (error) => {
            toast.error(`Failed : ${error?.message}`);
         },
      }
   );

   useEffect(() => {
      if (!checkUserTabAccess("Garbage_Info") && userRole() !== "zonal") {
         toast.info("You do not have permission to access Garbage Info");
         navigate(-1); // redirect to previous page if user dont not have Tab Access
         return;
      }
   }, []);

   return (
      <div>
         {isFetching && <Loader />}
         <div className="search_btn_wrapper">
            <div className="w-200">
               <Searchbox
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search Requests..."
               />
            </div>
         </div>
         <div className="tableWrapper big_Shadow">
            <div
               className="table-responsive position-relative tablemain"
               style={{ overflowY: "auto", height: "58vh" }}
            >
               <table className="table position-relative w-100">
                  <thead
                     className="rounded-pill w-100 bg_Table_Header border-0"
                     style={{ position: "sticky", top: "-0.04px", zIndex: "1" }}
                  >
                     <tr>
                        <th scope="col" className="col_100px">
                           Sr. No
                        </th>
                        <th scope="col" className="col_250px">
                           Driver Name
                        </th>
                        <th scope="col" className="col_200px">
                           Vehicle No.
                        </th>
                        <th scope="col" className="col_200px">
                           Date
                        </th>
                        <th
                           scope="col"
                           className="col_150px text-center row-fix-to-last table-th-bg-color"
                        >
                           Action
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {trasferReqList?.length > 0 &&
                        trasferReqList?.map((data, index) => {
                           return (
                              <tr
                                 className={`${
                                    isEven(index + 1) && "Table_Data"
                                 }`}
                              >
                                 <th scope="row">
                                    {" "}
                                    {PaginationSrNo(page, index)}
                                 </th>
                                 <td className="text-capitalize">
                                    {data?.driverId?.name || "--"}
                                 </td>
                                 <td className="text-capitalize">
                                    {data?.vehicleNo ? data?.vehicleNo : "--"}
                                 </td>
                                 <td className="text-capitalize">
                                    {data?.createdAt
                                       ? formatDate(data?.createdAt)
                                       : "--"}
                                 </td>
                                 <td className="col_150px row-fix-to-last text-center">
                                    <div
                                       className="mr-2 cursor_pointer"
                                       title="View"
                                       onClick={() => {
                                          navigate(
                                             `/transfer-request/view-transfer-request?transferReqId=${data?._id}`
                                          );
                                       }}
                                    >
                                       {svgIcons.eyeIcon}
                                    </div>
                                 </td>
                              </tr>
                           );
                        })}
                  </tbody>
               </table>
               {trasferReqList?.length == 0 && (
                  <h6 className="DataNotFoundText">No Transfer Request Found...</h6>
               )}
            </div>
         </div>

         <div className="mt-2">
            <PaginationButton
               page={page}
               setPage={setPage}
               totalPages={data?.totalPages || 1}
            />
         </div>
      </div>
   );
};

export default TransferRequestList;
