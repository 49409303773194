import { useNavigate, useSearchParams } from "react-router-dom";
import { TransparentBtnWithBorder } from "../../../commanComponents/buttons/Button";
import PaginationButton from "../../../commanComponents/buttons/PaginationBtn";
import TwoSectionLayout from "../../../commanComponents/ComponentLayouts/TwoSectionLayout";
import { InputWithLabel } from "../../../commanComponents/inputs/InputWithLabel";
import { Heading_5, Heading_6 } from "../../../commanComponents/NavigationBar/Typography";
import { commonApi } from "../../../constants/apis/adminCommunication";
import GarbageCollectionTable from "./GarbageCollectionTable";
import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";

const ViewDetailsGarbageCollection = ({ houseId }) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [garbageList, setGarbageList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [zoneName, setZoneName] = useState("")
  const [wardName, setWardName] = useState("")
  const [params] = useSearchParams()
  const houseNo = params.get("houseNo");

  const route = useNavigate();

  const getHouseCollectionData = useCallback(async () => {
    // API call whether dates are selected or not (for initial load)
    if (!startDate && !endDate) {
      // Initial load without dates
      try {
        const res = await commonApi.getHouseGarbageCollectionList(page, houseId);
        handleApiResponse(res);
      } catch (error) {
        handleApiError(error);
      }
      return;
    }

    // Proceed if both startDate and endDate are present
    if (startDate && endDate) {
      try {
        const res = await commonApi.getHouseGarbageCollectionList(page, houseId, startDate, endDate);
        handleApiResponse(res);
      } catch (error) {
        handleApiError(error);
      }
    }
  }, [page, houseId, startDate, endDate]);

  const handleApiResponse = (res) => {
    if (res?.status === "SUCCESS") {
      setGarbageList(res?.garbageHistory?.result);
      setTotalPages(res?.garbageHistory?.totalPages);
      setZoneName(res?.zoneId?.name)
      setWardName(res?.wardId?.name)
    } else if (res?.status === "JWT_INVALID") {
      toast.error(res?.message || "Please Login!");
      setGarbageList([]);
      setTotalPages(0);
      route("/login");
    } else {
      setGarbageList([]);
      setTotalPages(0);
      toast.error(res?.message || "Something Went Wrong!");
    }
  };

  const handleApiError = (error) => {
    toast.error(error?.message || "Something Went Wrong!");
  };

  // Call the API initially and whenever both startDate and endDate change
  useEffect(() => {
    getHouseCollectionData();
  }, [getHouseCollectionData]);

  // Handle date change and trigger API call when both dates are present
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <TwoSectionLayout
      leftChildren={
        <div className="w-100 h-100 d-flex flex-column">
          <Heading_5 text={`House/Plot No: ${houseNo || '--'}`} className="text-center text-capitalize" />
          <hr />
          <h6 className="mt-4 text-center">Filter by Date</h6>
          <div className="w-100 h-100 d-flex justify-content-between flex-column">
            <div>
              <div className="w-100 my-2">
                <InputWithLabel
                  label={true}
                  labelText="Start Date"
                  placeholder="Enter Start Date"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange} // Handle start date change
                />
              </div>
              <div className="w-100">
                <InputWithLabel
                  label={true}
                  labelText="End Date"
                  placeholder="Enter End Date"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange} // Handle end date change
                />
              </div>
            </div>
            <div>
              <TransparentBtnWithBorder text={zoneName} className="w-100 text-center" />
              <div className="w-100 py-2">
                <TransparentBtnWithBorder text={wardName} className="w-100 text-center" />
              </div>
            </div>
          </div>
        </div>
      }
      rightChildren={
        <>
          <div className="common_shadow rounded px-3 pt-3 pb-1 bg-white GarbageCollectionList">
            <Heading_6 text="Daily Logs" className="text-capitalize" />
            <GarbageCollectionTable data={garbageList} page={page} />
            <div className="py-2">
              <PaginationButton page={page} setPage={setPage} totalPages={totalPages} />
            </div>
          </div>
        </>
      }
    />
  );
};

export default ViewDetailsGarbageCollection;
