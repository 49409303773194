
import './ToggleButton.css';

export const ToggleButton = ({ isActive = false, onChange, disabled }) => {
  return (
    <label className="switch mb-0"  title={isActive? 'Click to Deactive':'Click to Active' }>
      <input
        type="checkbox"
        checked={isActive}
        onChange={onChange} // Updated to onChange
        disabled={disabled}
      />
      <span className="slider round"></span>
    </label>
  );
};
