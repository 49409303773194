import React, { useEffect, useState } from "react";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { ToggleButton } from "../../commanComponents/buttons/ToggleButtons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import { useNavigate } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import useDebounce from "../../constants/useDebounce";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import Loader from "../../commanComponents/Loader";
import Attachment from "../../commanComponents/Attachment";
import Select from "react-select";
import Track from "./Track";
import { PaginationSrNo } from "../../constants/Helper/Helpers";
import { userRole } from "../../constants/Helper/getUserRole";
import { isEven } from "../../constants/Helper/NumbersFunction";

function AllTrackList() {
   const [page, setPage] = useState(1);
   const [searchString, setSearchString] = useState("");
   const [allTrackData, setAllTrackData] = useState([]);
   const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [ZoneList, setZoneList] = useState([]);
   const [zoneLoading, setZoneLoading] = useState(false);
   const [selectedZone, setSelectedZone] = useState("");
   const [trackCords, setTrackCords] = useState([]);
   const navigation = useNavigate()

   const { data, isFetching, error } = useQuery(
      ["allTracks", page, debouncedSearchTerm, selectedZone],
      () => commonApi?.getAllTrackList(page, searchString, selectedZone?.value),
      {
         refetchOnWindowFocus: false, 
         onSuccess: (data) => {
            if (data?.status == "SUCCESS") {
               setAllTrackData(data?.track);
            } else if (data?.status == "JWT_INVALID") {
               toast.error(`Failed : ${data?.message}`);
            }
            if (data?.status == "FAILED") {
               setAllTrackData([]);
            }
         },
         onError: (error) => {
            toast.error(`Failed : ${error?.message}`);
         },
      }
   );

   const getActiveZoneList = async () => {
      setZoneLoading(true);
      try {
         const response = await commonApi.getActiveZoneList();
         if (response?.status === "SUCCESS") {
            const options = response.result.map((zone) => ({
               value: zone._id,
               label: zone.name,
            }));
            setZoneLoading(false);
            setZoneList(options);
         } else if (response?.status === "JWT_INVALID") {
            toast.error(`Failed : ${response?.message}`);
            setZoneLoading(false);
         } else {
            toast.error(response?.message);
            setZoneList([]);
            setZoneLoading(false);

            throw new Error(response?.message || "Failed to fetch Zones");
         }
      } catch (error) {
         console.error("Error fetching fetch Zones:", error);
         setZoneList([]);
         setZoneLoading(false);
      }
   };

   const handleLocation = (data) => {
      const locations = [];
      data?.map((ele) => {
         const zone = []
         ele?.map((cords) => {
            zone.push({ lat: cords.latitude, lng: cords.longitude });
         });
         locations.push(zone)
      });
      setTrackCords(locations);
   };

   const handleClosePopup = () => setIsPopupOpen(false);

   useEffect(() => {
      getActiveZoneList();
   }, []);

   useEffect(()=>{
      if (userRole() !== 'zonal') {
         navigation(-1); // redirect to previous page if user dont not have Tab Access
        return;
     }
     },[])

   return (
      <div>
         {isFetching && <Loader />}
         <div className="search_btn_wrapper">
            <div className="w-200 ">
               <Searchbox
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search Track..."
               />
            </div>
            <div style={{ width: "200px" }}>
               <Select
                  options={[{label:'All Tracks', value:''}, ...ZoneList]}
                  isLoading={zoneLoading}
                  onChange={(selected) => {
                     setSelectedZone(selected);
                  }}
                  styles={{
                     menu: (provided) => ({
                        ...provided,
                        zIndex: 10001,
                     }),
                     menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 10001,
                     }),
                  }}
                  value={selectedZone ? selectedZone : ""}
                  placeholder="Filter By zone"
               />
            </div>
         </div>
         <div className="tableWrapper common_shadow">
            <div
               className="table-responsive position-relative tablemain"
               style={{ overflowY: "auto", height: "58vh" }}
            >
               <table className="table position-relative w-100">
                  <thead
                     className="rounded-pill w-100 bg_Table_Header border-0"
                     style={{ position: "sticky", top: "-0.04px", zIndex: "1" }}
                  >
                     <tr>
                        <th scope="col" className="col_100px">
                           Sr. No
                        </th>
                        <th scope="col" className="col_250px">
                           Track Name
                        </th>
                        <th scope="col" className="col_250px">
                           Zone
                        </th>
                        <th scope="col" className="col_250px">
                           Ward
                        </th>
                        <th scope="col" className="col_100px text-center row-fix-to-last table-th-bg-color">
                           Action
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {allTrackData?.length > 0 &&
                        allTrackData?.map((data, index) => {
                           return (
                              <tr className={`${
                                 isEven(index + 1) && "Table_Data"
                              }`}>
                                 <th>{PaginationSrNo(page, index)}</th>
                                 <td className="text-capitalize">{data?.name ? data?.name : "--"}</td>
                                 <td className="text-capitalize">
                                    {data?.zoneId?.name
                                       ? data?.zoneId?.name
                                       : "--"}
                                 </td>
                                 <td className="text-capitalize">
                                    {data?.wardId?.name
                                       ? data?.wardId?.name
                                       : "--"}
                                 </td>
                                 <td className="row-fix-to-last text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                       <div
                                          className="mr-2 cursor_pointer"
                                          onClick={() => {
                                             setIsPopupOpen(true);
                                             handleLocation(data?.trackCords);
                                          }}
                                          title="View"
                                       >
                                          {svgIcons.eyeIcon}
                                          {/* <EditIcon /> */}
                                       </div>
                                    </div>
                                 </td>
                              </tr>
                           );
                        })}
                  </tbody>
               </table>
               {allTrackData?.length == 0 && (
                  <h6 className="DataNotFoundText">No Tracks Found...</h6>
               )}
            </div>
         </div>

         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            // title="Attachment"
            style={{ maxWidth: "80%", height: "90vh" }}
         >
            <Track locations={trackCords} />
         </CenteredPopup>

         <div className="mt-2">
            <PaginationButton
               page={page}
               setPage={setPage}
               totalPages={data?.totalPages || 1}
            />
         </div>
      </div>
   );
}

export default AllTrackList;
