import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import AdminRoute from "./AdminRoute";
import Layout from "../pages/Layout";
import Login from "../pages/Login";
import ResetPassword from "../pages/GarbageCollection/ResetPassword";
import ForgotPassword from "../pages/ForgotPassword";

const Router = () => {
   return (
      <Routes>
         <Route path="/login" element={<Login />} />
         <Route path="/reset-password" element={<ResetPassword />} />
         <Route path="/forgot-password" element={<ForgotPassword />} />
         {/* Nested routing using outlet */}
         <Route path="*" element={<Layout />}>
            <Route
               path="*"
               element={
                  <ProtectedRoutes>
                    <AdminRoute />{" "}
                  </ProtectedRoutes>
               }
            />
         </Route>
      </Routes>
   );
};

export default Router;
