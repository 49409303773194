import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import MapComponent from "./MapComponent";
import { userRole } from "../../constants/Helper/getUserRole";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import { toast } from "react-toastify";
import { commonApi } from "../../constants/apis/adminCommunication";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { addHouses } from "../../store/actions/liveTracking";
import SingleMapComponents from "./SingleMapComponents";
import MapWithDirections from "./DummyComponents";

const AdminLiveTracking = () => {
  const route = useNavigate();
  const dispatch = useDispatch();
  const [selectedzoneList, setSelectedZoneList] = useState(null);
  const [zoneList, setZoneList] = useState([]);
  const [selectedWardList, setSelectedTrack] = useState(null);
  const [wardList, setWardList] = useState([]);
  const [tracksList, setTracksList] = useState([]);
  const [trackListLoading, setTrackListLoading] = useState(false);
  const [locations, setLocations] = useState([]); // Array to store multiple locations
  const { zoneId, trackId } = useSelector((state) => state.liveTracking);

  const customStyles = useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        padding: "0 1rem",
        minWidth: "200px",
      }),
      menu: (provided) => ({
        ...provided,
        padding: "0 0",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: "0",
      }),
    }),
    []
  );
  useEffect(() => {
    // const socketConnection = io("http://localhost:3020", {
    const socketConnection = io("https://gotbackend.allindianitservices.com", {
      transports: ["polling", "websocket"],
    });

    socketConnection.on("connect", () => {
      console.log("Connected to the socket server", socketConnection.id);
    });

    socketConnection.on("get-location", (data) => {
      console.log(data);

      // Conditionally update locations array only when zoneId or trackId are null or undefined
      // if (!zoneId && !trackId) {
      setLocations((prevLocations) => {
        // Check if the trackId already exists in the locations array
        const locationIndex = prevLocations.findIndex(
          (location) => location.trackId === data.trackId
        );

        if (locationIndex !== -1) {
          // Update the existing location entry with the same trackId
          const updatedLocations = [...prevLocations];
          updatedLocations[locationIndex] = {
            ...updatedLocations[locationIndex],
            latitude: data.latitude,
            longitude: data.longitude,
          };
          return updatedLocations;
        } else {
          // Add a new location entry if the trackId does not exist
          return [
            ...prevLocations,
            {
              latitude: data.latitude,
              longitude: data.longitude,
              trackId: data.trackId, // Include the trackId for uniqueness
              zoneId: data.zoneId, // Add zoneId if needed for future reference
            },
          ];
        }
      });
      // }
    });

    socketConnection.on("logs", (error) => {
      console.error("logs", error);
    });

    socketConnection.on("connect_error", (error) => {
      console.error("Connection Error:", error);
    });

    socketConnection.on("disconnect", (reason) => {
      console.log("Disconnected:", reason);
    });

    // Cleanup the socket connection
    return () => {
      socketConnection.disconnect();
    };
  }, [zoneId, trackId]);
  // Filter locations based on zoneId and trackId, if selected
  const filteredLocations = useMemo(() => {
    if (zoneId && trackId) {
      // If zoneId and trackId are present, filter the locations array
      return locations.filter(
        (location) => location.zoneId === zoneId && location.trackId === trackId
      );
    }
    // Otherwise, return all locations
    return locations;
  }, [zoneId, trackId, locations]);
  const getActiveZone = useCallback(async () => {
    try {
      const res = await commonApi.getActiveZones();
      if (res?.status === "SUCCESS") {
        const options = res.result.map((zone) => ({
          value: zone._id,
          label: zone.name,
        }));
        setZoneList(options);
      } else if (res?.status === "JWT_INVALID") {
        toast.error(res?.message || "Please Log In!");
      } else {
        toast.error(res?.message || "Something Went Wrong!");
      }
    } catch (error) {
      toast.error(error?.message || "Something Went Wrong!");
    }
  }, []);
  const getAllHouses = useCallback(async () => {
    try {
      const res = await commonApi.liveTrackingGetAllHouses();
      if (res?.status === "SUCCESS") {
        dispatch(addHouses(res?.coordinates));
      } else if (res?.status === "JWT_INVALID") {
        toast.error(res?.message || "Please Log In!");
      } else {
        toast.error(res?.message || "Something Went Wrong!");
      }
    } catch (error) {
      toast.error(error?.message || "Something Went Wrong!");
    }
  }, []);

  const liveTrackingFilterFunction = useCallback(
    async (zoneId) => {
      try {
        setTrackListLoading(true);
        const formData = {
          zoneId: zoneId || selectedzoneList?.value,
          trackId: selectedWardList?.value || "",
        };

        const res = await commonApi.liveTrackingFilters(formData);
        if (res?.status === "SUCCESS") {
          setTracksList(res?.track);
          const options = res.track.map((ward) => ({
            value: ward._id,
            label: ward.name,
          }));
          setWardList(options);
        } else if (res?.status === "JWT_INVALID") {
          toast.error(res?.message || "Please Log In!");
        } else {
          toast.error(res?.message || "Something Went Wrong!");
        }
      } catch (error) {
        toast.error(error?.message || "Something Went Wrong!");
      } finally {
        setTrackListLoading(false);
      }
    },
    [selectedzoneList, selectedWardList]
  );

  useEffect(() => {
    getActiveZone();
    getAllHouses();
    liveTrackingFilterFunction();
  }, [getActiveZone, liveTrackingFilterFunction]);

  useEffect(() => {
    if (userRole() !== "zonal" && !checkUserTabAccess("Live_Tracking")) {
      toast.info("You do not have permission to access Live Tracking");
      route(-1);
    }
  }, [route]);

  const handleZoneChange = useCallback(
    (selected) => {
      setSelectedZoneList(selected);
      liveTrackingFilterFunction(selected.value); // Trigger filter based on zone selection
    },
    [liveTrackingFilterFunction]
  );

  const handleTrackChange = useCallback((selected) => {
    setSelectedTrack(selected);
  }, []);
  return (
    !trackListLoading && (
      <div className="w-100 mx-auto GarbageCollectionLayout LiveTracking">
        <div
          className="d-flex align-items-center w-100 mx-auto"
          style={{ gap: "10px" }}
        >
          {/* <Select
            value={selectedzoneList}
            onChange={handleZoneChange} // Memoized handler
            options={[{ label: "All Zones", value: "" }, ...zoneList]}
            placeholder="Select Zone"
            styles={customStyles}
          />

          <Select
            options={[{ label: "All Tracks", value: "" }, ...wardList]}
            value={selectedWardList}
            onChange={handleTrackChange} // Memoized handler
            placeholder="Select Track"
            styles={customStyles}
          /> */}
        </div>
        {zoneId && trackId ? (
          <>
            {/* <SingleMapComponents
              location={filteredLocations}
              zoneId={zoneId}
              trackId={trackId}
            /> */}
            <MapWithDirections
              location={filteredLocations}
              zoneId={zoneId}
              trackId={trackId}
            />
          </>
        ) : (
          <div className="MapWrapper big_Shadow rounded-lg bg-white my-2">
            <MapComponent location={locations} />
          </div>
        )}
      </div>
    )
  );
};

export default React.memo(AdminLiveTracking);
