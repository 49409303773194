// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateDepartmentForm form{
height: max-content;
display: flex;
flex-direction: column;
flex-grow: 1;
justify-content: center;
gap: 5px;
}

.CreateDepartmentForm .inputwrapper{
    max-width: 300px;
}
.CreateDepartmentForm .buttonsWrapper{
    gap: .7em;
}
.DesignationForm form{
height: max-content;
display: flex;
flex-direction: column;
flex-grow: 1;
justify-content: center;
}

.DesignationForm .buttonsWrapper{
    gap: .7em;
}


.DesignationForm .inputwrapper{
    max-width: 300px;
}

/* ----------- garbage Form ------------- */

.GarbageForm{
    max-width: 600px;
}

.GarbageForm .buttonsWrapper {
    gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/commanComponents/forms/Forms.css"],"names":[],"mappings":"AAAA;AACA,mBAAmB;AACnB,aAAa;AACb,sBAAsB;AACtB,YAAY;AACZ,uBAAuB;AACvB,QAAQ;AACR;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,SAAS;AACb;AACA;AACA,mBAAmB;AACnB,aAAa;AACb,sBAAsB;AACtB,YAAY;AACZ,uBAAuB;AACvB;;AAEA;IACI,SAAS;AACb;;;AAGA;IACI,gBAAgB;AACpB;;AAEA,2CAA2C;;AAE3C;IACI,gBAAgB;AACpB;;AAEA;IACI,QAAQ;AACZ","sourcesContent":[".CreateDepartmentForm form{\nheight: max-content;\ndisplay: flex;\nflex-direction: column;\nflex-grow: 1;\njustify-content: center;\ngap: 5px;\n}\n\n.CreateDepartmentForm .inputwrapper{\n    max-width: 300px;\n}\n.CreateDepartmentForm .buttonsWrapper{\n    gap: .7em;\n}\n.DesignationForm form{\nheight: max-content;\ndisplay: flex;\nflex-direction: column;\nflex-grow: 1;\njustify-content: center;\n}\n\n.DesignationForm .buttonsWrapper{\n    gap: .7em;\n}\n\n\n.DesignationForm .inputwrapper{\n    max-width: 300px;\n}\n\n/* ----------- garbage Form ------------- */\n\n.GarbageForm{\n    max-width: 600px;\n}\n\n.GarbageForm .buttonsWrapper {\n    gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
