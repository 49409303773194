import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { commonApi } from '../../constants/apis/adminCommunication';
import { toast } from 'react-toastify';
import Spinner from '../../commanComponents/Spinner';
import { userRole } from '../../constants/Helper/getUserRole';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = () => {
    const [loading, setLoading] = useState(false);
    const [complaintData, setComplaintData] = useState({
        totalComplaints: 0,
        pendingComplaints: 0,
        resolvedComplaints: 0
    });

    const fetchGrivanceData = async () => {
        try {
           setLoading(true);
           const serverUrl = await userRole() === "zonal" ? commonApi.getGrivanceDataforZonal() : commonApi.getGrivanceData()
           const serverResponse = await serverUrl
           if (serverResponse?.status === "SUCCESS") {
            setComplaintData(serverResponse?.complaintData);
           } else if (serverResponse?.status === "JWT_INVALID") {
              toast.info(`Failed : ${serverResponse?.message}`);
           } else if (serverResponse?.status === "FAILED") {
              toast.error(`Failed : ${serverResponse?.message}`);
           }
        } catch (error) {
           toast.error(`Failed : ${error.message}`);
        } finally {
           setLoading(false);
        }
     };
  
     useEffect(() => {
        fetchGrivanceData();
     }, []);

    const data = {
        labels: ['Resolved', 'Pending'],
        datasets: [
            {
                label: 'Grievance Complaints',
                data: [complaintData.resolvedComplaints, complaintData.pendingComplaints],
                backgroundColor: ['#4CAF50', '#FFA726'],
                hoverBackgroundColor: ['#45a049', '#FF9800'],
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        cutout: '62%',
        plugins: {
            tooltip: {
                enabled: true,
            },
            legend: {
                display: false, 
            },
        },
    };

    return (
        <div className="mt-4 DoughnutChartWrapper big_Shadow">
            <h5 className='text-center mb-4'>Grievance Complaints</h5>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px', fontSize:'15px', fontWeight:'500' }}>
                <div style={{ backgroundColor: '#2196F3', color: 'white', padding: '5px 10px', borderRadius: '5px' }}>
                    {complaintData.totalComplaints} Total
                </div>
                <div style={{ backgroundColor: '#4CAF50', color: 'white', padding: '5px 10px', borderRadius: '5px' }}>
                    {complaintData.resolvedComplaints} Resolved
                </div>
                <div style={{ backgroundColor: '#FFA726', color: 'white', padding: '5px 10px', borderRadius: '5px' }}>
                    {complaintData.pendingComplaints} In Progress
                </div>
            </div>
            <div style={{ position: 'relative' }}>
                <div style={{margin:'auto', marginTop:'20px'}}>
                <Doughnut data={data} options={options} />
                </div>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    fontSize: '22px',
                    fontWeight: 'bold',
                    color: '#4CAF50'
                }}>
                    <div>{loading ? <Spinner /> : 'Grievance'}</div>
                </div>
            </div>
        </div>
    );
};

export default DoughnutChart;
