import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  house: [],
  zoneId: "",
  trackId: "",
  location: {},
  locations: [],
};

export const liveTrackingAction = createSlice({
  name: "liveTracking",
  initialState,
  reducers: {
    addHouses: (state, action) => {
      state.house = action.payload;
    },
    clickParticularVehicle: (state, action) => {
      state.zoneId = action.payload.zoneId;
      state.trackId = action.payload.trackId;
    },
    updateParticularVehicleLocation: (state, action) => {
      state.location = action.payload;
    },
    addOrUpdateLocation: (state, action) => {
      const { trackId, latitude, longitude, zoneId } = action.payload;
      const locationIndex = state.locations.findIndex(
        (location) => location.trackId === trackId
      );

      if (locationIndex !== -1) {
        // Update the existing location entry
        state.locations[locationIndex] = {
          ...state.locations[locationIndex],
          latitude,
          longitude,
        };
      } else {
        // Add a new location entry
        state.locations.push({
          latitude,
          longitude,
          trackId,
          zoneId,
        });
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addHouses,
  clickParticularVehicle,
  updateParticularVehicleLocation,
  addOrUpdateLocation,
} = liveTrackingAction.actions;

export default liveTrackingAction.reducer;
