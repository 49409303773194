import { PaginationSrNo } from "../../constants/Helper/Helpers";
import { isEven } from "../../constants/Helper/NumbersFunction";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import "./GarbageCollectionList.css";

const GarbageCollectionList = ({
  data,
  page,
  HandleGarbageCollectionId,
}) => {
  return (
    <div className="GarbageCollectionList">
      <table className="table w-100 overflow-auto">
        <thead
          className="w-100  border-0"
          style={{ position: "sticky", top: "0", right: "0", zIndex: "1" }}
        >
          <tr className="bg_Table_Header">
            <th scope="col" className="col_100px">Sr. No</th>
            <th scope="col" className="col_200px">Zone</th>
            <th scope="col" className="col_200px">Ward/Area</th>
            <th scope="col" className="col_200px">House/Plot No.</th>
            <th scope="col" className="col_250px">
              Garbage Type
            </th>
          </tr>
          <tr className="py-4">
            <th scope="col" className="text-capitalize py-3" colSpan={4}></th>
            <th scope="col" className="text-capitalize p-0 position-relative">
              <div className="d-flex garbage_type" >
                <span className="bg_Table_Header py-1 px-4 col_100px" style={{borderRadius:'5px 0px 0px 5px'}}>Dry</span>
                <span className="bg_Table_Header py-1 px-4 col_100px" style={{borderRadius:'0px 5px 5px 0px'}}>Wet</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => (
            <tr key={item._id || i}>
              <th
                scope="row"
                className={` ${isEven(i + 1) && "Table_Data"}`}
              >
                {PaginationSrNo(page, i)}
              </th>
              <td
                className={`text-capitalize ${isEven(i + 1) && "Table_Data"}`}
              >
                { item?.zoneId.name || "--"}
              </td>
              <td
                className={`text-capitalize ${isEven(i + 1) && "Table_Data"}`}
              >
                {item?.wardId?.name || "--"}
              </td>
              <td
                className={`text-capitalize ${isEven(i + 1) && "Table_Data"}`}
              >
                {item?.houseId?.plotNo || "--"}
              </td>
              <td
                className={`text-capitalize ${isEven(i + 1) && "Table_Data"}`}
              >
                <div className="d-flex">
                  <span className="px-1 col_100px">
                    {item?.dryWasteCollected} Kg
                  </span>
                  <span className="px-1 col_100px">
                    {item?.wetWasteCollected} Lr
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {(!data || data?.length == 0) && (
                  <h6 className="DataNotFoundText">
                     No garbage collection data found, Please select filter to see data...
                  </h6>
               )}
    </div>
  );
};

export default GarbageCollectionList;
