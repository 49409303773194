import { memo, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ZonalNavigationButtonWithDropdown } from "../commanComponents/buttons/NavigationButtonWithDropdown";
import CenteredPopup from "../commanComponents/Modals/CenteredPopup";
import { RoundedbuttonWithIcon } from "../commanComponents/buttons/NavigationButtons";
import { svgIcons } from "../constants/utilities/ImagesExport/Icons";
import Searchbox from "../commanComponents/inputs/Searchbox";
import TwoSectionLayout from "../commanComponents/ComponentLayouts/TwoSectionLayout";
import { Heading_6 } from "../commanComponents/NavigationBar/Typography";
import { commonApi } from "../constants/apis/adminCommunication";
import useDebounce from "../constants/useDebounce";
import { useQueryClient, useInfiniteQuery, useMutation } from "react-query";
import AddZoneForm from "../commanComponents/forms/AddZoneForm";
import { toast } from "react-toastify";
import WardList from "../components/ZoneAndWard/WardList";
import Spinner from "../commanComponents/Spinner";
import { checkUserTabAccess } from "../constants/Helper/checkUserTabAccess";
import { useNavigate } from "react-router-dom";
import BulkUploadExSheetBtn from "../commanComponents/buttons/BulkUploadExSheetBtn";
import FileSaver from "file-saver";

const ZoneAndWard = memo(() => {
   const [searchTerm, setSearchTerm] = useState("");
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [bulkUploadLoading, setBulkUploadLoading] = useState(false);
   const [openDropdowns, setOpenDropdowns] = useState({});
   const [selectedBulkUploadFile, setSelectedBulkUploadFile] = useState({});
   const [zone, setZone] = useState(null); // Use null to indicate no zone is selected
   const debouncedSearchTerm = useDebounce(searchTerm, 1000);
   const queryClient = useQueryClient();
   const [isListUpdated, setIsListUpdated] = useState(false);
   const route = useNavigate();

   const fetchZoneList = useCallback(
      async ({ pageParam = 1 }) => {
         const response = await commonApi.getZoneList(
            pageParam,
            debouncedSearchTerm
         );

         if (!response || !response.result) {
            return { result: [] };
         }
         return response;
      },
      [debouncedSearchTerm]
   );

   const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
      useInfiniteQuery(
         ["zones", { searchString: debouncedSearchTerm }, isListUpdated],
         fetchZoneList,
         {
            refetchOnWindowFocus: false,
            getNextPageParam: (lastPage, pages) => {
               if (lastPage.result.length < 10) return undefined;
               return pages.length + 1;
            },
         }
      );

   const mutation = useMutation((id) => commonApi.changeZoneStatus(id), {
      onSuccess: (data) => {
         if (data.status === "SUCCESS") {
            toast.success("Zone status updated successfully!");
            queryClient.invalidateQueries("zones");
         } else if (data?.status === "JWT_INVALID") {
            toast.error(`Failed: ${data?.message}`);
         } else {
            toast.error(data.message || "Error updating status!");
         }
      },
      onError: (error) => {
         toast.error(`Error updating status: ${error.message}`);
      },
   });

   const HandlebulkUploadZones = async () => {
      const formData = new FormData();

      if (!selectedBulkUploadFile?.type) {
         return;
      }

      formData.append("file", selectedBulkUploadFile);
      try {
         setBulkUploadLoading(true);
         const serverResponse = await commonApi.bulkUploadZones(formData);

         if (serverResponse?.status === "SUCCESS") {
            toast.success(`Success : ${serverResponse?.message}`);
            setIsListUpdated((prev) => !prev);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.error(`Failed : ${serverResponse?.message}`);
         } else {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setBulkUploadLoading(false);
         setSelectedBulkUploadFile({});
      }
   };

   const downloadTemplate = async () => {
      try {
         const serverResponse = await commonApi.downloadZoneTamplate();

         if (serverResponse) {
            // Create a Blob from the response
            const file = new Blob([serverResponse], {
               type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(file, `zone-template.xlsx`);
            toast.success("Zone tamplate downloaded successfully!");
         } else {
            toast.info("Failed to download Zone tamplate. Please try again.");
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      }
   };

   const handleZoneEdit = (id) => {
      // Find the zone with the given ID from the available data
      const zoneToEdit = data?.pages
         .flatMap((page) => page.result)
         .find((zone) => zone._id === id);
      if (zoneToEdit) {
         setZone(zoneToEdit); // Set the zone data for editing
         setIsPopupOpen(true); // Open the popup
      } else {
         toast.error("Zone not found in the current data!");
      }
   };

   const handleOpenPopup = () => {
      setZone(null); // Reset zone state for creating a new zone
      setIsPopupOpen(true);
   };

   const handleClosePopup = () => {
      setIsPopupOpen(false);
      setZone(null); // Clear zone data when closing the popup
   };

   const toggleDropdown = (zoneId) => {
      setOpenDropdowns((prev) => ({
         ...prev,
         [zoneId]: !prev[zoneId],
      }));
   };

   useEffect(() => {
      if (!checkUserTabAccess("Zone_Ward")) {
         toast.info("You do not have permission to access Zone Ward");
         route(-1); // redirect to previous page if user dont not have Tab Access
         return;
      }
   }, []);

   useEffect(() => {
      HandlebulkUploadZones();
   }, [selectedBulkUploadFile]);

   return (
      <TwoSectionLayout
         leftChildren={
            <div className="d-flex flex-column h-100">
               <div>
                  <div className="d-flex align-items-center mb-3 justify-content-between">
                     <Heading_6
                        text={`Zone (${data?.pages[0].zoneCount || 0})`}
                        className="fw-bold text-capitalize"
                     />
                     {checkUserTabAccess("Zone_Ward")?.access === "write" && (
                        <BulkUploadExSheetBtn
                           btnText={"Bulk Upload"}
                           setSelectedBulkUploadFile={setSelectedBulkUploadFile}
                           selectedBulkUploadFile={selectedBulkUploadFile}
                           btnLoading={bulkUploadLoading}
                           handleDownloadTemplate={downloadTemplate}
                        />
                     )}
                  </div>
                  <Searchbox
                     value={searchTerm}
                     onChange={(e) => setSearchTerm(e.target.value)}
                  />
               </div>
               <div className="departmentButtons pt-2">
                  <div className="departmentNavButtons" id="scrollableDiv">
                     <InfiniteScroll
                        dataLength={data?.pages?.length || 0}
                        next={fetchNextPage}
                        hasMore={hasNextPage}
                        scrollableTarget="scrollableDiv"
                     >
                        {data?.pages
                           .flatMap((page) => page.result)
                           .map((zone) => (
                              <ZonalNavigationButtonWithDropdown
                                 key={zone?._id}
                                 zoneData={zone}
                                 isOpen={!!openDropdowns[zone?._id]}
                                 handleDropdown={() =>
                                    toggleDropdown(zone?._id)
                                 }
                                 handleToggle={() => mutation.mutate(zone?._id)}
                                 handleEdit={() => handleZoneEdit(zone?._id)}
                              />
                           ))}
                        {!data?.pages.flatMap((page) => page.result).length &&
                           !isFetching && (
                              <h6 className="DataNotFoundText">
                                 No Zone Found...
                              </h6>
                           )}
                     </InfiniteScroll>

                     {isFetching && (
                        <div className="m-3 text-center">
                           <Spinner />
                        </div>
                     )}
                  </div>
                  {checkUserTabAccess("Zone_Ward")?.access === "write" && (
                     <div>
                        <RoundedbuttonWithIcon
                           onClick={handleOpenPopup}
                           text="ADD ZONE"
                           className="text-uppercase w-100"
                           icon={svgIcons.PlusIcon}
                        />
                     </div>
                  )}
               </div>
               <CenteredPopup
                  isOpen={isPopupOpen}
                  onClose={handleClosePopup}
                  title={zone ? "Edit Zone" : "Create Zone"}
               >
                  <AddZoneForm zone={zone} onClose={handleClosePopup} />
               </CenteredPopup>
            </div>
         }
         rightChildren={<WardList />}
      />
   );
});

export default ZoneAndWard;
