// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OTPInput {
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.openotp{
  max-height: 500px;
}
.closeotp{
  max-height: 0;
}
.OTPInput .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 10px;
    max-width: 320px;
  }
  
  
  .OTPInput .form .inputs {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .OTPInput .form .inputs input {
    height: 1.6em;
    width: 1.6em;
    outline: none;
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    border-radius: 0.3em;
    border: 1px solid gray;
    background-color: var(--Primary_color);
  }`, "",{"version":3,"sources":["webpack://./src/components/Forgot Password/OTPInput.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;AACf;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,gBAAgB;EAClB;;;EAGA;IACE,aAAa;IACb,8BAA8B;IAC9B,SAAS;EACX;;EAEA;IACE,aAAa;IACb,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,oBAAoB;IACpB,sBAAsB;IACtB,sCAAsC;EACxC","sourcesContent":[".OTPInput {\n  transition: all 0.5s ease-in-out;\n  overflow: hidden;\n}\n.openotp{\n  max-height: 500px;\n}\n.closeotp{\n  max-height: 0;\n}\n.OTPInput .form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    position: relative;\n    overflow: hidden;\n    padding: 10px;\n    max-width: 320px;\n  }\n  \n  \n  .OTPInput .form .inputs {\n    display: flex;\n    justify-content: space-between;\n    gap: 10px;\n  }\n  \n  .OTPInput .form .inputs input {\n    height: 1.6em;\n    width: 1.6em;\n    outline: none;\n    text-align: center;\n    font-size: 1.5em;\n    color: #fff;\n    border-radius: 0.3em;\n    border: 1px solid gray;\n    background-color: var(--Primary_color);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
