// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textArea textarea{
    border: 1px solid #dadada;
    color: #333;
    font-size: 16px;
    border: 1px solid #8A92A6 !important;
    max-height: 120px;
}

.textArea textarea::placeholder{
    color: #b8c0d2 ;
}
.textArea textarea:disabled{
    background-color: #eeeeee
}

.textArea label {
    margin-bottom: 5px;
    color: #8A92A6 !important;
}`, "",{"version":3,"sources":["webpack://./src/commanComponents/inputs/inputs.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,oCAAoC;IACpC,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;AACA;IACI;AACJ;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":[".textArea textarea{\n    border: 1px solid #dadada;\n    color: #333;\n    font-size: 16px;\n    border: 1px solid #8A92A6 !important;\n    max-height: 120px;\n}\n\n.textArea textarea::placeholder{\n    color: #b8c0d2 ;\n}\n.textArea textarea:disabled{\n    background-color: #eeeeee\n}\n\n.textArea label {\n    margin-bottom: 5px;\n    color: #8A92A6 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
