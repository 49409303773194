import React, { useState } from "react";
import Searchbox from "../../commanComponents/inputs/Searchbox";
import { RoundedbuttonWithIcon } from "../../commanComponents/buttons/NavigationButtons";
import { svgIcons } from "../../constants/utilities/ImagesExport/Icons";
import { ToggleButton } from "../../commanComponents/buttons/ToggleButtons";
import PaginationButton from "../../commanComponents/buttons/PaginationBtn";
import { useNavigate } from "react-router-dom";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import useDebounce from "../../constants/useDebounce";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import Loader from "../../commanComponents/Loader";
import Attachment from "../../commanComponents/Attachment";
import { isEven } from "../../constants/Helper/NumbersFunction";
import { PaginationSrNo } from "../../constants/Helper/Helpers";

function MyGrievanceList() {
   const navigate = useNavigate();
   const [page, setPage] = useState(1);
   const [searchString, setSearchString] = useState("");
   const [pageUpdated, setPageUpdated] = useState("");
   const [grievanceData, setGrievanceData] = useState([]);
   const debouncedSearchTerm = useDebounce(searchString, 1000);
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [attachmentFile, setAttachmentFile] = useState("");

   const { data, isFetching, error } = useQuery(
      ["myGrievance", page, debouncedSearchTerm, pageUpdated],
      () => commonApi?.getAllMyGrievanceList(page, searchString),
      {
         refetchOnWindowFocus: false, 
         onSuccess: (data) => {
            if (data?.status == "SUCCESS") {
               setGrievanceData(data?.complaint);
            } else if (data?.status == "JWT_INVALID") {
               toast.error(`Failed : ${data?.message}`);
            }
            if (data?.status == "FAILED") {
               setGrievanceData([]);
            }
         },
         onError: (error) => {
            toast.error(`Failed : ${error?.message}`);
         },
      }
   );

   const handleClosePopup = () => setIsPopupOpen(false);

   return (
      <div>
         {(isFetching || loading) && <Loader />}
         <div className="search_btn_wrapper">
            <div className="w-200">
               <Searchbox
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search Grievance..."
               />
            </div>
            <div className="buttons_wrapper">
               <RoundedbuttonWithIcon
                  text={"Add grievance"}
                  textClasses="text-uppercase"
                  icon={svgIcons.PlusIcon}
                  onClick={() => {
                     navigate("/my-grievance/manage-grievance?type=add");
                  }}
               />
            </div>
         </div>
         <div className="tableWrapper common_shadow">
            <div
               className="table-responsive position-relative tablemain"
               style={{ overflowY: "auto", height: "58vh" }}
            >
               <table className="table position-relative w-100">
                  <thead
                     className="rounded-pill w-100 bg_Table_Header border-0"
                     style={{ position: "sticky", top: "-0.04px", zIndex: "1" }}
                  >
                     <tr>
                        <th scope="col" className="col_100px">
                           Sr. No
                        </th>
                        <th scope="col" className="col_200px">
                           Grievance Id
                        </th>
                        <th scope="col" className="col_200px">
                           Date
                        </th>
                        <th scope="col" className="col_250px">
                           Subject
                        </th>
                        <th scope="col" className="col_200px text-center">
                           Status
                        </th>
                        <th scope="col" className="col_150px text-center">
                           Attachment
                        </th>
                        <th scope="col" className="col_100px text-center row-fix-to-last table-th-bg-color">
                           Action
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {grievanceData?.length > 0 &&
                        grievanceData?.map((data, index) => {
                           return (
                              <tr
                                 className={`${
                                    isEven(index + 1) && "Table_Data"
                                 }`}
                              >
                                 <th>{PaginationSrNo(page, index)}</th>
                                 <td className="text-capitalize">
                                    {data?.complaintId || "--"}
                                 </td>
                                 <td>
                                    {data?.date
                                       ? new Date(
                                            data?.date
                                         )?.toLocaleDateString("hi")
                                       : "--"}
                                 </td>
                                 <td className="text-capitalize">
                                    {data?.subject ? data?.subject : "--"}
                                 </td>
                                 <td
                                    className={`${
                                       data?.status == "resolved" ||
                                       data?.status == "completed"
                                          ? "text_primary"
                                          : data?.status === "inprogress"
                                          ? "text_Support_Orange"
                                          : "text_support_Blue"
                                    } text-capitalize font-weight_600 text-center`}
                                 >
                                    {data?.status ? data?.status : "--"}
                                 </td>
                                 <td
                                    className="text-center cursor_pointer"
                                    onClick={() => {
                                       setAttachmentFile(
                                          data?.attachmentUrl[0] &&
                                             data?.attachmentUrl[0]?.fileUrl
                                       );
                                       setIsPopupOpen(true);
                                    }}
                                    title="Attachment"
                                 >
                                    {svgIcons?.attachmentIcon}
                                 </td>
                                 <td className="row-fix-to-last text-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                       <div
                                          className="mr-2 cursor_pointer"
                                          title="View"
                                          onClick={() => {
                                             navigate(
                                                `/my-grievance/manage-grievance?type=view&grievanceId=${data?._id}`
                                             );
                                          }}
                                       >
                                          {svgIcons.eyeIcon}
                                          {/* <EditIcon /> */}
                                       </div>
                                    </div>
                                 </td>
                              </tr>
                           );
                        })}
                  </tbody>
               </table>
               {grievanceData?.length == 0 && (
                  <h6 className="DataNotFoundText">No Grievance Found...</h6>
               )}
            </div>
         </div>
         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            title="Attachment"
         >
            <Attachment onClose={handleClosePopup} fileUrl={attachmentFile} />
         </CenteredPopup>

         <div className="mt-2">
            <PaginationButton
               page={page}
               setPage={setPage}
               totalPages={data?.totalPages || 1}
            />
         </div>
      </div>
   );
}
export default MyGrievanceList;
